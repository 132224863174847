import React, { useState } from 'react';
import {
    Box,
    Container,
    Paper,
    TextField,
    Button,
    Typography,
    Alert,
    Divider
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useCart } from '../context/CartContext';
import axios from 'axios';

function Checkout() {
    const navigate = useNavigate();
    const { cartItems, clearCart } = useCart();
    const [email, setEmail] = useState('');
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);

    const calculateTotal = () => {
        return cartItems.reduce((sum, item) => sum + parseFloat(item.price), 0);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        setError('');

        try {
            const total = calculateTotal();
            const orderData = {
                email: email,
                items: cartItems,
                total: total
            };

            // Log detallado de los datos a enviar
            console.log('Datos completos a enviar:', orderData);
            console.log('Items del carrito:', JSON.stringify(cartItems, null, 2));

            const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/orders/create`, orderData);
            
            if (response.data.success) {
                clearCart();
                navigate(`/payment/${response.data.data.orderId}`);
            }
        } catch (error) {
            console.error('Error detallado:', {
                message: error.message,
                response: error.response?.data,
                status: error.response?.status
            });
            setError(error.response?.data?.message || 'Error al procesar la orden');
        } finally {
            setLoading(false);
        }
    };

    return (
        <Container maxWidth="sm" sx={{ py: 8 }}>
            <Paper elevation={3} sx={{ p: 4 }}>
                <Typography variant="h4" gutterBottom align="center">
                    Finalizar Compra
                </Typography>

                {error && (
                    <Alert severity="error" sx={{ mb: 2 }}>
                        {error}
                    </Alert>
                )}

                <form onSubmit={handleSubmit}>
                    <TextField
                        fullWidth
                        label="Correo electrónico"
                        type="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                        sx={{ mb: 3 }}
                    />

                    <Box sx={{ mb: 3 }}>
                        <Typography variant="h6" gutterBottom>
                            Resumen de la orden
                        </Typography>
                        <Typography>
                            Total a pagar: ${calculateTotal()}
                        </Typography>
                    </Box>

                    <Button
                        type="submit"
                        variant="contained"
                        fullWidth
                        size="large"
                        disabled={loading || cartItems.length === 0}
                    >
                        {loading ? 'Procesando...' : 'Continuar al pago'}
                    </Button>
                </form>
            </Paper>
        </Container>
    );
}

export default Checkout; 