import React from 'react';
import { 
    Container, Typography, Box, Paper, Grid, Chip,
    Table, TableBody, TableCell, TableContainer, 
    TableHead, TableRow, Button, IconButton
} from '@mui/material';
import { motion } from 'framer-motion';
import DownloadIcon from '@mui/icons-material/Download';
import VisibilityIcon from '@mui/icons-material/Visibility';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';

function Orders() {
    const orders = [
        {
            id: '#ORD-2024-001',
            date: '15 Mar 2024',
            game: 'EA Sports FC 24',
            price: 59.99,
            status: 'Completado',
            key: 'XXXX-XXXX-XXXX'
        },
        {
            id: '#ORD-2024-002',
            date: '14 Mar 2024',
            game: 'Cyberpunk 2077',
            price: 49.99,
            status: 'Completado',
            key: 'XXXX-XXXX-XXXX'
        },
        {
            id: '#ORD-2024-003',
            date: '13 Mar 2024',
            game: 'Red Dead Redemption 2',
            price: 39.99,
            status: 'Procesando',
            key: 'Pendiente'
        }
    ];

    const getStatusColor = (status) => {
        switch(status) {
            case 'Completado':
                return 'var(--accent-color)';
            case 'Procesando':
                return 'var(--warning)';
            default:
                return 'var(--error)';
        }
    };

    return (
        <Box 
            sx={{ 
                minHeight: '100vh',
                background: 'var(--bg-gradient)',
                pt: { xs: 12, md: 16 },
                pb: 8
            }}
        >
            <Container maxWidth="lg">
                <motion.div
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.5 }}
                >
                    <Typography 
                        variant="h3" 
                        component="h1" 
                        color="white"
                        sx={{ 
                            mb: 4,
                            fontWeight: 700,
                            textAlign: 'center'
                        }}
                    >
                        Mis Pedidos
                    </Typography>

                    {/* Resumen de Pedidos */}
                    <Grid container spacing={3} sx={{ mb: 4 }}>
                        <Grid item xs={12} md={4}>
                            <Paper 
                                className="glass-effect"
                                sx={{ 
                                    p: 3,
                                    background: 'rgba(255, 255, 255, 0.1)',
                                    color: 'white',
                                    textAlign: 'center'
                                }}
                            >
                                <Typography variant="h4" sx={{ mb: 1 }}>
                                    {orders.length}
                                </Typography>
                                <Typography variant="body1" sx={{ opacity: 0.7 }}>
                                    Total de Pedidos
                                </Typography>
                            </Paper>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Paper 
                                className="glass-effect"
                                sx={{ 
                                    p: 3,
                                    background: 'rgba(255, 255, 255, 0.1)',
                                    color: 'white',
                                    textAlign: 'center'
                                }}
                            >
                                <Typography variant="h4" sx={{ mb: 1 }}>
                                    ${orders.reduce((sum, order) => sum + order.price, 0).toFixed(2)}
                                </Typography>
                                <Typography variant="body1" sx={{ opacity: 0.7 }}>
                                    Total Gastado
                                </Typography>
                            </Paper>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Paper 
                                className="glass-effect"
                                sx={{ 
                                    p: 3,
                                    background: 'rgba(255, 255, 255, 0.1)',
                                    color: 'white',
                                    textAlign: 'center'
                                }}
                            >
                                <Typography variant="h4" sx={{ mb: 1 }}>
                                    {orders.filter(order => order.status === 'Completado').length}
                                </Typography>
                                <Typography variant="body1" sx={{ opacity: 0.7 }}>
                                    Pedidos Completados
                                </Typography>
                            </Paper>
                        </Grid>
                    </Grid>

                    {/* Tabla de Pedidos */}
                    <TableContainer 
                        component={Paper}
                        className="glass-effect"
                        sx={{ 
                            background: 'rgba(255, 255, 255, 0.1)',
                            color: 'white'
                        }}
                    >
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell sx={{ color: 'white', fontWeight: 600 }}>ID Pedido</TableCell>
                                    <TableCell sx={{ color: 'white', fontWeight: 600 }}>Fecha</TableCell>
                                    <TableCell sx={{ color: 'white', fontWeight: 600 }}>Juego</TableCell>
                                    <TableCell sx={{ color: 'white', fontWeight: 600 }}>Precio</TableCell>
                                    <TableCell sx={{ color: 'white', fontWeight: 600 }}>Estado</TableCell>
                                    <TableCell sx={{ color: 'white', fontWeight: 600 }}>Clave</TableCell>
                                    <TableCell sx={{ color: 'white', fontWeight: 600 }}>Acciones</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {orders.map((order) => (
                                    <TableRow key={order.id}>
                                        <TableCell sx={{ color: 'white' }}>{order.id}</TableCell>
                                        <TableCell sx={{ color: 'white' }}>{order.date}</TableCell>
                                        <TableCell sx={{ color: 'white' }}>{order.game}</TableCell>
                                        <TableCell sx={{ color: 'white' }}>${order.price}</TableCell>
                                        <TableCell>
                                            <Chip 
                                                label={order.status}
                                                sx={{ 
                                                    bgcolor: getStatusColor(order.status),
                                                    color: 'white'
                                                }}
                                            />
                                        </TableCell>
                                        <TableCell sx={{ color: 'white' }}>{order.key}</TableCell>
                                        <TableCell>
                                            <IconButton 
                                                sx={{ color: 'white' }}
                                                title="Ver detalles"
                                            >
                                                <VisibilityIcon />
                                            </IconButton>
                                            {order.status === 'Completado' && (
                                                <IconButton 
                                                    sx={{ color: 'white' }}
                                                    title="Descargar factura"
                                                >
                                                    <DownloadIcon />
                                                </IconButton>
                                            )}
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </motion.div>
            </Container>
        </Box>
    );
}

export default Orders; 