import React, { useState, useEffect } from 'react';
import {
    Box,
    Container,
    Typography,
    Button,
    Paper,
    Grid,
    Divider,
    Alert
} from '@mui/material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import qrBancolombia from '../assets/images/qr-bancolombia.png';

function Payment() {
    const { orderId } = useParams();
    const navigate = useNavigate();
    const [file, setFile] = useState(null);
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');
    const [loading, setLoading] = useState(false);

    const handleFileChange = (e) => {
        const selectedFile = e.target.files[0];
        if (selectedFile) {
            // Validar el tipo de archivo
            const validTypes = ['image/jpeg', 'image/png', 'image/jpg', 'application/pdf'];
            if (!validTypes.includes(selectedFile.type)) {
                setError('Por favor, sube una imagen (JPG, PNG) o PDF');
                return;
            }
            // Validar el tamaño (5MB máximo)
            if (selectedFile.size > 5 * 1024 * 1024) {
                setError('El archivo no debe superar los 5MB');
                return;
            }
            setFile(selectedFile);
            setError('');
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!file) {
            setError('Por favor selecciona un archivo');
            return;
        }

        setLoading(true);
        setError('');
        
        try {
            const formData = new FormData();
            formData.append('proof', file);

            console.log('Enviando comprobante para la orden:', orderId);
            
            const response = await axios.post(
                `${process.env.REACT_APP_BACKEND_URL}/orders/${orderId}/upload-proof`,
                formData,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                }
            );

            console.log('Respuesta del servidor:', response.data);

            if (response.data.success) {
                setSuccess('Comprobante enviado exitosamente');
                // Mostrar mensaje de éxito por 2 segundos antes de redirigir
                setTimeout(() => {
                    navigate('/orders');
                }, 2000);
            }
        } catch (error) {
            console.error('Error al subir comprobante:', error);
            setError(
                error.response?.data?.message || 
                'Error al subir el comprobante. Por favor intenta de nuevo.'
            );
        } finally {
            setLoading(false);
        }
    };

    return (
        <Container maxWidth="lg" sx={{ mt: 12, mb: 4 }}>
            <Paper 
                elevation={3} 
                sx={{ 
                    p: 4,
                    background: 'rgba(10, 10, 31, 0.9)',
                    backdropFilter: 'blur(10px)',
                    border: '1px solid rgba(255, 255, 255, 0.1)'
                }}
            >
                <Typography variant="h4" gutterBottom sx={{ color: 'primary.main' }}>
                    Información de Pago
                </Typography>

                {/* Grid para organizar la información de pago y el QR */}
                <Grid container spacing={4}>
                    {/* Columna izquierda: Información bancaria */}
                    <Grid item xs={12} md={7}>
                        <Box sx={{ mb: 3 }}>
                            <Typography variant="h6" gutterBottom color="primary">
                                Datos Bancarios
                            </Typography>
                            <Typography variant="body1" color="white" paragraph>
                                Bancolombia Ahorros: <Box component="span" sx={{ backgroundColor: 'rgba(255, 255, 0, 0.2)', px: 1, borderRadius: 1 }}>65206595684</Box>
                            </Typography>
                            <Typography variant="body1" color="white" paragraph>
                                C.C. <Box component="span" sx={{ backgroundColor: 'rgba(255, 255, 0, 0.2)', px: 1, borderRadius: 1 }}>1019135251</Box>
                            </Typography>
                            <Typography variant="body1" color="white" paragraph>
                                Titular: Oscar Roncario
                            </Typography>
                            <Typography variant="body1" color="white" paragraph>
                                Nu bank Cuenta de ahorros: <Box component="span" sx={{ backgroundColor: 'rgba(255, 255, 0, 0.2)', px: 1, borderRadius: 1 }}>63965230</Box>
                            </Typography>
                            <Typography variant="body1" color="white" paragraph>
                                Nu Placa: <Box component="span" sx={{ backgroundColor: 'rgba(255, 255, 0, 0.2)', px: 1, borderRadius: 1 }}>ORL251</Box>
                            </Typography>
                            <Typography variant="body1" color="white" paragraph>
                                Transfiya:
                            </Typography>
                            <Typography variant="body1" color="white" paragraph>
                                <Box component="span" sx={{ backgroundColor: 'rgba(255, 255, 0, 0.2)', px: 1, borderRadius: 1 }}>3016445671</Box>
                            </Typography>
                        </Box>

                        <Divider sx={{ my: 3, borderColor: 'rgba(255, 255, 255, 0.1)' }} />

                        {/* Sección de subida de comprobante */}
                        <Box sx={{ mt: 3 }}>
                            <Typography variant="h6" gutterBottom color="primary">
                                Subir Comprobante de Pago
                            </Typography>
                            <input
                                type="file"
                                accept="image/*,.pdf"
                                onChange={handleFileChange}
                                style={{ display: 'none' }}
                                id="proof-upload"
                            />
                            <label htmlFor="proof-upload">
                                <Button
                                    variant="contained"
                                    component="span"
                                    startIcon={<CloudUploadIcon />}
                                    sx={{
                                        mt: 2,
                                        background: 'linear-gradient(45deg, #6A0DAD, #9D00FF)',
                                        '&:hover': {
                                            background: 'linear-gradient(45deg, #9D00FF, #6A0DAD)',
                                        }
                                    }}
                                >
                                    Seleccionar Comprobante
                                </Button>
                            </label>
                            {file && (
                                <Typography variant="body2" sx={{ mt: 1, color: 'white' }}>
                                    Archivo seleccionado: {file.name}
                                </Typography>
                            )}
                        </Box>

                        {/* Botón de envío */}
                        <Button
                            variant="contained"
                            onClick={handleSubmit}
                            disabled={!file || loading}
                            sx={{
                                mt: 3,
                                background: 'linear-gradient(45deg, #00FFFF, #00CCCC)',
                                '&:hover': {
                                    background: 'linear-gradient(45deg, #00CCCC, #00FFFF)',
                                }
                            }}
                        >
                            {loading ? 'Enviando...' : 'Enviar Comprobante'}
                        </Button>
                    </Grid>

                    {/* Columna derecha: Código QR */}
                    <Grid item xs={12} md={5}>
                        <Box 
                            sx={{ 
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                justifyContent: 'center',
                                height: '100%',
                                p: 3,
                                border: '1px solid rgba(255, 255, 255, 0.1)',
                                borderRadius: 2,
                                background: 'rgba(0, 0, 0, 0.3)'
                            }}
                        >
                            <Typography variant="h6" gutterBottom color="primary" textAlign="center">
                                Escanea el código QR para pagar
                            </Typography>
                            <Box
                                component="img"
                                src={qrBancolombia}
                                alt="Código QR para pago"
                                sx={{
                                    width: '100%',
                                    maxWidth: '300px',
                                    height: 'auto',
                                    mt: 2,
                                    mb: 2,
                                    filter: 'drop-shadow(0 0 10px rgba(0, 255, 255, 0.3))'
                                }}
                            />
                            <Typography 
                                variant="body2" 
                                color="white" 
                                textAlign="center"
                                sx={{ opacity: 0.8 }}
                            >
                                También puedes escanear este código desde tu aplicación bancaria
                            </Typography>
                        </Box>
                    </Grid>
                </Grid>

                {/* Alertas y mensajes de estado */}
                {error && (
                    <Alert severity="error" sx={{ mt: 3 }}>
                        {error}
                    </Alert>
                )}
                {success && (
                    <Alert severity="success" sx={{ mt: 3 }}>
                        {success}
                    </Alert>
                )}
            </Paper>
        </Container>
    );
}

export default Payment;