import React, { useState, useEffect } from 'react';
import { 
    AppBar, Toolbar, Button, Typography, Box, Container,
    IconButton, Drawer, List, ListItem, ListItemText,
    useScrollTrigger, Slide, Badge, Menu, MenuItem,
    useTheme, InputBase, Autocomplete, Divider, ListItemIcon, useMediaQuery
} from '@mui/material';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import MenuIcon from '@mui/icons-material/Menu';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import FavoriteIcon from '@mui/icons-material/Favorite';
import SearchIcon from '@mui/icons-material/Search';
import { motion } from 'framer-motion';
import { useAuth } from '../context/AuthContext';
import { useCart } from '../context/CartContext';
import axios from 'axios';
import PersonIcon from '@mui/icons-material/Person';
import LogoutIcon from '@mui/icons-material/Logout';
import SettingsIcon from '@mui/icons-material/Settings';
import CloseIcon from '@mui/icons-material/Close';
import { Link as RouterLink } from 'react-router-dom';

function HideOnScroll({ children }) {
    const trigger = useScrollTrigger();
    return (
        <Slide appear={false} direction="down" in={!trigger}>
            {children}
        </Slide>
    );
}

function Navbar() {
    const { user, logout } = useAuth();
    const navigate = useNavigate();
    const [anchorEl, setAnchorEl] = useState(null);
    const { cartItems = [] } = useCart() || {};
    const [searchQuery, setSearchQuery] = useState('');
    const [suggestions, setSuggestions] = useState([]);
    const [loading, setLoading] = useState(false);
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
    const isMobile = useMediaQuery(useTheme().breakpoints.down('md'));

    useEffect(() => {
        const fetchGames = async () => {
            try {
                const response = await axios.get('http://localhost:5000/api/games');
                setSuggestions(response.data);
            } catch (error) {
                console.error('Error al cargar sugerencias:', error);
            } finally {
                setLoading(false);
            }
        };
        fetchGames();
    }, []);

    const handleSearch = (e) => {
        e.preventDefault();
        if (searchQuery.trim()) {
            navigate(`/products?search=${encodeURIComponent(searchQuery.trim())}`);
            setSearchQuery('');
        }
    };

    const handleMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const handleLogout = () => {
        logout();
        handleMenuClose();
        navigate('/');
    };

    const handleProfile = () => {
        navigate('/profile');
        handleMenuClose();
    };

    const handleSettings = () => {
        navigate('/settings');
        handleMenuClose();
    };

    return (
        <AppBar position="fixed" sx={{ 
            backgroundColor: '#000000',
            background: 'linear-gradient(180deg, rgba(0,0,0,0.95) 0%, rgba(10,10,20,0.95) 100%)',
            backdropFilter: 'blur(10px)',
            borderBottom: '1px solid rgba(255, 255, 255, 0.05)',
            boxShadow: '0 4px 30px rgba(0, 0, 0, 0.3)'
        }}>
            <Container maxWidth={false}>
                <Toolbar sx={{ 
                    justifyContent: 'space-between', 
                    gap: 2,
                    minHeight: { 
                        xs: '80px', 
                        sm: '90px', 
                        md: '100px'
                    },
                    py: 1
                }}>
                    {/* Logo - Aumentar tamaño */}
                    <Link to="/" style={{ textDecoration: 'none', display: 'flex', alignItems: 'center' }}>
                        <Box 
                            component="img" 
                            src="https://imgur.com/ceel9no.png" 
                            alt="Black Vortex Gaming" 
                            sx={{ 
                                height: { 
                                    xs: 70, 
                                    sm: 80, 
                                    md: 90
                                },
                                maxWidth: '350px',
                                objectFit: 'contain',
                                mr: 2,
                                ml: { xs: 1, md: 2 }
                            }} 
                        />
                    </Link>

                    {/* Enlaces principales - Ajustar tamaño de botones */}
                    <Box sx={{ 
                        display: { xs: 'none', md: 'flex' },  // Ocultar en móvil
                        gap: 1,
                        alignItems: 'center',
                        flex: '1 1 auto',
                        ml: 2
                    }}>
                        <Link to="/" style={{ textDecoration: 'none' }}>
                            <Button 
                                color="inherit" 
                                sx={{ 
                                    fontSize: '0.9rem',
                                    py: 0.5
                                }}
                            >
                                Inicio
                            </Button>
                        </Link>
                        <Button
                            component={RouterLink}
                            to="/sales"
                            color="inherit"
                            sx={{ 
                                fontSize: '0.9rem',
                                py: 0.5,
                                '&:hover': {
                                    color: '#00FFFF'
                                }
                            }}
                        >
                            Ofertas
                        </Button>
                        <Link to="/products" style={{ textDecoration: 'none' }}>
                            <Button color="inherit">Videojuegos</Button>
                        </Link>
                    </Box>

                    {/* Barra de búsqueda */}
                    <Box 
                        component="form" 
                        onSubmit={handleSearch}
                        sx={{ 
                            display: { xs: 'none', md: 'flex' },  // Ocultar en móvil
                            alignItems: 'center',
                            backgroundColor: 'rgba(255, 255, 255, 0.03)',
                            borderRadius: '6px',
                            padding: '2px 8px',
                            width: '250px',
                            border: '1px solid rgba(0, 255, 255, 0.1)',
                            transition: 'all 0.3s ease',
                            '&:hover': {
                                backgroundColor: 'rgba(255, 255, 255, 0.05)',
                                border: '1px solid rgba(0, 255, 255, 0.2)',
                                boxShadow: '0 0 10px rgba(0, 255, 255, 0.1)'
                            }
                        }}
                    >
                        <InputBase
                            placeholder="Buscar juegos..."
                            value={searchQuery}
                            onChange={(e) => setSearchQuery(e.target.value)}
                            sx={{ 
                                ml: 1, 
                                flex: 1,
                                color: '#fff',
                                '& input::placeholder': {
                                    color: 'rgba(255, 255, 255, 0.7)',
                                    opacity: 1
                                }
                            }}
                        />
                        <IconButton 
                            type="submit" 
                            sx={{ 
                                p: '10px',
                                color: '#00FFFF',
                                '&:hover': {
                                    color: '#40FFFF'
                                }
                            }}
                        >
                            <SearchIcon />
                        </IconButton>
                    </Box>

                    {/* Elementos de la derecha */}
                    <Box sx={{ 
                        display: 'flex', 
                        alignItems: 'center',
                        gap: { xs: 0.5, md: 1 },  // Reducir gap en móvil
                        ml: { xs: 0, md: 1 }      // Ajustar margen en móvil
                    }}>
                        {/* Selector de moneda - Solo mostrar en desktop */}
                        <Button 
                            color="inherit"
                            sx={{ 
                                display: { xs: 'none', md: 'flex' },  // Ocultar en móvil
                                color: '#fff',
                                '&:hover': {
                                    color: '#00FFFF'
                                },
                                fontSize: '0.9rem',
                                minWidth: 'auto'
                            }}
                        >
                            COP
                        </Button>

                        {/* Perfil/Login con menú desplegable */}
                        {user ? (
                            <>
                                <IconButton 
                                    color="inherit" 
                                    onClick={handleMenuOpen}
                                    sx={{
                                        '&:hover': {
                                            color: '#00FFFF'
                                        }
                                    }}
                                >
                                    <AccountCircleIcon />
                                </IconButton>
                                <Menu
                                    anchorEl={anchorEl}
                                    open={Boolean(anchorEl)}
                                    onClose={handleMenuClose}
                                    PaperProps={{
                                        sx: {
                                            backgroundColor: 'rgba(0, 0, 0, 0.9)',
                                            backdropFilter: 'blur(10px)',
                                            border: '1px solid rgba(0, 255, 255, 0.2)',
                                            color: 'white',
                                            '& .MuiMenuItem-root': {
                                                '&:hover': {
                                                    backgroundColor: 'rgba(0, 255, 255, 0.1)'
                                                }
                                            }
                                        }
                                    }}
                                    transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                                    anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                                >
                                    <MenuItem onClick={handleProfile}>
                                        <ListItemIcon>
                                            <PersonIcon sx={{ color: '#00FFFF' }} />
                                        </ListItemIcon>
                                        Mi Perfil
                                    </MenuItem>
                                    <MenuItem onClick={handleSettings}>
                                        <ListItemIcon>
                                            <SettingsIcon sx={{ color: '#00FFFF' }} />
                                        </ListItemIcon>
                                        Configuración
                                    </MenuItem>
                                    <Divider sx={{ borderColor: 'rgba(255, 255, 255, 0.1)' }} />
                                    <MenuItem onClick={handleLogout}>
                                        <ListItemIcon>
                                            <LogoutIcon sx={{ color: '#FF4081' }} />
                                        </ListItemIcon>
                                        Cerrar Sesión
                                    </MenuItem>
                                </Menu>
                            </>
                        ) : (
                            <Link to="/login" style={{ textDecoration: 'none' }}>
                                <IconButton 
                                    color="inherit"
                                    sx={{
                                        '&:hover': {
                                            color: '#00FFFF'
                                        }
                                    }}
                                >
                                    <AccountCircleIcon />
                                </IconButton>
                            </Link>
                        )}

                        {/* Favoritos */}
                        <Link to="/favoritos" style={{ textDecoration: 'none' }}>
                            <IconButton color="inherit">
                                <Badge badgeContent={0} color="error">
                                    <FavoriteIcon />
                                </Badge>
                            </IconButton>
                        </Link>

                        {/* Carrito - Actualizado para usar la ruta /cart */}
                        <Link to="/cart" style={{ textDecoration: 'none' }}>
                            <IconButton color="inherit">
                                <Badge badgeContent={cartItems?.length || 0} color="error">
                                    <ShoppingCartIcon />
                                </Badge>
                            </IconButton>
                        </Link>

                        {/* Botón de menú hamburguesa - Solo mostrar en móvil */}
                        {isMobile && (
                            <IconButton
                                color="inherit"
                                onClick={() => setMobileMenuOpen(true)}
                                sx={{
                                    display: { xs: 'flex', md: 'none' },  // Solo mostrar en móvil
                                    '&:hover': {
                                        color: '#00FFFF'
                                    }
                                }}
                            >
                                <MenuIcon />
                            </IconButton>
                        )}
                    </Box>
                </Toolbar>
            </Container>

            <Drawer
                anchor="right"
                open={mobileMenuOpen}
                onClose={() => setMobileMenuOpen(false)}
                PaperProps={{
                    sx: {
                        width: '100%',  // Pantalla completa en móvil
                        maxWidth: '300px',
                        background: 'linear-gradient(180deg, rgba(0,0,0,0.95) 0%, rgba(10,10,20,0.95) 100%)',
                        backdropFilter: 'blur(10px)',
                        borderLeft: '2px solid rgba(157, 0, 255, 0.3)',
                        color: 'white'
                    }
                }}
            >
                <Box sx={{ 
                    p: 3,
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 2
                }}>
                    {/* Botón de cerrar */}
                    <Box sx={{ 
                        display: 'flex', 
                        justifyContent: 'flex-end',
                        mb: 2
                    }}>
                        <IconButton 
                            onClick={() => setMobileMenuOpen(false)}
                            sx={{ 
                                color: 'white',
                                '&:hover': { color: '#00FFFF' }
                            }}
                        >
                            <CloseIcon />
                        </IconButton>
                    </Box>

                    {/* Lista de enlaces */}
                    <List sx={{ width: '100%' }}>
                        {['Inicio', 'Ofertas', 'Videojuegos'].map((text, index) => (
                            <ListItem 
                                key={text}
                                component={Link}
                                to={index === 0 ? '/' : index === 1 ? '/sales' : '/products'}
                                onClick={() => setMobileMenuOpen(false)}
                                sx={{
                                    mb: 1,
                                    borderRadius: '8px',
                                    transition: 'all 0.3s ease',
                                    background: 'rgba(157, 0, 255, 0.1)',
                                    border: '1px solid rgba(157, 0, 255, 0.2)',
                                    '&:hover': {
                                        background: 'rgba(157, 0, 255, 0.2)',
                                        transform: 'translateX(8px)'
                                    }
                                }}
                            >
                                <ListItemText 
                                    primary={text} 
                                    sx={{ 
                                        '& .MuiTypography-root': {
                                            fontSize: '1.1rem',
                                            fontWeight: 500,
                                            letterSpacing: '0.5px'
                                        }
                                    }}
                                />
                            </ListItem>
                        ))}
                    </List>

                    {/* Barra de búsqueda */}
                    <Box 
                        component="form" 
                        onSubmit={handleSearch}
                        sx={{ 
                            mt: 2,
                            display: 'flex',
                            alignItems: 'center',
                            background: 'rgba(255, 255, 255, 0.05)',
                            borderRadius: '8px',
                            border: '1px solid rgba(157, 0, 255, 0.2)',
                            p: '4px 12px'
                        }}
                    >
                        <InputBase
                            placeholder="Buscar juegos..."
                            value={searchQuery}
                            onChange={(e) => setSearchQuery(e.target.value)}
                            sx={{ 
                                flex: 1,
                                color: 'white',
                                '& input::placeholder': {
                                    color: 'rgba(255, 255, 255, 0.7)',
                                    opacity: 1
                                }
                            }}
                        />
                        <IconButton type="submit" sx={{ color: '#00FFFF' }}>
                            <SearchIcon />
                        </IconButton>
                    </Box>
                </Box>
            </Drawer>
        </AppBar>
    );
}

export default Navbar; 