import axios from 'axios';

const adminApi = axios.create({
    baseURL: 'https://blackvortexgames.com/api'
});

// Interceptor para agregar el token a todas las peticiones
adminApi.interceptors.request.use(
    (config) => {
        const token = localStorage.getItem('adminToken');
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

// Interceptor para manejar errores de autenticación
adminApi.interceptors.response.use(
    (response) => response,
    (error) => {
        if (error.response?.status === 401) {
            // Redirigir al login si el token no es válido
            window.location.href = '/admin/login';
        }
        return Promise.reject(error);
    }
);

export default adminApi; 