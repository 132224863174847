import axios from 'axios';

const API_URL = 'http://localhost:5000/api/game-accounts';

const getAuthHeader = () => {
    const token = localStorage.getItem('adminToken');
    console.log('Token usado en petición:', token ? 'Presente' : 'Ausente'); // Debug
    return {
        headers: {
            'Authorization': `Bearer ${token}`
        }
    };
};

export const gameAccountService = {
    addBulkAccounts: async (gameId, accounts, additionalAccounts) => {
        try {
            const token = localStorage.getItem('adminToken');
            console.log('Enviando datos:', {
                game_id: gameId,
                accounts,
                additional_accounts: additionalAccounts
            });

            const response = await axios.post(
                'http://localhost:5000/api/game-accounts/bulk',
                {
                    game_id: gameId,
                    accounts: accounts,
                    additional_accounts: additionalAccounts
                },
                {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json'
                    }
                }
            );
            return response.data;
        } catch (error) {
            console.error('Error en addBulkAccounts:', error);
            throw error;
        }
    },

    getAccountsByGame: async (gameId) => {
        const response = await axios.get(`${API_URL}/game/${gameId}`, getAuthHeader());
        return response.data;
    },

    updateAccount: async (accountId, accountData) => {
        const response = await axios.put(`${API_URL}/${accountId}`, accountData, getAuthHeader());
        return response.data;
    },

    deleteAccount: async (accountId) => {
        const response = await axios.delete(`${API_URL}/${accountId}`, getAuthHeader());
        return response.data;
    },

    assignAccountToOrder: async (orderId, gameId) => {
        const response = await axios.post(`${API_URL}/assign/${orderId}`, {
            gameId
        }, getAuthHeader());
        return response.data;
    }
}; 