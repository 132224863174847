import React from 'react';
import { 
    Container, Box, Typography, Button, IconButton,
    Paper, Grid, Divider 
} from '@mui/material';
import { useCart } from '../context/CartContext';
import { formatCOP } from '../utils/formatPrice';
import DeleteIcon from '@mui/icons-material/Delete';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import { useNavigate } from 'react-router-dom';

function Cart() {
    const navigate = useNavigate();
    const { cartItems = [], removeFromCart } = useCart() || {};

    const calculateTotal = () => {
        if (!cartItems) return 0;
        return cartItems.reduce((total, item) => total + Number(item.price), 0);
    };

    const handleCheckout = () => {
        navigate('/checkout');
    };

    return (
        <Box sx={{ minHeight: '100vh', pt: { xs: 12, md: 16 }, pb: 8 }}>
            <Container maxWidth="lg">
                <Typography variant="h2" sx={{ 
                    mb: 4, 
                    color: 'white',
                    textAlign: 'center',
                    textTransform: 'uppercase'
                }}>
                    Tu Carrito
                </Typography>
                <Typography variant="h5" sx={{ 
                    mb: 6, 
                    color: 'white',
                    textAlign: 'center'
                }}>
                    {cartItems.length} {cartItems.length === 1 ? 'PRODUCTO' : 'PRODUCTOS'} EN TU CARRITO
                </Typography>

                <Grid container spacing={4}>
                    {/* Lista de productos */}
                    <Grid item xs={12} md={8}>
                        {cartItems.map((item) => (
                            <Paper key={item.id} sx={{
                                mb: 2,
                                p: 2,
                                background: 'rgba(0, 0, 0, 0.5)',
                                backdropFilter: 'blur(10px)',
                                border: '1px solid rgba(255, 255, 255, 0.1)'
                            }}>
                                <Grid container spacing={2} alignItems="center">
                                    <Grid item xs={3}>
                                        <img 
                                            src={item.image} 
                                            alt={item.name}
                                            style={{
                                                width: '100%',
                                                height: 'auto',
                                                borderRadius: '8px'
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Typography variant="h6" sx={{ color: 'white' }}>
                                            {item.name}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={2} sx={{ textAlign: 'right' }}>
                                        <Typography sx={{ color: '#00FFFF', fontWeight: 'bold' }}>
                                            ${formatCOP(item.price)} COP
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={1} sx={{ textAlign: 'right' }}>
                                        <IconButton 
                                            onClick={() => removeFromCart?.(item.id)}
                                            sx={{ color: 'red' }}
                                        >
                                            <DeleteIcon />
                                        </IconButton>
                                    </Grid>
                                </Grid>
                            </Paper>
                        ))}
                    </Grid>

                    {/* Resumen del pedido */}
                    <Grid item xs={12} md={4}>
                        <Paper sx={{
                            p: 3,
                            background: 'rgba(0, 0, 0, 0.5)',
                            backdropFilter: 'blur(10px)',
                            border: '1px solid rgba(255, 255, 255, 0.1)'
                        }}>
                            <Typography variant="h5" sx={{ mb: 3, color: 'white' }}>
                                RESUMEN DEL PEDIDO
                            </Typography>
                            
                            <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
                                <Typography sx={{ color: 'white' }}>Subtotal</Typography>
                                <Typography sx={{ color: 'white' }}>${formatCOP(calculateTotal())} COP</Typography>
                            </Box>

                            <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 3 }}>
                                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                    <MenuBookIcon sx={{ mr: 1, color: '#00FFFF' }} />
                                    <Typography sx={{ color: 'white' }}>Guía de Instalación</Typography>
                                </Box>
                                <Typography sx={{ color: '#00FFFF' }}>Gratis</Typography>
                            </Box>

                            <Divider sx={{ my: 2, bgcolor: 'rgba(255, 255, 255, 0.1)' }} />

                            <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 3 }}>
                                <Typography variant="h6" sx={{ color: 'white' }}>TOTAL</Typography>
                                <Typography variant="h6" sx={{ color: '#00FFFF' }}>
                                    ${formatCOP(calculateTotal())} COP
                                </Typography>
                            </Box>

                            <Button
                                variant="contained"
                                color="primary"
                                fullWidth
                                size="large"
                                onClick={handleCheckout}
                                disabled={cartItems.length === 0}
                            >
                                Continuar Compra
                            </Button>

                            <Typography 
                                sx={{ 
                                    mt: 2, 
                                    color: 'gray',
                                    textAlign: 'center',
                                    fontSize: '0.9rem'
                                }}
                            >
                                Entrega instantánea después del pago
                            </Typography>
                        </Paper>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    );
}

export default Cart; 