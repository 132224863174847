import React from 'react';
import { Box, Container, Typography, Link, Grid } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';

function Footer() {
    return (
        <Box 
            component="footer" 
            sx={{
                py: 3,
                px: 2,
                mt: 'auto',
                backgroundColor: 'rgba(0, 0, 0, 0.8)',
                backdropFilter: 'blur(10px)',
                borderTop: '1px solid rgba(255, 255, 255, 0.1)'
            }}
        >
            <Container maxWidth="lg">
                <Grid container spacing={4}>
                    {/* Columna de Nosotros */}
                    <Grid item xs={12} md={3}>
                        <Typography variant="h6" color="primary" gutterBottom>
                            Nosotros
                        </Typography>
                        <Link href="/quienes-somos" color="inherit" sx={{ 
                            display: 'block', 
                            mb: 1,
                            textDecoration: 'none',
                            '&:hover': { color: '#00FFFF' }
                        }}>
                            Quiénes Somos
                        </Link>
                        <Link href="/contactanos" color="inherit" sx={{ 
                            display: 'block', 
                            mb: 1,
                            textDecoration: 'none',
                            '&:hover': { color: '#00FFFF' }
                        }}>
                            Contáctanos
                        </Link>
                        <Link href="/faqs" color="inherit" sx={{ 
                            display: 'block', 
                            mb: 1,
                            textDecoration: 'none',
                            '&:hover': { color: '#00FFFF' }
                        }}>
                            FAQs
                        </Link>
                    </Grid>

                    {/* Columna de Navega */}
                    <Grid item xs={12} md={3}>
                        <Typography variant="h6" color="primary" gutterBottom>
                            Navega
                        </Typography>
                        <RouterLink to="/ofertas" style={{ 
                            display: 'block', 
                            mb: 1,
                            textDecoration: 'none',
                            color: 'inherit',
                            '&:hover': { color: '#00FFFF' }
                        }}>
                            Ofertas
                        </RouterLink>
                        <RouterLink to="/products" style={{ 
                            display: 'block', 
                            mb: 1,
                            textDecoration: 'none',
                            color: 'inherit',
                            '&:hover': { color: '#00FFFF' }
                        }}>
                            Videojuegos
                        </RouterLink>
                        <RouterLink to="/novedades" style={{ 
                            display: 'block', 
                            mb: 1,
                            textDecoration: 'none',
                            color: 'inherit',
                            '&:hover': { color: '#00FFFF' }
                        }}>
                            Novedades
                        </RouterLink>
                    </Grid>

                    {/* Columna de Links Sociales */}
                    <Grid item xs={12} md={3}>
                        <Typography variant="h6" color="primary" gutterBottom>
                            Links Sociales
                        </Typography>
                        <Link href="https://wa.me/tunumero" color="inherit" sx={{ 
                            display: 'block', 
                            mb: 1,
                            textDecoration: 'none',
                            '&:hover': { color: '#00FFFF' }
                        }}>
                            WhatsApp
                        </Link>
                        <Link href="https://instagram.com/tuinstagram" color="inherit" sx={{ 
                            display: 'block', 
                            mb: 1,
                            textDecoration: 'none',
                            '&:hover': { color: '#00FFFF' }
                        }}>
                            Instagram
                        </Link>
                        <Link href="https://facebook.com/tufacebook" color="inherit" sx={{ 
                            display: 'block', 
                            mb: 1,
                            textDecoration: 'none',
                            '&:hover': { color: '#00FFFF' }
                        }}>
                            Facebook
                        </Link>
                        <Link href="https://youtube.com/tucanal" color="inherit" sx={{ 
                            display: 'block', 
                            mb: 1,
                            textDecoration: 'none',
                            '&:hover': { color: '#00FFFF' }
                        }}>
                            YouTube
                        </Link>
                    </Grid>
                </Grid>

                {/* Copyright */}
                <Box sx={{ 
                    mt: 3, 
                    pt: 3, 
                    borderTop: '1px solid rgba(255, 255, 255, 0.1)',
                    display: 'flex',
                    justifyContent: 'space-between',
                    flexWrap: 'wrap',
                    gap: 2
                }}>
                    <Typography variant="body2" color="text.secondary">
                        Black Vortex Gaming © 2024. Creado por Angel Vergara
                    </Typography>
                    <Box>
                        <Link href="/terminos" color="inherit" sx={{ 
                            mr: 2, 
                            textDecoration: 'none',
                            '&:hover': { color: '#00FFFF' }
                        }}>
                            Términos y Condiciones
                        </Link>
                        <Link href="/privacidad" color="inherit" sx={{ 
                            textDecoration: 'none',
                            '&:hover': { color: '#00FFFF' }
                        }}>
                            Opt-Out Preferences
                        </Link>
                    </Box>
                </Box>
            </Container>
        </Box>
    );
}

export default Footer; 