import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
    Box,
    Container,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Button,
    Typography,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    IconButton,
    Alert,
    Snackbar,
    FormControl,
    InputLabel,
    Select,
    MenuItem
} from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import axios from 'axios';
import { formatCOP } from '../../utils/formatPrice';

function AuthorizeOrders() {
    const [orders, setOrders] = useState([]);
    const [loading, setLoading] = useState(true);
    const [selectedProof, setSelectedProof] = useState(null);
    const [snackbar, setSnackbar] = useState({
        open: false,
        message: '',
        severity: 'success'
    });
    const navigate = useNavigate();
    const [guides, setGuides] = useState([]);
    const [selectedGuide, setSelectedGuide] = useState('');
    const [authorizeDialogOpen, setAuthorizeDialogOpen] = useState(false);
    const [selectedOrderId, setSelectedOrderId] = useState(null);

    useEffect(() => {
        fetchOrders();
        fetchGuides();
    }, []);

    const fetchOrders = async () => {
        try {
            const token = localStorage.getItem('adminToken');
            
            if (!token) {
                console.error('No hay token de administrador');
                navigate('/admin/login');
                return;
            }

            console.log('Solicitando órdenes pendientes...');
            const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/orders/pending`, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });

            console.log('Respuesta del servidor:', response.data);
            
            if (response.data.success) {
                const ordersData = response.data.data;
                console.log('Órdenes recibidas:', ordersData.length);
                
                // Verificar estructura de datos
                ordersData.forEach(order => {
                    console.log('Orden:', {
                        id: order.id,
                        email: order.email,
                        game: order.game,
                        proof: order.proof
                    });
                });

                setOrders(ordersData);
            }
        } catch (error) {
            console.error('Error detallado:', {
                message: error.message,
                response: error.response?.data,
                status: error.response?.status
            });
            
            if (error.response?.status === 401) {
                navigate('/admin/login');
            }
        } finally {
            setLoading(false);
        }
    };

    const fetchGuides = async () => {
        try {
            const response = await axios.get('http://localhost:5000/api/guides');
            if (response.data.success) {
                setGuides(response.data.data);
            }
        } catch (error) {
            console.error('Error al cargar guías:', error);
        }
    };

    const handleViewProof = (order) => {
        console.log('Orden recibida en handleViewProof:', order);
        
        // Verificar si hay comprobante de cualquiera de las dos formas
        if (!order.payment_proof && !order.proof) {
            console.log('No hay comprobante para esta orden');
            return;
        }

        // Usar el comprobante en el formato que esté disponible
        const proofUrl = order.proof?.file_path || `http://localhost:5000${order.payment_proof}`;
        console.log('URL del comprobante:', proofUrl);
        setSelectedProof(proofUrl);
    };

    const handleAuthorizeClick = (orderId) => {
        setSelectedOrderId(orderId);
        setAuthorizeDialogOpen(true);
    };

    const handleAuthorize = async () => {
        try {
            const token = localStorage.getItem('adminToken');
            
            const response = await axios.post(
                `${process.env.REACT_APP_BACKEND_URL}/orders/${selectedOrderId}/authorize`,
                {
                    guideId: selectedGuide || null
                },
                {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                }
            );

            if (response.data.success) {
                setSnackbar({
                    open: true,
                    message: selectedGuide 
                        ? 'Orden autorizada y cuenta enviada con guía' 
                        : 'Orden autorizada y cuenta enviada',
                    severity: 'success'
                });
                fetchOrders();
                setAuthorizeDialogOpen(false);
                setSelectedGuide('');
            }
        } catch (error) {
            if (error.response?.status === 401) {
                // Token expirado o inválido
                localStorage.removeItem('adminToken'); // Limpiar el token expirado
                navigate('/admin/login'); // Redirigir al login
                setSnackbar({
                    open: true,
                    message: 'Sesión expirada. Por favor, inicie sesión nuevamente.',
                    severity: 'warning'
                });
            } else {
                setSnackbar({
                    open: true,
                    message: error.response?.data?.message || 'Error al autorizar la orden',
                    severity: 'error'
                });
            }
        }
    };

    const handleCloseSnackbar = () => {
        setSnackbar(prev => ({ ...prev, open: false }));
    };

    return (
        <Box sx={{ 
            minHeight: '100vh', 
            bgcolor: 'background.default',
            pt: { xs: 10, sm: 12 },
            pb: 4,
            mt: 8
        }}>
            <Container maxWidth="lg">
                <Typography variant="h4" gutterBottom sx={{ color: 'white', mb: 4 }}>
                    Gestionar Compras
                </Typography>

                <Paper sx={{ 
                    width: '100%', 
                    overflow: 'hidden',
                    bgcolor: 'rgba(0, 0, 0, 0.5)',
                    backdropFilter: 'blur(10px)',
                    border: '1px solid rgba(255, 255, 255, 0.1)'
                }}>
                    {loading ? (
                        <Typography sx={{ p: 2, color: 'white' }}>Cargando órdenes...</Typography>
                    ) : orders.length === 0 ? (
                        <Typography sx={{ p: 2, color: 'white' }}>No hay órdenes pendientes de autorización</Typography>
                    ) : (
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>ID</TableCell>
                                    <TableCell>Email</TableCell>
                                    <TableCell>Juego</TableCell>
                                    <TableCell>Total</TableCell>
                                    <TableCell>Fecha de Pago</TableCell>
                                    <TableCell>Acciones</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {orders.map((order) => (
                                    <TableRow key={order.id}>
                                        <TableCell>{order.id}</TableCell>
                                        <TableCell>{order.email}</TableCell>
                                        <TableCell>{order.game?.name || 'N/A'}</TableCell>
                                        <TableCell>${formatCOP(order.total)}</TableCell>
                                        <TableCell>
                                            {order.payment_date ? new Date(order.payment_date).toLocaleDateString() : 'No pagado'}
                                        </TableCell>
                                        <TableCell>
                                            <IconButton 
                                                onClick={() => handleViewProof(order)}
                                                sx={{ 
                                                    color: (order.proof || order.payment_proof) ? 'primary.main' : 'text.disabled',
                                                    mr: 1 
                                                }}
                                                disabled={!order.proof && !order.payment_proof}
                                            >
                                                <VisibilityIcon />
                                            </IconButton>
                                            <IconButton
                                                onClick={() => handleAuthorizeClick(order.id)}
                                                sx={{ 
                                                    color: 'info.main',
                                                    mr: 1,
                                                    '&:hover': {
                                                        color: 'info.dark'
                                                    }
                                                }}
                                                disabled={!order.proof && !order.payment_proof}
                                            >
                                                <MenuBookIcon />
                                            </IconButton>
                                            <IconButton
                                                onClick={() => handleAuthorize(order.id)}
                                                sx={{ 
                                                    color: 'success.main',
                                                    '&:hover': {
                                                        color: 'success.dark'
                                                    }
                                                }}
                                                disabled={!order.proof && !order.payment_proof}
                                            >
                                                <CheckCircleIcon />
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    )}
                </Paper>

                {/* Dialog para mostrar el comprobante */}
                <Dialog 
                    open={!!selectedProof} 
                    onClose={() => setSelectedProof(null)}
                    maxWidth="md"
                    fullWidth
                >
                    <DialogTitle>Comprobante de Pago</DialogTitle>
                    <DialogContent>
                        {selectedProof && (
                            <img 
                                src={selectedProof} 
                                alt="Comprobante" 
                                style={{ width: '100%', height: 'auto' }}
                            />
                        )}
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => setSelectedProof(null)}>
                            Cerrar
                        </Button>
                    </DialogActions>
                </Dialog>

                {/* Dialog para autorizar con guía */}
                <Dialog 
                    open={authorizeDialogOpen} 
                    onClose={() => setAuthorizeDialogOpen(false)}
                    maxWidth="sm"
                    fullWidth
                >
                    <DialogTitle>Autorizar Orden</DialogTitle>
                    <DialogContent>
                        <FormControl fullWidth sx={{ mt: 2 }}>
                            <InputLabel>Seleccionar Guía (Opcional)</InputLabel>
                            <Select
                                value={selectedGuide}
                                label="Seleccionar Guía (Opcional)"
                                onChange={(e) => setSelectedGuide(e.target.value)}
                            >
                                <MenuItem value="">
                                    <em>Sin guía</em>
                                </MenuItem>
                                {guides.filter(guide => 
                                    guide.section === 'compartida' || 
                                    guide.section === 'denuvo' || 
                                    guide.section === 'ubisoft'
                                ).map((guide) => (
                                    <MenuItem key={guide.id} value={guide.id}>
                                        <Box>
                                            <Typography variant="subtitle1">
                                                {guide.title}
                                            </Typography>
                                            <Typography variant="caption" color="textSecondary">
                                                Sección: {guide.section}
                                            </Typography>
                                        </Box>
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => setAuthorizeDialogOpen(false)}>
                            Cancelar
                        </Button>
                        <Button 
                            onClick={handleAuthorize}
                            variant="contained" 
                            color="primary"
                        >
                            Autorizar
                        </Button>
                    </DialogActions>
                </Dialog>

                <Snackbar 
                    open={snackbar.open} 
                    autoHideDuration={6000} 
                    onClose={handleCloseSnackbar}
                >
                    <Alert 
                        onClose={handleCloseSnackbar} 
                        severity={snackbar.severity}
                        sx={{ width: '100%' }}
                    >
                        {snackbar.message}
                    </Alert>
                </Snackbar>
            </Container>
        </Box>
    );
}

export default AuthorizeOrders; 