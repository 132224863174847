import React from 'react';
import { 
    Container, Box, Typography, Paper, Avatar,
    Divider, Grid
} from '@mui/material';
import { useAuth } from '../../context/AuthContext';
import PersonIcon from '@mui/icons-material/Person';
import EmailIcon from '@mui/icons-material/Email';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';

function Profile() {
    const { user } = useAuth();

    if (!user) {
        return (
            <Box sx={{ minHeight: '100vh', pt: { xs: 12, md: 16 }, pb: 8 }}>
                <Container>
                    <Typography color="white">Cargando perfil...</Typography>
                </Container>
            </Box>
        );
    }

    return (
        <Box sx={{ minHeight: '100vh', pt: { xs: 12, md: 16 }, pb: 8 }}>
            <Container maxWidth="lg">
                <Paper
                    sx={{
                        p: 4,
                        background: 'rgba(0, 0, 0, 0.7)',
                        backdropFilter: 'blur(10px)',
                        borderRadius: '16px',
                        border: '1px solid rgba(255, 255, 255, 0.1)'
                    }}
                >
                    <Box sx={{ 
                        display: 'flex', 
                        flexDirection: { xs: 'column', md: 'row' },
                        alignItems: { xs: 'center', md: 'flex-start' },
                        gap: 4
                    }}>
                        {/* Avatar y nombre */}
                        <Box sx={{ 
                            display: 'flex', 
                            flexDirection: 'column',
                            alignItems: 'center',
                            gap: 2
                        }}>
                            <Avatar
                                sx={{
                                    width: 150,
                                    height: 150,
                                    bgcolor: '#6A0DAD',
                                    fontSize: '4rem'
                                }}
                            >
                                {user.username?.charAt(0).toUpperCase()}
                            </Avatar>
                            <Typography variant="h4" color="white" sx={{ fontWeight: 'bold' }}>
                                {user.username}
                            </Typography>
                        </Box>

                        {/* Información del usuario */}
                        <Box sx={{ flex: 1 }}>
                            <Typography variant="h5" color="#00FFFF" gutterBottom>
                                Información del Perfil
                            </Typography>
                            <Divider sx={{ mb: 3, borderColor: 'rgba(255, 255, 255, 0.1)' }} />
                            
                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                                        <PersonIcon sx={{ color: '#00FFFF' }} />
                                        <Box>
                                            <Typography variant="body2" color="rgba(255, 255, 255, 0.7)">
                                                Nombre de usuario
                                            </Typography>
                                            <Typography color="white">
                                                {user.username}
                                            </Typography>
                                        </Box>
                                    </Box>
                                </Grid>

                                <Grid item xs={12}>
                                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                                        <EmailIcon sx={{ color: '#00FFFF' }} />
                                        <Box>
                                            <Typography variant="body2" color="rgba(255, 255, 255, 0.7)">
                                                Correo electrónico
                                            </Typography>
                                            <Typography color="white">
                                                {user.email}
                                            </Typography>
                                        </Box>
                                    </Box>
                                </Grid>

                                <Grid item xs={12}>
                                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                                        <CalendarTodayIcon sx={{ color: '#00FFFF' }} />
                                        <Box>
                                            <Typography variant="body2" color="rgba(255, 255, 255, 0.7)">
                                                Miembro desde
                                            </Typography>
                                            <Typography color="white">
                                                {new Date().toLocaleDateString()}
                                            </Typography>
                                        </Box>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>
                    </Box>
                </Paper>
            </Container>
        </Box>
    );
}

export default Profile; 