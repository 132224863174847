import React, { useState } from 'react';
import {
    Box,
    Container,
    TextField,
    Button,
    Typography,
    Grid,
    Paper,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Alert,
    InputAdornment,
    Collapse
} from '@mui/material';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import AdminNavbar from '../../components/AdminNavbar';
import PriceInput from '../../components/PriceInput';
import { formatCOP } from '../../utils/formatPrice';

function AddGame() {
    const navigate = useNavigate();
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');
    const [loading, setLoading] = useState(false);
    const [formData, setFormData] = useState({
        name: '',
        originalPrice: '',
        price: '',
        discount: '0',
        description: '',
        longDescription: '',
        image: null,
        bannerImage: null,
        min_os: '',
        min_processor: '',
        min_memory: '',
        min_graphics: '',
        min_storage: '',
        min_directx: '',
        rec_os: '',
        rec_processor: '',
        rec_memory: '',
        rec_graphics: '',
        rec_storage: '',
        rec_directx: '',
        audio: '',
        subtitles: '',
        genre: '',
        releaseDate: '',
        platform: '',
        developer: '',
        publisher: ''
    });
    const [imagePreview, setImagePreview] = useState(null);
    const [discountAmount, setDiscountAmount] = useState('');
    const [bannerPreview, setBannerPreview] = useState(null);
    const [additionalAccountType, setAdditionalAccountType] = useState('no');
    const [additionalAccountEmail, setAdditionalAccountEmail] = useState('');
    const [additionalAccountPassword, setAdditionalAccountPassword] = useState('');

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prev => ({
            ...prev,
            [name]: value
        }));
    };

    const handleImageChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            setFormData(prev => ({
                ...prev,
                image: file
            }));

            // Crear URL para vista previa
            const reader = new FileReader();
            reader.onloadend = () => {
                setImagePreview(reader.result);
            };
            reader.readAsDataURL(file);
        }
    };

    const handleDiscountAmountChange = (e) => {
        const amountToDiscount = e.target.value === '' ? 0 : Number(e.target.value);
        const originalPrice = Number(formData.originalPrice);
        
        if (amountToDiscount > originalPrice) {
            return;
        }

        const finalPrice = originalPrice - amountToDiscount;
        const calculatedDiscount = Math.round((amountToDiscount / originalPrice) * 100);

        setDiscountAmount(e.target.value);
        setFormData(prev => ({
            ...prev,
            originalPrice: originalPrice,
            price: finalPrice,
            discount: calculatedDiscount
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        setError('');
        setSuccess('');

        try {
            const submitData = new FormData();
            
            // Validar que se hayan seleccionado ambas imágenes
            if (!formData.image) {
                setError('Se requiere una imagen de portada');
                setLoading(false);
                return;
            }

            if (!formData.bannerImage) {
                setError('Se requiere una imagen de banner');
                setLoading(false);
                return;
            }

            // Agregar todos los campos al FormData
            Object.keys(formData).forEach(key => {
                if (formData[key] !== null) {
                    if (key === 'image' || key === 'bannerImage') {
                        submitData.append(key, formData[key]);
                    } else {
                        submitData.append(key, formData[key]);
                    }
                }
            });

            submitData.append('additional_account_type', additionalAccountType);
            if (additionalAccountType !== 'no') {
                submitData.append('additional_account_email', additionalAccountEmail);
                submitData.append('additional_account_password', additionalAccountPassword);
            }

            const token = localStorage.getItem('adminToken');
            if (!token) {
                setError('No hay token de autenticación. Por favor, inicie sesión nuevamente.');
                navigate('/admin/login');
                return;
            }

            const response = await axios.post(
                `${process.env.REACT_APP_BACKEND_URL}/games/add`, 
                submitData, 
                {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        'Authorization': `Bearer ${token}`
                    }
                }
            );

            if (response.data.success) {
                setSuccess('Juego agregado exitosamente');
                setTimeout(() => {
                    navigate('/admin/edit-games');
                }, 2000);
            }
        } catch (error) {
            console.error('Error completo:', error);
            setError(error.response?.data?.message || 'Error al agregar el juego');
        } finally {
            setLoading(false);
        }
    };

    const handleBannerChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            setFormData(prev => ({
                ...prev,
                bannerImage: file
            }));

            // Opcional: Crear vista previa del banner
            const reader = new FileReader();
            reader.onloadend = () => {
                setBannerPreview(reader.result);  // Necesitarás crear este estado
            };
            reader.readAsDataURL(file);
        }
    };

    return (
        <Box sx={{ minHeight: '100vh', bgcolor: 'background.default' }}>
            <AdminNavbar />
            <Container maxWidth="lg" sx={{ pt: 12, pb: 4 }}>
                <Paper elevation={3} sx={{ p: 4, bgcolor: 'background.paper' }}>
                    <Typography variant="h4" gutterBottom>
                        Agregar Nuevo Juego
                    </Typography>

                    {error && (
                        <Alert severity="error" sx={{ mb: 2 }}>
                            {error}
                        </Alert>
                    )}

                    {success && (
                        <Alert severity="success" sx={{ mb: 2 }}>
                            {success}
                        </Alert>
                    )}

                    <form onSubmit={handleSubmit}>
                        <Grid container spacing={3}>
                            {/* Información básica */}
                            <Grid item xs={12} md={6}>
                                <TextField
                                    fullWidth
                                    label="Nombre del juego"
                                    name="name"
                                    value={formData.name}
                                    onChange={handleChange}
                                    required
                                />
                            </Grid>

                            <Grid item xs={12} md={6}>
                                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                                    <Button
                                        variant="contained"
                                        component="label"
                                        fullWidth
                                    >
                                        Subir Imagen
                                        <input
                                            type="file"
                                            hidden
                                            accept="image/*"
                                            onChange={handleImageChange}
                                            name="image"
                                        />
                                    </Button>
                                    
                                    {/* Vista previa de la imagen */}
                                    {imagePreview && (
                                        <Box sx={{ 
                                            mt: 2, 
                                            position: 'relative',
                                            width: '100%',
                                            height: '200px',
                                            borderRadius: '8px',
                                            overflow: 'hidden'
                                        }}>
                                            <img
                                                src={imagePreview}
                                                alt="Vista previa"
                                                style={{
                                                    width: '100%',
                                                    height: '100%',
                                                    objectFit: 'cover'
                                                }}
                                            />
                                            <Button
                                                variant="contained"
                                                color="error"
                                                size="small"
                                                onClick={() => {
                                                    setImagePreview(null);
                                                    setFormData(prev => ({
                                                        ...prev,
                                                        image: null
                                                    }));
                                                }}
                                                sx={{
                                                    position: 'absolute',
                                                    top: 8,
                                                    right: 8
                                                }}
                                            >
                                                Eliminar
                                            </Button>
                                        </Box>
                                    )}

                                    {/* Mensaje de estado de la imagen */}
                                    <Typography variant="caption" color="textSecondary">
                                        {formData.image ? 
                                            `Imagen seleccionada: ${formData.image.name}` : 
                                            'Ninguna imagen seleccionada'}
                                    </Typography>
                                </Box>
                            </Grid>

                            {/* Precios */}
                            <Grid item xs={12} md={4}>
                                <TextField
                                    fullWidth
                                    label="Precio Original"
                                    name="originalPrice"
                                    type="number"
                                    value={formData.originalPrice}
                                    onChange={handleChange}
                                    required
                                    InputProps={{
                                        startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                    }}
                                    inputProps={{
                                        style: { 
                                            color: 'white',
                                            height: '1.4375em',
                                            padding: '16.5px 14px'
                                        }
                                    }}
                                    sx={{ 
                                        '& .MuiInputBase-root': {
                                            height: '56px'
                                        },
                                        '& .MuiOutlinedInput-root': {
                                            '& fieldset': {
                                                borderColor: 'rgba(255, 255, 255, 0.23)',
                                            },
                                            '&:hover fieldset': {
                                                borderColor: 'rgba(255, 255, 255, 0.5)',
                                            },
                                            '&.Mui-focused fieldset': {
                                                borderColor: 'rgba(138, 43, 226, 0.8)',
                                            }
                                        },
                                        '& .MuiInputLabel-root': {
                                            color: 'rgba(255, 255, 255, 0.7)',
                                            '&.Mui-focused': {
                                                color: 'rgba(138, 43, 226, 0.8)'
                                            }
                                        }
                                    }}
                                />
                            </Grid>

                            <Grid item xs={12} md={4}>
                                <TextField
                                    fullWidth
                                    label="Monto a descontar"
                                    name="discountAmount"
                                    type="number"
                                    value={discountAmount}
                                    onChange={handleDiscountAmountChange}
                                    InputProps={{
                                        startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                    }}
                                    inputProps={{ 
                                        min: 0, 
                                        max: formData.originalPrice,
                                        step: "1000",
                                        style: { 
                                            color: 'white',
                                            height: '1.4375em',
                                            padding: '16.5px 14px'
                                        }
                                    }}
                                    sx={{ 
                                        '& .MuiInputBase-root': {
                                            height: '56px',
                                        },
                                        '& .MuiOutlinedInput-root': {
                                            '& fieldset': {
                                                borderColor: 'rgba(255, 255, 255, 0.23)',
                                            },
                                            '&:hover fieldset': {
                                                borderColor: 'rgba(255, 255, 255, 0.5)',
                                            },
                                            '&.Mui-focused fieldset': {
                                                borderColor: 'rgba(138, 43, 226, 0.8)',
                                            }
                                        },
                                        '& label': { 
                                            color: 'rgba(255, 255, 255, 0.7)'
                                        },
                                        '& label.Mui-focused': {
                                            color: 'rgba(138, 43, 226, 0.8)'
                                        }
                                    }}
                                />
                            </Grid>

                            <Grid item xs={12} md={4}>
                                <TextField
                                    fullWidth
                                    label="Precio Final"
                                    value={formatCOP(formData.price)}
                                    disabled
                                    InputProps={{
                                        startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                        readOnly: true
                                    }}
                                    inputProps={{
                                        style: { 
                                            color: '#00FFFF',
                                            height: '1.4375em',
                                            padding: '16.5px 14px'
                                        }
                                    }}
                                    sx={{ 
                                        '& .MuiInputBase-root': {
                                            height: '56px',
                                            background: 'rgba(0, 255, 255, 0.05)'
                                        },
                                        '& .MuiOutlinedInput-root': {
                                            '& fieldset': {
                                                borderColor: 'rgba(0, 255, 255, 0.2)',
                                            },
                                            '&:hover fieldset': {
                                                borderColor: 'rgba(0, 255, 255, 0.3)',
                                            },
                                            '&.Mui-disabled': {
                                                '& fieldset': {
                                                    borderColor: 'rgba(0, 255, 255, 0.2)',
                                                }
                                            }
                                        },
                                        '& .MuiInputLabel-root': {
                                            color: 'rgba(0, 255, 255, 0.7)',
                                            '&.Mui-disabled': {
                                                color: 'rgba(0, 255, 255, 0.7)'
                                            }
                                        },
                                        '& .Mui-disabled': {
                                            '-webkit-text-fill-color': '#00FFFF'
                                        }
                                    }}
                                />
                            </Grid>

                            {discountAmount > 0 && (
                                <Grid item xs={12}>
                                    <Box sx={{ 
                                        p: 2, 
                                        mt: 1,
                                        borderRadius: 1,
                                        background: 'rgba(0, 255, 255, 0.1)',
                                        border: '1px solid rgba(0, 255, 255, 0.2)'
                                    }}>
                                        <Typography sx={{ color: '#00FFFF', fontSize: '0.9rem' }}>
                                            Precio original: ${formatCOP(formData.originalPrice)} COP
                                        </Typography>
                                        <Typography sx={{ color: '#00FFFF', fontSize: '0.9rem' }}>
                                            Descuento aplicado: {formData.discount}%
                                        </Typography>
                                        <Typography sx={{ color: '#00FFFF', fontSize: '0.9rem' }}>
                                            Precio final: ${formatCOP(formData.price)} COP
                                        </Typography>
                                    </Box>
                                </Grid>
                            )}

                            {/* Descripciones */}
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    label="Descripción Corta"
                                    name="description"
                                    multiline
                                    rows={2}
                                    value={formData.description}
                                    onChange={handleChange}
                                    required
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    label="Descripción Larga"
                                    name="longDescription"
                                    multiline
                                    rows={4}
                                    value={formData.longDescription}
                                    onChange={handleChange}
                                    required
                                />
                            </Grid>

                            <Grid item xs={12} md={6}>
                                <Typography variant="subtitle1" sx={{ color: 'white', mb: 2 }}>
                                    Banner del Juego
                                </Typography>
                                <input
                                    accept="image/*"
                                    style={{ display: 'none' }}
                                    id="banner-image-upload"
                                    type="file"
                                    onChange={handleBannerChange}
                                />
                                <label htmlFor="banner-image-upload">
                                    <Button variant="contained" component="span" fullWidth>
                                        Subir Banner
                                    </Button>
                                </label>
                                {/* Opcional: Mostrar vista previa del banner */}
                                {bannerPreview && (
                                    <Box sx={{ mt: 2 }}>
                                        <img 
                                            src={bannerPreview}
                                            alt="Banner preview"
                                            style={{ 
                                                maxWidth: '100%',
                                                height: 'auto',
                                                borderRadius: '8px'
                                            }}
                                        />
                                    </Box>
                                )}
                            </Grid>

                            <Grid item xs={12}>
                                <FormControl fullWidth>
                                    <InputLabel>¿Requiere Cuenta Adicional?</InputLabel>
                                    <Select
                                        value={additionalAccountType}
                                        onChange={(e) => setAdditionalAccountType(e.target.value)}
                                        label="¿Requiere Cuenta Adicional?"
                                    >
                                        <MenuItem value="no">No</MenuItem>
                                        <MenuItem value="ubisoft">Ubisoft</MenuItem>
                                        <MenuItem value="ea">EA</MenuItem>
                                        <MenuItem value="rockstar">Rockstar</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>

                            {/* Botón de envío con más feedback */}
                            <Grid item xs={12}>
                                <Button
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                    fullWidth
                                    disabled={loading || !formData.image}
                                    sx={{
                                        height: 56,
                                        position: 'relative'
                                    }}
                                >
                                    {loading ? 'Agregando...' : 'Agregar Juego'}
                                </Button>
                                {!formData.image && (
                                    <Typography 
                                        variant="caption" 
                                        color="error"
                                        sx={{ mt: 1, display: 'block' }}
                                    >
                                        Se requieren tanto la imagen de portada como el banner del juego
                                    </Typography>
                                )}
                            </Grid>
                        </Grid>
                    </form>
                </Paper>
            </Container>
        </Box>
    );
}

export default AddGame; 