import React from 'react';
import { keyframes } from '@emotion/react';
import { Box } from '@mui/material';

const twinkle = keyframes`
  0% { opacity: 0.2; }
  50% { opacity: 0.8; }
  100% { opacity: 0.2; }
`;

const StarBackground = () => {
    const generateStars = (count) => {
        return Array.from({ length: count }, (_, i) => ({
            id: i,
            left: `${Math.random() * 100}%`,
            top: `${Math.random() * 100}%`,
            size: Math.random() * 0.04 + 0.01,
            duration: Math.random() * 6 + 4
        }));
    };

    const stars = generateStars(35);

    return (
        <Box
            sx={{
                position: 'fixed',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                pointerEvents: 'none',
                zIndex: 0,
                overflow: 'hidden',
                backdropFilter: 'blur(120px)',
                background: 'rgba(0,0,0,0.25)'
            }}
        >
            {stars.map((star) => (
                <Box
                    key={star.id}
                    sx={{
                        position: 'absolute',
                        left: star.left,
                        top: star.top,
                        width: star.size,
                        height: star.size,
                        backgroundColor: '#9D00FF',
                        borderRadius: '50%',
                        boxShadow: 'none',
                        animation: `${twinkle} ${star.duration}s infinite`,
                        animationDelay: `${Math.random() * 3}s`,
                        opacity: Math.random() * 0.02 + 0.003,
                        filter: 'blur(1px) brightness(1.5)',
                        mixBlendMode: 'screen'
                    }}
                />
            ))}
        </Box>
    );
};

export default StarBackground; 