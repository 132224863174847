import React, { useState, useEffect } from 'react';
import { Box, Typography } from '@mui/material';

const ImageWithFallback = ({ src, alt, fallbackSrc, ...props }) => {
    const [imgSrc, setImgSrc] = useState(src);
    const [retryCount, setRetryCount] = useState(0);
    const maxRetries = 3;

    useEffect(() => {
        setImgSrc(src);
        setRetryCount(0);
    }, [src]);

    const handleError = (error) => {
        console.log('Error cargando imagen - Detalles:', {
            originalSrc: src,
            currentSrc: imgSrc,
            retryCount,
            maxRetries,
            timestamp: new Date().toISOString()
        });

        if (retryCount < maxRetries) {
            setRetryCount(prev => prev + 1);
            // Intentar cargar la imagen de nuevo después de un breve retraso
            setTimeout(() => setImgSrc(src), 1000);
        } else if (fallbackSrc) {
            console.log('Usando imagen de fallback:', fallbackSrc);
            setImgSrc(fallbackSrc);
        }
    };

    return (
        <img
            src={imgSrc}
            alt={alt}
            onError={handleError}
            style={{ 
                ...props.style,
                objectFit: 'cover',
                width: '100%',
                height: '100%'
            }}
            {...props}
        />
    );
};

export default ImageWithFallback; 