import React, { useState, useEffect } from 'react';
import { 
    Container, Box, Typography, Button, Grid, 
    Paper, MenuItem, TextField
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import axios from 'axios';
import { formatCOP } from '../../utils/formatPrice';

function AddCarousel() {
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();
    const [games, setGames] = useState([]);
    const [selectedGame, setSelectedGame] = useState(null);
    const [loading, setLoading] = useState(false);
    const [carouselData, setCarouselData] = useState({
        title: '',
        subtitle: '',
        gameId: '',
    });

    // Mantener la función fetchGames existente
    useEffect(() => {
        const fetchGames = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/games`);
                if (response.data.success) {
                    setGames(response.data.data);
                }
            } catch (error) {
                enqueueSnackbar('Error al cargar los juegos', { variant: 'error' });
            }
        };
        fetchGames();
    }, [enqueueSnackbar]);

    // Actualizar handleSubmit para usar la imagen banner del juego
    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        try {
            // Asegurarnos de que estamos usando la imagen banner del juego seleccionado
            const gameData = games.find(g => g.id === carouselData.gameId);
            const carouselPayload = {
                ...carouselData,
                image: gameData.banner_image, // Usar la imagen banner del juego
                price: gameData.price,
                discount: gameData.discount,
                originalPrice: gameData.originalPrice
            };

            const response = await axios.post(
                `${process.env.REACT_APP_BACKEND_URL}/carousel`,
                carouselPayload
            );

            if (response.data.success) {
                enqueueSnackbar('Carrusel creado exitosamente', { variant: 'success' });
                navigate('/admin/carousel');
            }
        } catch (error) {
            enqueueSnackbar('Error al crear el carrusel', { variant: 'error' });
        } finally {
            setLoading(false);
        }
    };

    // Mantener el handleGameChange existente con ajustes para usar el banner
    const handleGameChange = (event) => {
        const game = games.find(g => g.id === event.target.value);
        setSelectedGame(game);
        setCarouselData(prev => ({
            ...prev,
            gameId: game.id,
            title: game.name,
            subtitle: game.discount > 0 ? `¡${game.discount}% de descuento!` : 'Disponible ahora'
        }));
    };

    // El resto del JSX se mantiene igual, solo asegurarnos de mostrar la imagen banner
    return (
        <Box sx={{ minHeight: '100vh', pt: { xs: 12, md: 16 }, pb: 8 }}>
            <Container maxWidth="lg">
                <Paper sx={{ p: 4, bgcolor: 'background.paper' }}>
                    <Typography variant="h4" sx={{ mb: 4, color: 'white' }}>
                        Agregar Nuevo Carrusel
                    </Typography>
                    <form onSubmit={handleSubmit}>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <TextField
                                    select
                                    fullWidth
                                    label="Seleccionar Juego"
                                    value={carouselData.gameId}
                                    onChange={handleGameChange}
                                    required
                                >
                                    {games.map((game) => (
                                        <MenuItem key={game.id} value={game.id}>
                                            {game.name}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Grid>

                            {selectedGame && (
                                <Grid item xs={12}>
                                    <Box sx={{ mt: 2 }}>
                                        <img 
                                            src={selectedGame.banner_image}
                                            alt={selectedGame.name}
                                            style={{ 
                                                width: '100%',
                                                maxHeight: '300px',
                                                objectFit: 'cover',
                                                borderRadius: '8px'
                                            }}
                                        />
                                    </Box>
                                </Grid>
                            )}

                            <Grid item xs={12}>
                                <Button
                                    type="submit"
                                    variant="contained"
                                    fullWidth
                                    disabled={loading || !selectedGame}
                                    sx={{
                                        mt: 3,
                                        bgcolor: 'var(--accent-color)',
                                        '&:hover': {
                                            bgcolor: 'var(--accent-dark)'
                                        }
                                    }}
                                >
                                    {loading ? 'Creando...' : 'Crear Carrusel'}
                                </Button>
                            </Grid>
                        </Grid>
                    </form>
                </Paper>
            </Container>
        </Box>
    );
}

export default AddCarousel; 