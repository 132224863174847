export const images = {
    // Imágenes de Hero y fondos
    hero: "https://images.unsplash.com/photo-1542751371-adc38448a05e?w=1200",
    heroDevices: "https://assets.stickpng.com/images/580b57fcd9996e24bc43c529.png",
    gridPattern: "https://transparenttextures.com/patterns/cubes.png",

    // Logos de plataformas gaming
    steamLogo: "https://upload.wikimedia.org/wikipedia/commons/thumb/8/83/Steam_icon_logo.svg/2048px-Steam_icon_logo.svg.png",
    originLogo: "https://upload.wikimedia.org/wikipedia/commons/thumb/f/f2/EA_Sports_FC_24_logo.svg/2048px-EA_Sports_FC_24_logo.svg.png",
    epicLogo: "https://upload.wikimedia.org/wikipedia/commons/thumb/3/31/Epic_Games_logo.svg/1764px-Epic_Games_logo.svg.png",

    // Imágenes de juegos
    products: {
        aaa: {
            fc24: "https://image.api.playstation.com/vulcan/ap/rnd/202307/0710/60c78360e4d0ae7e6b80f09eb4416dc7f553e009e4c407c7.png",
            cyberpunk: "https://image.api.playstation.com/vulcan/ap/rnd/202111/3013/cKZ4tKNFj9C00DYkxqW8PgDE.png",
            rdr2: "https://image.api.playstation.com/vulcan/img/rnd/202009/2913/TQKAd8U6hnIFQIIcz6qnFh8C.png"
        },
        action: {
            gtav: "https://image.api.playstation.com/vulcan/img/rnd/202009/2917/X3WIAh63yKhRRiMohLoJMeQa.png",
            eldenring: "https://image.api.playstation.com/vulcan/ap/rnd/202110/2000/phvVT0qZfcRms5qDAk0SI3CM.png",
            godofwar: "https://image.api.playstation.com/vulcan/ap/rnd/202207/1210/4wstqWi0Z5c8V4U4K6UuJUsM.png"
        },
        sports: {
            nba2k24: "https://image.api.playstation.com/vulcan/ap/rnd/202307/0711/dd6a56d0a551a7a1a54f4af1d6f2f66a2814b0ce7937e532.png",
            f123: "https://image.api.playstation.com/vulcan/ap/rnd/202302/2321/78d576fba3803d1baf2b2468a8e445d78bd1e8e6cd324862.png",
            wwe2k23: "https://image.api.playstation.com/vulcan/ap/rnd/202211/3007/AjU9QqcHq2XrIBqjuLLzZLcN.png"
        }
    },

    // Imágenes para el blog
    blog: {
        gaming: "https://images.unsplash.com/photo-1542751371-adc38448a05e?w=800",
        esports: "https://images.unsplash.com/photo-1542751110-97427bbecf20?w=800",
        tech: "https://images.unsplash.com/photo-1550745165-9bc0b252726f?w=800"
    },

    // Avatares para testimonios
    avatars: {
        user1: "https://images.unsplash.com/photo-1535713875002-d1d0cf377fde?w=200",
        user2: "https://images.unsplash.com/photo-1494790108377-be9c29b29330?w=200",
        user3: "https://images.unsplash.com/photo-1527980965255-d3b416303d12?w=200"
    },

    // Categorías de juegos
    categories: {
        action: "https://images.unsplash.com/photo-1542751371-adc38448a05e?w=800",
        sports: "https://images.unsplash.com/photo-1461896836934-ffe607ba8211?w=800",
        rpg: "https://images.unsplash.com/photo-1542751110-97427bbecf20?w=800",
        strategy: "https://images.unsplash.com/photo-1553481187-be93c21490a9?w=800"
    }
}; 