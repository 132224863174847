import React, { useState, useEffect } from 'react';
import { 
    Container, Box, Typography, Grid, Button, Radio, 
    RadioGroup, FormControlLabel, FormControl 
} from '@mui/material';
import { useParams } from 'react-router-dom';
import { motion } from 'framer-motion';
import axios from 'axios';
import { formatPrice } from '../utils/formatters';

const defaultImage = 'https://blackvortexgames.com/api/images/default-game.jpg';

function GameDetails() {
    const { id } = useParams();
    const [game, setGame] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [selectedVersion, setSelectedVersion] = useState('primary');
    const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;
    
    useEffect(() => {
        const fetchGame = async () => {
            try {
                setLoading(true);
                console.log('ID del juego solicitado:', id);
                const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/games/${id}`);
                console.log('Respuesta completa:', response);
                console.log('Datos del juego:', response.data);
                const gameData = {
                    ...response.data,
                    systemRequirements: response.data.systemRequirements || {
                        minimum: {
                            os: 'No especificado',
                            processor: 'No especificado',
                            memory: 'No especificado',
                            graphics: 'No especificado',
                            storage: 'No especificado'
                        },
                        recommended: {
                            os: 'No especificado',
                            processor: 'No especificado',
                            memory: 'No especificado',
                            graphics: 'No especificado',
                            storage: 'No especificado'
                        }
                    }
                };
                setGame(gameData);
            } catch (error) {
                console.error('Error detallado:', error);
                console.error('Respuesta de error:', error.response);
                setError(error.response?.data?.message || 'Error al cargar el juego');
            } finally {
                setLoading(false);
            }
        };

        fetchGame();
    }, [id, BACKEND_URL]);

    if (loading) {
        return (
            <Box sx={{ 
                minHeight: '100vh', 
                display: 'flex', 
                alignItems: 'center', 
                justifyContent: 'center',
                color: 'white'
            }}>
                <Typography variant="h5">Cargando detalles del juego...</Typography>
            </Box>
        );
    }

    if (error) {
        return (
            <Box sx={{ 
                minHeight: '100vh', 
                display: 'flex', 
                alignItems: 'center', 
                justifyContent: 'center',
                color: 'error.main'
            }}>
                <Typography variant="h5">Error: {error}</Typography>
            </Box>
        );
    }

    if (!game) {
        return (
            <Box sx={{ 
                minHeight: '100vh', 
                display: 'flex', 
                alignItems: 'center', 
                justifyContent: 'center',
                color: 'white'
            }}>
                <Typography variant="h5">Juego no encontrado</Typography>
            </Box>
        );
    }

    const { minimum = {}, recommended = {} } = game.systemRequirements || {};

    return (
        <Box sx={{ 
            minHeight: '100vh', 
            pt: { xs: 12, md: 16 }, 
            pb: 8,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
        }}>
            <Container maxWidth="lg">
                <Grid 
                    container 
                    spacing={4} 
                    sx={{
                        background: 'rgba(0, 0, 0, 0.5)',
                        backdropFilter: 'blur(10px)',
                        borderRadius: 2,
                        p: 3,
                        border: '1px solid rgba(106, 13, 173, 0.3)'
                    }}
                >
                    {/* Solo la Imagen Principal */}
                    <Grid item xs={12} md={6} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <Box
                            component="img"
                            sx={{
                                width: '100%',
                                height: 'auto',
                                borderRadius: '8px'
                            }}
                            src={game.image ? `https://blackvortexgames.com/api/images/games/${game.image.split('/').pop()}` : defaultImage}
                            alt={game.name}
                        />
                        {game.banner_image && (
                            <Box
                                component="img"
                                sx={{
                                    width: '100%',
                                    mt: 2,
                                    borderRadius: '8px'
                                }}
                                src={`https://blackvortexgames.com/api/images/games/${game.banner_image.split('/').pop()}`}
                                alt={`${game.name} banner`}
                            />
                        )}
                    </Grid>

                    {/* Información y Opciones de Compra */}
                    <Grid item xs={12} md={6} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <Box sx={{ width: '100%', maxWidth: '500px' }}>
                            <Typography 
                                variant="h3"
                                sx={{ 
                                    mb: 2,
                                    fontSize: { xs: '1.8rem', md: '2.2rem' },
                                    fontWeight: 600,
                                    textAlign: 'center'
                                }}
                            >
                                {game.name}
                            </Typography>
                            <Typography variant="subtitle1" sx={{ mb: 4 }}>
                                {game.description}
                            </Typography>

                            <Box 
                                sx={{ 
                                    mb: 4,
                                    p: 3,
                                    background: 'rgba(106, 13, 173, 0.1)',
                                    borderRadius: '10px',
                                    border: '1px solid rgba(106, 13, 173, 0.2)'
                                }}
                            >
                                <Box sx={{ textAlign: 'center' }}>
                                    <Typography 
                                        variant="h4" 
                                        sx={{ 
                                            color: '#00FFFF', 
                                            mb: game.discount > 0 ? 0 : 2,
                                            fontSize: '1.5rem',
                                            fontWeight: 600
                                        }}
                                    >
                                        ${Number(game.price).toLocaleString('es-CO')} COP
                                    </Typography>
                                    {game.discount > 0 && (
                                        <Typography
                                            variant="h6"
                                            sx={{
                                                color: 'rgba(255,255,255,0.6)',
                                                textDecoration: 'line-through',
                                                mt: 0.5,
                                                mb: 2,
                                                fontSize: '1.2rem'
                                            }}
                                        >
                                            ${Number(game.price / (1 - game.discount/100)).toLocaleString('es-CO')} COP
                                        </Typography>
                                    )}
                                </Box>
                            </Box>

                            <Box sx={{ display: 'flex', gap: 2 }}>
                                <Button
                                    variant="contained"
                                    size="large"
                                    sx={{
                                        background: 'linear-gradient(45deg, #6A0DAD, #9D00FF)',
                                        color: 'white',
                                        '&:hover': {
                                            background: 'linear-gradient(45deg, #9D00FF, #6A0DAD)',
                                        }
                                    }}
                                >
                                    Comprar Ahora
                                </Button>
                                <Button
                                    variant="outlined"
                                    size="large"
                                    sx={{
                                        borderColor: '#00FFFF',
                                        color: '#00FFFF',
                                        '&:hover': {
                                            borderColor: '#00FFFF',
                                            background: 'rgba(0, 255, 255, 0.1)',
                                        }
                                    }}
                                >
                                    Agregar al Carrito
                                </Button>
                            </Box>

                            {/* Información Adicional */}
                            <Box sx={{ mt: 4, textAlign: 'left', width: '100%' }}>
                                {/* Descripción del juego */}
                                <Typography 
                                    variant="h6" 
                                    sx={{ 
                                        mb: 2, 
                                        color: 'white',
                                        borderBottom: '2px solid var(--accent-color)',
                                        pb: 1
                                    }}
                                >
                                    Descripción
                                </Typography>
                                <Typography 
                                    variant="body1" 
                                    sx={{ 
                                        color: 'rgba(255,255,255,0.8)',
                                        mb: 4,
                                        lineHeight: 1.6
                                    }}
                                >
                                    {game.description}
                                </Typography>

                                {/* Características */}
                                <Typography 
                                    variant="h6" 
                                    sx={{ 
                                        mb: 2, 
                                        color: 'white',
                                        borderBottom: '2px solid var(--accent-color)',
                                        pb: 1
                                    }}
                                >
                                    Características Principales
                                </Typography>
                                <Typography sx={{ color: 'rgba(255,255,255,0.8)', whiteSpace: 'pre-line' }}>
                                    {game.longDescription}
                                </Typography>

                                {/* Requisitos */}
                                <Box sx={{ mt: 4 }}>
                                    <Typography 
                                        variant="h6" 
                                        sx={{ 
                                            mb: 2, 
                                            color: 'white',
                                            borderBottom: '2px solid var(--accent-color)',
                                            pb: 1
                                        }}
                                    >
                                        Requisitos del Sistema
                                    </Typography>
                                    <Grid container spacing={4}>
                                        <Grid item xs={12} md={6}>
                                            <Typography variant="subtitle1" color="var(--accent-color)" sx={{ mb: 2 }}>
                                                Mínimos
                                            </Typography>
                                            <Box sx={{ color: 'rgba(255,255,255,0.8)' }}>
                                                <Typography>SO: {minimum.os}</Typography>
                                                <Typography>Procesador: {minimum.processor}</Typography>
                                                <Typography>Memoria: {minimum.memory}</Typography>
                                                <Typography>Gráficos: {minimum.graphics}</Typography>
                                                <Typography>Almacenamiento: {minimum.storage}</Typography>
                                                <Typography>DirectX: {minimum.directx}</Typography>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <Typography variant="subtitle1" color="var(--accent-color)" sx={{ mb: 2 }}>
                                                Recomendados
                                            </Typography>
                                            <Box sx={{ color: 'rgba(255,255,255,0.8)' }}>
                                                <Typography>SO: {recommended.os}</Typography>
                                                <Typography>Procesador: {recommended.processor}</Typography>
                                                <Typography>Memoria: {recommended.memory}</Typography>
                                                <Typography>Gráficos: {recommended.graphics}</Typography>
                                                <Typography>Almacenamiento: {recommended.storage}</Typography>
                                                <Typography>DirectX: {recommended.directx}</Typography>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    );
}

export default GameDetails; 