import React from 'react';
import { Box, IconButton } from '@mui/material';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import YouTubeIcon from '@mui/icons-material/YouTube';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';

function SocialBar() {
    return (
        <Box
            sx={{
                position: 'fixed',
                right: 0,
                top: '50%',
                transform: 'translateY(-50%)',
                display: 'flex',
                flexDirection: 'column',
                gap: 1,
                zIndex: 1000,
                backgroundColor: 'rgba(0, 0, 0, 0.6)',
                padding: '10px',
                borderRadius: '10px 0 0 10px',
                boxShadow: '0 0 10px rgba(0,0,0,0.5)',
                backdropFilter: 'blur(5px)'
            }}
        >
            <IconButton 
                href="https://facebook.com" 
                target="_blank"
                sx={{ 
                    bgcolor: '#3b5998',
                    color: 'white',
                    '&:hover': { 
                        bgcolor: '#4c70ba',
                        transform: 'translateX(-5px)'
                    },
                    transition: 'all 0.3s ease'
                }}
            >
                <FacebookIcon />
            </IconButton>

            <IconButton 
                href="https://instagram.com" 
                target="_blank"
                sx={{ 
                    bgcolor: '#C13584',
                    color: 'white',
                    '&:hover': { 
                        bgcolor: '#e1306c',
                        transform: 'translateX(-5px)'
                    },
                    transition: 'all 0.3s ease'
                }}
            >
                <InstagramIcon />
            </IconButton>

            <IconButton 
                href="https://youtube.com" 
                target="_blank"
                sx={{ 
                    bgcolor: '#FF0000',
                    color: 'white',
                    '&:hover': { 
                        bgcolor: '#ff3333',
                        transform: 'translateX(-5px)'
                    },
                    transition: 'all 0.3s ease'
                }}
            >
                <YouTubeIcon />
            </IconButton>

            <IconButton 
                href="https://wa.me/tunumero" 
                target="_blank"
                sx={{ 
                    bgcolor: '#25D366',
                    color: 'white',
                    '&:hover': { 
                        bgcolor: '#2ecc71',
                        transform: 'translateX(-5px)'
                    },
                    transition: 'all 0.3s ease'
                }}
            >
                <WhatsAppIcon />
            </IconButton>
        </Box>
    );
}

export default SocialBar; 