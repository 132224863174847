import React from 'react';
import Slider from 'react-slick';
import { Typography } from '@mui/material';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './carousel.css';
import { useNavigate } from 'react-router-dom';
import { formatCOP } from '../utils/formatPrice';

const NextArrow = ({ onClick }) => (
    <button className="carousel-arrow next" onClick={onClick}>
        <KeyboardArrowRightIcon />
    </button>
);

const PrevArrow = ({ onClick }) => (
    <button className="carousel-arrow prev" onClick={onClick}>
        <KeyboardArrowLeftIcon />
    </button>
);

const Carousel = ({ slides }) => {
    const navigate = useNavigate();

    const handleSlideClick = (slide) => {
        if (slide.game?.id) {
            navigate(`/game/${slide.game.id}`);
        }
    };

    const settings = {
        dots: false,
        infinite: true,
        speed: 800,
        slidesToShow: 1,
        slidesToScroll: 1,
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />,
        centerMode: true,
        centerPadding: '350px',
        cssEase: 'cubic-bezier(0.45, 0, 0.35, 1)',
        autoplay: true,
        autoplaySpeed: 3000,
        pauseOnHover: true,
        rtl: false,
        beforeChange: (current, next) => {
            if (current === slides.length - 1 && next === 0) {
                settings.rtl = true;
                setTimeout(() => {
                    settings.rtl = false;
                }, 800);
            }
        }
    };

    return (
        <div className="carousel-wrapper">
            <Slider {...settings}>
                {slides.map((slide, index) => (
                    <div 
                        key={index} 
                        className="slide-item"
                        onClick={() => handleSlideClick(slide)}
                    >
                        <div className="slide-content">
                            <img src={slide.image} alt={slide.title} />
                            <div className="slide-info">
                                <Typography 
                                    variant="h4" 
                                    sx={{ 
                                        fontFamily: 'Montserrat, sans-serif',
                                        fontWeight: 700,
                                        letterSpacing: '1px',
                                        textTransform: 'uppercase',
                                        fontSize: { xs: '1.4rem', sm: '1.6rem', md: '1.8rem' },
                                        mb: 0,
                                        textShadow: '2px 2px 4px rgba(0,0,0,0.5)',
                                        color: '#fff',
                                        lineHeight: 1
                                    }}
                                >
                                    {slide.title}
                                </Typography>
                                {slide.subtitle && (
                                    <Typography 
                                        variant="h6" 
                                        sx={{ 
                                            fontFamily: 'Montserrat, sans-serif',
                                            fontWeight: 500,
                                            letterSpacing: '0.5px',
                                            fontSize: { xs: '1rem', sm: '1.1rem', md: '1.2rem' },
                                            opacity: 0.9,
                                            textShadow: '1px 1px 2px rgba(0,0,0,0.5)',
                                            color: 'rgba(255, 255, 255, 0.7)',
                                            mb: 2,
                                            mt: 0,
                                            lineHeight: 1.2
                                        }}
                                    >
                                        {slide.subtitle}
                                    </Typography>
                                )}
                                <Typography 
                                    variant="h4" 
                                    sx={{ 
                                        fontFamily: 'Montserrat, sans-serif',
                                        fontWeight: 700,
                                        color: '#00FFFF',
                                        textShadow: '0 0 10px rgba(0,255,255,0.5)',
                                        fontSize: { xs: '1.6rem', sm: '1.8rem', md: '2rem' }
                                    }}
                                >
                                    {formatCOP(slide.game?.price || 0)}
                                </Typography>
                            </div>
                        </div>
                    </div>
                ))}
            </Slider>
        </div>
    );
};

export default Carousel; 