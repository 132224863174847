import React, { useState } from 'react';
import { 
    Container, Box, Typography, Paper, TextField, Button,
    Divider, Alert, Snackbar
} from '@mui/material';
import { useAuth } from '../../context/AuthContext';
import axios from 'axios';

function Settings() {
    const { user, setUser } = useAuth();
    const [formData, setFormData] = useState({
        username: user?.username || '',
        email: user?.email || '',
        currentPassword: '',
        newPassword: '',
        confirmPassword: ''
    });
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');
    const [loading, setLoading] = useState(false);

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };

    const handleUpdateProfile = async (e) => {
        e.preventDefault();
        setLoading(true);
        setError('');
        setSuccess('');

        try {
            // Validaciones
            if (formData.newPassword !== formData.confirmPassword) {
                setError('Las contraseñas no coinciden');
                return;
            }

            const token = localStorage.getItem('token');
            const response = await axios.put(
                'http://localhost:5000/api/auth/update-profile',
                {
                    username: formData.username,
                    email: formData.email,
                    currentPassword: formData.currentPassword,
                    newPassword: formData.newPassword || undefined
                },
                {
                    headers: { Authorization: `Bearer ${token}` }
                }
            );

            setUser(response.data.user);
            setSuccess('Perfil actualizado correctamente');
            
            // Limpiar campos de contraseña
            setFormData(prev => ({
                ...prev,
                currentPassword: '',
                newPassword: '',
                confirmPassword: ''
            }));

        } catch (error) {
            setError(error.response?.data?.message || 'Error al actualizar el perfil');
        } finally {
            setLoading(false);
        }
    };

    return (
        <Box sx={{ minHeight: '100vh', pt: { xs: 12, md: 16 }, pb: 8 }}>
            <Container maxWidth="md">
                <Paper
                    sx={{
                        p: 4,
                        background: 'rgba(0, 0, 0, 0.7)',
                        backdropFilter: 'blur(10px)',
                        borderRadius: '16px',
                        border: '1px solid rgba(255, 255, 255, 0.1)'
                    }}
                >
                    <Typography variant="h4" color="#00FFFF" gutterBottom>
                        Configuración del Perfil
                    </Typography>
                    <Divider sx={{ mb: 4, borderColor: 'rgba(255, 255, 255, 0.1)' }} />

                    <form onSubmit={handleUpdateProfile}>
                        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
                            {/* Información básica */}
                            <Typography variant="h6" color="white">
                                Información básica
                            </Typography>
                            <TextField
                                name="username"
                                label="Nombre de usuario"
                                value={formData.username}
                                onChange={handleChange}
                                fullWidth
                                sx={{
                                    '& .MuiOutlinedInput-root': {
                                        color: 'white',
                                        '& fieldset': {
                                            borderColor: 'rgba(255, 255, 255, 0.23)',
                                        },
                                        '&:hover fieldset': {
                                            borderColor: '#00FFFF',
                                        },
                                    },
                                    '& .MuiInputLabel-root': {
                                        color: 'rgba(255, 255, 255, 0.7)',
                                    },
                                }}
                            />
                            <TextField
                                name="email"
                                label="Correo electrónico"
                                type="email"
                                value={formData.email}
                                onChange={handleChange}
                                fullWidth
                                sx={{
                                    '& .MuiOutlinedInput-root': {
                                        color: 'white',
                                        '& fieldset': {
                                            borderColor: 'rgba(255, 255, 255, 0.23)',
                                        },
                                        '&:hover fieldset': {
                                            borderColor: '#00FFFF',
                                        },
                                    },
                                    '& .MuiInputLabel-root': {
                                        color: 'rgba(255, 255, 255, 0.7)',
                                    },
                                }}
                            />

                            <Divider sx={{ my: 2, borderColor: 'rgba(255, 255, 255, 0.1)' }} />

                            {/* Cambio de contraseña */}
                            <Typography variant="h6" color="white">
                                Cambiar contraseña
                            </Typography>
                            <TextField
                                name="currentPassword"
                                label="Contraseña actual"
                                type="password"
                                value={formData.currentPassword}
                                onChange={handleChange}
                                fullWidth
                                sx={{
                                    '& .MuiOutlinedInput-root': {
                                        color: 'white',
                                        '& fieldset': {
                                            borderColor: 'rgba(255, 255, 255, 0.23)',
                                        },
                                        '&:hover fieldset': {
                                            borderColor: '#00FFFF',
                                        },
                                    },
                                    '& .MuiInputLabel-root': {
                                        color: 'rgba(255, 255, 255, 0.7)',
                                    },
                                }}
                            />
                            <TextField
                                name="newPassword"
                                label="Nueva contraseña"
                                type="password"
                                value={formData.newPassword}
                                onChange={handleChange}
                                fullWidth
                                sx={{
                                    '& .MuiOutlinedInput-root': {
                                        color: 'white',
                                        '& fieldset': {
                                            borderColor: 'rgba(255, 255, 255, 0.23)',
                                        },
                                        '&:hover fieldset': {
                                            borderColor: '#00FFFF',
                                        },
                                    },
                                    '& .MuiInputLabel-root': {
                                        color: 'rgba(255, 255, 255, 0.7)',
                                    },
                                }}
                            />
                            <TextField
                                name="confirmPassword"
                                label="Confirmar nueva contraseña"
                                type="password"
                                value={formData.confirmPassword}
                                onChange={handleChange}
                                fullWidth
                                sx={{
                                    '& .MuiOutlinedInput-root': {
                                        color: 'white',
                                        '& fieldset': {
                                            borderColor: 'rgba(255, 255, 255, 0.23)',
                                        },
                                        '&:hover fieldset': {
                                            borderColor: '#00FFFF',
                                        },
                                    },
                                    '& .MuiInputLabel-root': {
                                        color: 'rgba(255, 255, 255, 0.7)',
                                    },
                                }}
                            />

                            <Button
                                type="submit"
                                variant="contained"
                                disabled={loading}
                                sx={{
                                    mt: 2,
                                    py: 1.5,
                                    background: 'linear-gradient(45deg, #6A0DAD, #9D00FF)',
                                    '&:hover': {
                                        background: 'linear-gradient(45deg, #9D00FF, #6A0DAD)',
                                    }
                                }}
                            >
                                {loading ? 'Actualizando...' : 'Guardar Cambios'}
                            </Button>
                        </Box>
                    </form>
                </Paper>

                {/* Notificaciones */}
                <Snackbar 
                    open={!!error} 
                    autoHideDuration={6000} 
                    onClose={() => setError('')}
                >
                    <Alert severity="error" onClose={() => setError('')}>
                        {error}
                    </Alert>
                </Snackbar>

                <Snackbar 
                    open={!!success} 
                    autoHideDuration={6000} 
                    onClose={() => setSuccess('')}
                >
                    <Alert severity="success" onClose={() => setSuccess('')}>
                        {success}
                    </Alert>
                </Snackbar>
            </Container>
        </Box>
    );
}

export default Settings; 