import React, { useState, useEffect } from 'react';
import { Container, Box, Typography, Grid, Pagination, FormControl, Select, MenuItem, TextField, InputAdornment, Paper, InputBase, IconButton } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import GameCard from '../components/GameCard';
import axios from 'axios';
import FilterMenu from '../components/FilterMenu';
import { useSearchParams, useLocation } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { useTheme, useMediaQuery } from '@mui/material';

function Products() {
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const urlSearchQuery = searchParams.get('search');
    const [games, setGames] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [page, setPage] = useState(1);
    const [sortBy, setSortBy] = useState('newest');
    const [searchTerm, setSearchTerm] = useState('');
    const [gamesPerPage, setGamesPerPage] = useState(12);
    const [priceRange, setPriceRange] = useState([0, 1000000]);
    const [selectedCategory, setSelectedCategory] = useState('');
    const [sortOrder, setSortOrder] = useState('');
    const { enqueueSnackbar } = useSnackbar();
    const [searchInputValue, setSearchInputValue] = useState('');
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    useEffect(() => {
        const fetchGames = async () => {
            try {
                setLoading(true);
                let url = `${process.env.REACT_APP_BACKEND_URL}/games`;
                
                if (urlSearchQuery) {
                    url = `${process.env.REACT_APP_BACKEND_URL}/games?search=${encodeURIComponent(urlSearchQuery)}`;
                }

                console.log('Intentando obtener juegos desde:', url);

                const response = await axios.get(url);
                
                if (response.data && response.data.success) {
                    const formattedGames = response.data.data.map(game => ({
                        id: game.id,
                        name: game.name,
                        price: parseFloat(game.price),
                        discount: game.discount || 0,
                        image: game.image,
                        description: game.description,
                        longDescription: game.longDescription,
                        requirements: {
                            minimum: {
                                os: game.min_os || '',
                                processor: game.min_processor || '',
                                memory: game.min_memory || '',
                                graphics: game.min_graphics || '',
                                storage: game.min_storage || '',
                                directx: game.min_directx || ''
                            },
                            recommended: {
                                os: game.rec_os || '',
                                processor: game.rec_processor || '',
                                memory: game.rec_memory || '',
                                graphics: game.rec_graphics || '',
                                storage: game.rec_storage || '',
                                directx: game.rec_directx || ''
                            }
                        },
                        features: {
                            audio: game.audio ? game.audio.split(',') : [],
                            subtitles: game.subtitles ? game.subtitles.split(',') : [],
                            genre: game.genre ? game.genre.split(',') : [],
                            releaseDate: game.releaseDate,
                            platform: game.platform || 'Steam',
                            developer: game.developer || '',
                            publisher: game.publisher || ''
                        }
                    }));

                    let filteredGames = formattedGames;
                    if (urlSearchQuery) {
                        filteredGames = formattedGames.filter(game => 
                            game.name.toLowerCase().includes(urlSearchQuery.toLowerCase())
                        );
                    }

                    setGames(filteredGames);
                    
                    if (filteredGames.length === 0 && urlSearchQuery) {
                        enqueueSnackbar(`No se encontraron juegos para: "${urlSearchQuery}"`, { 
                            variant: 'info'
                        });
                    }
                } else {
                    throw new Error('Formato de respuesta inválido');
                }
            } catch (error) {
                console.error('Error detallado:', {
                    message: error.message,
                    status: error.response?.status,
                    statusText: error.response?.statusText,
                    data: error.response?.data,
                    config: error.config
                });
                setError(error.response?.data?.message || 'Error al cargar los juegos');
                enqueueSnackbar('Error al cargar los juegos', { variant: 'error' });
            } finally {
                setLoading(false);
            }
        };

        fetchGames();
    }, [urlSearchQuery, enqueueSnackbar]);

    const filteredGames = games.filter(game => {
        const matchesSearch = searchTerm === '' || 
            game.name.toLowerCase().includes(searchTerm.toLowerCase());
        const matchesPrice = game.price >= priceRange[0] && game.price <= priceRange[1];
        const matchesCategory = !selectedCategory || game.category === selectedCategory;
        
        console.log('Filtrado:', {
            game: game.name,
            searchTerm,
            matchesSearch,
            matchesPrice,
            matchesCategory
        });

        return matchesSearch && matchesPrice && matchesCategory;
    });

    const sortedGames = [...filteredGames].sort((a, b) => {
        switch (sortOrder) {
            case 'price_asc':
                return a.price - b.price;
            case 'price_desc':
                return b.price - a.price;
            case 'name':
                return a.name.localeCompare(b.name);
            case 'date':
                return new Date(b.releaseDate) - new Date(a.releaseDate);
            default:
                return 0;
        }
    });

    const totalPages = Math.ceil(sortedGames.length / (isMobile ? 3 : 12));
    const indexOfLastGame = page * (isMobile ? 3 : 12);
    const indexOfFirstGame = indexOfLastGame - (isMobile ? 3 : 12);
    const currentGames = sortedGames.slice(indexOfFirstGame, indexOfLastGame);

    // Mostrar estado de carga
    if (loading) {
        return (
            <Box sx={{ minHeight: '100vh', pt: { xs: 12, md: 16 }, pb: 8 }}>
                <Container>
                    <Typography color="white">Cargando juegos...</Typography>
                </Container>
            </Box>
        );
    }

    // Mostrar error si existe
    if (error) {
        return (
            <Box sx={{ minHeight: '100vh', pt: { xs: 12, md: 16 }, pb: 8 }}>
                <Container>
                    <Typography color="error">Error: {error}</Typography>
                </Container>
            </Box>
        );
    }

    // Mostrar mensaje si no hay juegos
    if (!games || games.length === 0) {
        return (
            <Box sx={{ minHeight: '100vh', pt: { xs: 12, md: 16 }, pb: 8 }}>
                <Container>
                    <Typography color="white">No hay juegos disponibles</Typography>
                </Container>
            </Box>
        );
    }

    const handleFilter = (filterParams) => {
        console.log('Filtros aplicados:', filterParams);
        setPriceRange(filterParams.priceRange);
        setSelectedCategory(filterParams.category);
        setSortOrder(filterParams.sortBy);
        setSearchTerm(filterParams.searchTerm);
        setPage(1);
    };

    const handleSearch = (e) => {
        e.preventDefault();
        if (searchInputValue.trim()) {
            setSearchTerm(searchInputValue.trim());
            setPage(1);
        }
    };

    return (
        <Box sx={{ 
            display: 'flex',
            minHeight: '100vh',
            pt: { xs: 6, sm: 8, md: 10 }
        }}>
            {/* Menú de filtros - Oculto en móvil */}
            <Box sx={{ 
                display: { xs: 'none', md: 'block' },
                width: '250px',
                flexShrink: 0,
                background: 'rgba(10, 10, 31, 0.8)',
                borderRadius: '12px',
                p: 3,
                ml: 0,
                height: 'fit-content',
                backdropFilter: 'blur(10px)',
                border: '5px solid rgba(138, 43, 226, 0.8)',
                boxShadow: '0 0 30px rgba(138, 43, 226, 0.4), inset 0 0 30px rgba(138, 43, 226, 0.3)',
                zIndex: 2,
                overflow: 'hidden',
                '& .MuiFormGroup-root': {
                    width: '100%',
                    padding: '0 10px',
                    boxSizing: 'border-box'
                },
                '& .MuiTextField-root': {
                    width: '100%',
                    marginBottom: 2,
                    '& .MuiOutlinedInput-root': {
                        color: 'white',
                        '& fieldset': {
                            borderColor: 'rgba(255, 255, 255, 0.23)',
                        },
                        '&:hover fieldset': {
                            borderColor: 'rgba(255, 255, 255, 0.5)',
                        },
                    },
                },
                '& .MuiSlider-root': {
                    color: '#6A0DAD',
                    '& .MuiSlider-thumb': {
                        backgroundColor: '#9D00FF',
                    },
                    '& .MuiSlider-track': {
                        backgroundColor: '#9D00FF',
                    },
                }
            }}>
                <FilterMenu 
                    onFilter={handleFilter}
                    initialPriceRange={priceRange}
                    initialCategory={selectedCategory}
                    initialSortOrder={sortOrder}
                    initialSearchTerm={searchTerm}
                />
            </Box>

            {/* Contenido principal */}
            <Box sx={{ 
                flex: 1,
                p: { xs: 1, sm: 2 },
                display: 'flex',
                flexDirection: 'column',
                minHeight: '100vh'
            }}>
                {/* Barra de búsqueda - Solo visible en móvil */}
                <Box sx={{ 
                    display: { xs: 'block', md: 'none' },  // Solo visible en móvil
                    mb: 3,
                    width: '100%'
                }}>
                    <Paper
                        component="form"
                        onSubmit={handleSearch}
                        sx={{
                            p: '2px 4px',
                            display: 'flex',
                            alignItems: 'center',
                            background: 'rgba(255, 255, 255, 0.05)',
                            border: '1px solid rgba(157, 0, 255, 0.2)',
                            borderRadius: '8px'
                        }}
                    >
                        <InputBase
                            placeholder="Buscar juegos..."
                            value={searchInputValue}
                            onChange={(e) => setSearchInputValue(e.target.value)}
                            sx={{ 
                                ml: 1,
                                flex: 1,
                                color: 'white',
                                '& input::placeholder': {
                                    color: 'rgba(255, 255, 255, 0.7)',
                                    opacity: 1
                                }
                            }}
                        />
                        <IconButton type="submit" sx={{ color: '#00FFFF' }}>
                            <SearchIcon />
                        </IconButton>
                    </Paper>
                </Box>

                {/* Grid de juegos */}
                <Grid container spacing={0.5} sx={{ 
                    flex: 1,
                    mt: { xs: 0.5, sm: 1 }
                }}>
                    {currentGames.map((game) => (
                        <Grid item 
                            key={game.id} 
                            xs={6}
                            sm={6} 
                            md={4} 
                            lg={3}
                            sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                mb: 0.5,
                                px: 0.25,
                                '& .MuiCard-root': {
                                    width: {
                                        xs: '150px',
                                        sm: '100%',
                                        md: '200px',
                                        lg: '210px'
                                    },
                                    maxWidth: {
                                        xs: '150px',
                                        sm: '100%',
                                        md: '200px',
                                        lg: '210px'
                                    },
                                    m: 0,
                                    height: 'fit-content',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    '& .MuiCardContent-root': {
                                        p: 1,
                                        pb: '4px !important',
                                        flexGrow: 0,
                                        '&:last-child': {
                                            pb: '4px'
                                        }
                                    },
                                    '& .MuiCardActions-root': {
                                        p: 1,
                                        pt: 0,
                                        minHeight: 0,
                                        '& .MuiButton-root': {
                                            minHeight: 0,
                                            padding: '4px 8px'
                                        }
                                    },
                                    '& .MuiCardMedia-root': {
                                        height: {
                                            xs: '180px',
                                            sm: '220px',
                                            md: '230px',
                                            lg: '235px'
                                        }
                                    },
                                    '& .MuiTypography-root': {
                                        mb: 0.25,
                                        lineHeight: 1.2
                                    }
                                }
                            }}
                        >
                            <GameCard 
                                game={game} 
                                showPrice={true}
                            />
                        </Grid>
                    ))}
                </Grid>

                {/* Paginación con estilos mejorados */}
                <Box sx={{ 
                    display: 'flex', 
                    justifyContent: 'center',
                    py: 4,
                    mt: 'auto',
                    borderTop: '1px solid rgba(255,255,255,0.1)'
                }}>
                    <Pagination
                        count={totalPages}
                        page={page}
                        onChange={(e, value) => setPage(value)}
                        color="secondary"
                        size="large"
                        sx={{
                            '& .MuiPaginationItem-root': {
                                color: 'white',
                                fontSize: '1.1rem',
                                '&:hover': {
                                    backgroundColor: 'rgba(157, 0, 255, 0.2)'
                                },
                                '&.Mui-selected': {
                                    backgroundColor: 'rgba(157, 0, 255, 0.5)',
                                    '&:hover': {
                                        backgroundColor: 'rgba(157, 0, 255, 0.7)'
                                    }
                                }
                            }
                        }}
                    />
                </Box>
            </Box>
        </Box>
    );
}

export default Products; 