import React, { useState } from 'react';
import { 
    Box, 
    TextField, 
    Button, 
    Typography, 
    Container,
    Paper,
    InputAdornment,
    IconButton
} from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import { motion } from 'framer-motion';
import EmailIcon from '@mui/icons-material/Email';
import LockIcon from '@mui/icons-material/Lock';
import PersonIcon from '@mui/icons-material/Person';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import axios from 'axios';

function Register() {
    const [formData, setFormData] = useState({
        username: '',
        email: '',
        password: '',
        confirmPassword: ''
    });
    const [showPassword, setShowPassword] = useState(false);
    const [error, setError] = useState('');
    const { register } = useAuth();
    const navigate = useNavigate();

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (formData.password !== formData.confirmPassword) {
            setError('Las contraseñas no coinciden');
            return;
        }
        try {
            const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/auth/register`, {
                username: formData.username,
                email: formData.email,
                password: formData.password
            });
            if (response.data.success) {
                navigate('/');
            } else {
                setError(response.data.message);
            }
        } catch (error) {
            setError('Error al registrar usuario');
        }
    };

    return (
        <Container component="main" maxWidth="xs">
            <Box
                sx={{
                    minHeight: '100vh',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <motion.div
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.5 }}
                    style={{ width: '100%' }}
                >
                    <Paper
                        elevation={24}
                        sx={{
                            p: 4,
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            background: 'rgba(10, 10, 31, 0.8)',
                            backdropFilter: 'blur(10px)',
                            border: '1px solid rgba(138, 43, 226, 0.4)',
                            borderRadius: '20px',
                            boxShadow: '0 0 20px rgba(138, 43, 226, 0.2)',
                            position: 'relative',
                            overflow: 'hidden',
                            '&::before': {
                                content: '""',
                                position: 'absolute',
                                top: 0,
                                left: '-100%',
                                width: '200%',
                                height: '100%',
                                background: 'linear-gradient(90deg, transparent, rgba(138, 43, 226, 0.2), transparent)',
                                animation: 'shine 3s infinite'
                            }
                        }}
                    >
                        <Typography
                            component="h1"
                            variant="h4"
                            sx={{
                                mb: 4,
                                color: '#fff',
                                textShadow: '0 0 10px rgba(138, 43, 226, 0.5)',
                                fontWeight: 'bold',
                                textAlign: 'center'
                            }}
                        >
                            CREAR CUENTA
                        </Typography>

                        <Box component="form" onSubmit={handleSubmit} sx={{ width: '100%' }}>
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                label="Nombre de usuario"
                                name="username"
                                value={formData.username}
                                onChange={handleChange}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <PersonIcon sx={{ color: 'rgba(138, 43, 226, 0.8)' }} />
                                        </InputAdornment>
                                    ),
                                }}
                                sx={{
                                    '& .MuiOutlinedInput-root': {
                                        '& fieldset': {
                                            borderColor: 'rgba(138, 43, 226, 0.3)',
                                        },
                                        '&:hover fieldset': {
                                            borderColor: 'rgba(138, 43, 226, 0.5)',
                                        },
                                        '&.Mui-focused fieldset': {
                                            borderColor: 'rgba(138, 43, 226, 0.8)',
                                        },
                                    },
                                }}
                            />

                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                label="Email"
                                name="email"
                                type="email"
                                value={formData.email}
                                onChange={handleChange}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <EmailIcon sx={{ color: 'rgba(138, 43, 226, 0.8)' }} />
                                        </InputAdornment>
                                    ),
                                }}
                                sx={{
                                    '& .MuiOutlinedInput-root': {
                                        '& fieldset': {
                                            borderColor: 'rgba(138, 43, 226, 0.3)',
                                        },
                                        '&:hover fieldset': {
                                            borderColor: 'rgba(138, 43, 226, 0.5)',
                                        },
                                        '&.Mui-focused fieldset': {
                                            borderColor: 'rgba(138, 43, 226, 0.8)',
                                        },
                                    },
                                }}
                            />

                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                label="Contraseña"
                                name="password"
                                type={showPassword ? 'text' : 'password'}
                                value={formData.password}
                                onChange={handleChange}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <LockIcon sx={{ color: 'rgba(138, 43, 226, 0.8)' }} />
                                        </InputAdornment>
                                    ),
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                onClick={() => setShowPassword(!showPassword)}
                                                edge="end"
                                            >
                                                {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                                sx={{
                                    '& .MuiOutlinedInput-root': {
                                        '& fieldset': {
                                            borderColor: 'rgba(138, 43, 226, 0.3)',
                                        },
                                        '&:hover fieldset': {
                                            borderColor: 'rgba(138, 43, 226, 0.5)',
                                        },
                                        '&.Mui-focused fieldset': {
                                            borderColor: 'rgba(138, 43, 226, 0.8)',
                                        },
                                    },
                                }}
                            />

                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                label="Confirmar Contraseña"
                                name="confirmPassword"
                                type={showPassword ? 'text' : 'password'}
                                value={formData.confirmPassword}
                                onChange={handleChange}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <LockIcon sx={{ color: 'rgba(138, 43, 226, 0.8)' }} />
                                        </InputAdornment>
                                    ),
                                }}
                                sx={{
                                    '& .MuiOutlinedInput-root': {
                                        '& fieldset': {
                                            borderColor: 'rgba(138, 43, 226, 0.3)',
                                        },
                                        '&:hover fieldset': {
                                            borderColor: 'rgba(138, 43, 226, 0.5)',
                                        },
                                        '&.Mui-focused fieldset': {
                                            borderColor: 'rgba(138, 43, 226, 0.8)',
                                        },
                                    },
                                }}
                            />

                            {error && (
                                <Typography color="error" sx={{ mt: 2, textAlign: 'center' }}>
                                    {error}
                                </Typography>
                            )}

                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                sx={{
                                    mt: 3,
                                    mb: 2,
                                    background: 'linear-gradient(45deg, rgba(138, 43, 226, 0.8), rgba(156, 39, 176, 0.8))',
                                    border: '1px solid rgba(138, 43, 226, 0.3)',
                                    boxShadow: '0 0 10px rgba(138, 43, 226, 0.3)',
                                    '&:hover': {
                                        background: 'linear-gradient(45deg, rgba(138, 43, 226, 1), rgba(156, 39, 176, 1))',
                                        boxShadow: '0 0 20px rgba(138, 43, 226, 0.5)',
                                    },
                                }}
                            >
                                Registrarse
                            </Button>

                            <Box sx={{ mt: 2, textAlign: 'center' }}>
                                <Typography
                                    component={Link}
                                    to="/login"
                                    sx={{
                                        color: 'rgba(138, 43, 226, 0.8)',
                                        textDecoration: 'none',
                                        '&:hover': {
                                            textDecoration: 'underline',
                                            textShadow: '0 0 10px rgba(138, 43, 226, 0.5)',
                                        },
                                    }}
                                >
                                    ¿Ya tienes una cuenta? Inicia sesión
                                </Typography>
                            </Box>
                        </Box>
                    </Paper>
                </motion.div>
            </Box>
        </Container>
    );
}

export default Register; 