import React, { useState, useEffect } from 'react';
import { 
    Container, Box, Typography, Button, TextField, 
    MenuItem, Paper, Table, TableBody, TableCell, 
    TableHead, TableRow, IconButton, Tooltip,
    Divider
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { useSnackbar } from 'notistack';
import axios from 'axios';

function AddGuides() {
    const { enqueueSnackbar } = useSnackbar();
    const [guides, setGuides] = useState([]);
    const [formData, setFormData] = useState({
        section: '',
        title: '',
        url: ''
    });
    const [loading, setLoading] = useState(false);
    const [editMode, setEditMode] = useState(false);
    const [editId, setEditId] = useState(null);

    const sections = [
        { value: 'compartida', label: 'Compartida' },
        { value: 'denuvo', label: 'Denuvo' },
        { value: 'ubisoft', label: 'Ubisoft' },
        { value: 'sin_conexion', label: 'Sin Conexión' }
    ];

    useEffect(() => {
        fetchGuides();
    }, []);

    const fetchGuides = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/guides`);
            if (response.data.success) {
                setGuides(response.data.data);
            }
        } catch (error) {
            enqueueSnackbar('Error al cargar las guías', { variant: 'error' });
        }
    };

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        
        let urlToSubmit = formData.url;
        if (!urlToSubmit.startsWith('http://') && !urlToSubmit.startsWith('https://')) {
            urlToSubmit = `https://${urlToSubmit}`;
        }

        try {
            const token = localStorage.getItem('adminToken');
            const endpoint = editMode 
                ? `http://localhost:5000/api/guides/${editId}`
                : 'http://localhost:5000/api/guides';
            const method = editMode ? 'put' : 'post';

            const response = await axios[method](
                endpoint,
                { ...formData, url: urlToSubmit },
                {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json'
                    }
                }
            );

            if (response.data.success) {
                enqueueSnackbar(
                    editMode ? 'Guía actualizada exitosamente' : 'Guía agregada exitosamente', 
                    { variant: 'success' }
                );
                setFormData({
                    section: '',
                    title: '',
                    url: ''
                });
                setEditMode(false);
                setEditId(null);
                fetchGuides();
            }
        } catch (error) {
            enqueueSnackbar(
                error.response?.data?.message || 'Error al procesar la guía', 
                { variant: 'error' }
            );
        } finally {
            setLoading(false);
        }
    };

    const handleEdit = (guide) => {
        setFormData({
            section: guide.section,
            title: guide.title,
            url: guide.url
        });
        setEditMode(true);
        setEditId(guide.id);
    };

    const handleDelete = async (id) => {
        try {
            const token = localStorage.getItem('adminToken');
            const response = await axios.delete(
                `http://localhost:5000/api/guides/${id}`,
                {
                    headers: { Authorization: `Bearer ${token}` }
                }
            );

            if (response.data.success) {
                enqueueSnackbar('Guía eliminada exitosamente', { variant: 'success' });
                fetchGuides();
            }
        } catch (error) {
            enqueueSnackbar('Error al eliminar la guía', { variant: 'error' });
        }
    };

    const handleCancel = () => {
        setFormData({
            section: '',
            title: '',
            url: ''
        });
        setEditMode(false);
        setEditId(null);
    };

    return (
        <Box sx={{ minHeight: '100vh', pt: { xs: 2, sm: 4 }, pb: 4 }}>
            <Container maxWidth="lg">
                <Paper sx={{ p: 4, bgcolor: 'background.paper', boxShadow: 3 }}>
                    <Typography 
                        variant="h4" 
                        component="h1" 
                        gutterBottom
                        sx={{ color: 'primary.main' }}
                    >
                        {editMode ? 'Editar Guía' : 'Agregar Nueva Guía'}
                    </Typography>

                    <form onSubmit={handleSubmit}>
                        <TextField
                            select
                            fullWidth
                            name="section"
                            label="Sección"
                            value={formData.section}
                            onChange={handleChange}
                            margin="normal"
                            required
                            sx={{ mb: 2 }}
                        >
                            {sections.map((option) => (
                                <MenuItem key={option.value} value={option.value}>
                                    {option.label}
                                </MenuItem>
                            ))}
                        </TextField>

                        <TextField
                            fullWidth
                            name="title"
                            label="Título"
                            value={formData.title}
                            onChange={handleChange}
                            margin="normal"
                            required
                            sx={{ mb: 2 }}
                        />

                        <TextField
                            fullWidth
                            name="url"
                            label="URL"
                            value={formData.url}
                            onChange={handleChange}
                            margin="normal"
                            required
                            helperText="La URL se convertirá automáticamente a https:// si no se especifica"
                            sx={{ mb: 3 }}
                        />

                        <Box sx={{ display: 'flex', gap: 2 }}>
                            <Button
                                type="submit"
                                variant="contained"
                                color="primary"
                                size="large"
                                fullWidth
                                disabled={loading}
                                sx={{ py: 1.5, fontSize: '1.1rem' }}
                            >
                                {loading ? 'Procesando...' : (editMode ? 'Actualizar Guía' : 'Agregar Guía')}
                            </Button>

                            {editMode && (
                                <Button
                                    variant="outlined"
                                    color="secondary"
                                    size="large"
                                    fullWidth
                                    onClick={handleCancel}
                                    sx={{ py: 1.5, fontSize: '1.1rem' }}
                                >
                                    Cancelar
                                </Button>
                            )}
                        </Box>
                    </form>

                    <Divider sx={{ my: 4 }} />

                    <Typography variant="h5" gutterBottom>
                        Guías Existentes
                    </Typography>

                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Sección</TableCell>
                                <TableCell>Título</TableCell>
                                <TableCell>URL</TableCell>
                                <TableCell align="right">Acciones</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {guides.map((guide) => (
                                <TableRow key={guide.id}>
                                    <TableCell>{guide.section}</TableCell>
                                    <TableCell>{guide.title}</TableCell>
                                    <TableCell>
                                        <a 
                                            href={guide.url} 
                                            target="_blank" 
                                            rel="noopener noreferrer"
                                            style={{ color: 'inherit', textDecoration: 'none' }}
                                        >
                                            {guide.url}
                                        </a>
                                    </TableCell>
                                    <TableCell align="right">
                                        <Tooltip title="Editar">
                                            <IconButton onClick={() => handleEdit(guide)}>
                                                <EditIcon />
                                            </IconButton>
                                        </Tooltip>
                                        <Tooltip title="Eliminar">
                                            <IconButton onClick={() => handleDelete(guide.id)}>
                                                <DeleteIcon />
                                            </IconButton>
                                        </Tooltip>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </Paper>
            </Container>
        </Box>
    );
}

export default AddGuides; 