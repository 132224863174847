import React, { useState, useEffect } from 'react';
import {
    Container, Box, Typography, Paper, Table, TableBody,
    TableCell, TableContainer, TableHead, TableRow,
    IconButton, Button, Dialog, DialogTitle, DialogContent,
    DialogActions
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import adminApi from '../../utils/adminApi';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import axios from 'axios';

function EditGames() {
    const [games, setGames] = useState([]);
    const [deleteDialog, setDeleteDialog] = useState({ open: false, gameId: null });
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const checkAuth = async () => {
            const token = localStorage.getItem('adminToken'); 
            if (!token) {
                console.error('No hay token de administrador');
                navigate('/admin/login');
                return;
            }
            await fetchGames();
        };
        
        checkAuth();
    }, [navigate]);

    const fetchGames = async () => {
        try {
            setLoading(true);
            const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/games`);
            if (response.data.success) {
                setGames(response.data.data);
            }
        } catch (error) {
            console.error('Error al cargar juegos:', error);
            enqueueSnackbar('Error al cargar los juegos', { variant: 'error' });
        } finally {
            setLoading(false);
        }
    };

    const handleEdit = (gameId) => {
        try {
            if (!gameId) {
                console.error('ID de juego no válido');
                return;
            }
            // Navegar directamente a la página de edición
            navigate(`/admin/edit-game/${gameId}`);
        } catch (error) {
            console.error('Error al navegar:', error);
        }
    };

    const handleDeleteClick = (gameId) => {
        setDeleteDialog({ open: true, gameId });
    };

    const handleDeleteCancel = () => {
        setDeleteDialog({ open: false, gameId: null });
    };

    const handleDelete = async () => {
        try {
            await adminApi.delete(`/games/${deleteDialog.gameId}`);
            setDeleteDialog({ open: false, gameId: null });
            enqueueSnackbar('Juego eliminado exitosamente', { variant: 'success' });
            fetchGames();
        } catch (error) {
            console.error('Error al eliminar juego:', error);
            enqueueSnackbar('Error al eliminar el juego', { variant: 'error' });
            if (error.response?.status === 401) {
                localStorage.removeItem('adminToken'); 
                navigate('/admin/login');
            }
        }
    };

    return (
        <Box sx={{ minHeight: '100vh', pt: { xs: 12, md: 16 }, pb: 8 }}>
            <Container maxWidth="lg">
                <Paper sx={{ p: 4, background: 'rgba(0, 0, 0, 0.5)', backdropFilter: 'blur(10px)' }}>
                    <Typography variant="h4" sx={{ mb: 4, color: 'white' }}>
                        Editar Juegos
                    </Typography>

                    <TableContainer>
                        <Table sx={{ minWidth: 650 }}>
                            <TableHead>
                                <TableRow>
                                    <TableCell sx={{ color: 'white' }}>Imagen</TableCell>
                                    <TableCell sx={{ color: 'white' }}>Nombre</TableCell>
                                    <TableCell sx={{ color: 'white' }}>Precio</TableCell>
                                    <TableCell sx={{ color: 'white' }}>Descuento</TableCell>
                                    <TableCell sx={{ color: 'white' }}>Acciones</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {games && games.length > 0 ? (
                                    games.map((game) => (
                                        <TableRow key={game._id || game.id}>
                                            <TableCell>
                                                <img 
                                                    src={game.image} 
                                                    alt={game.name}
                                                    style={{ 
                                                        width: '50px', 
                                                        height: '50px', 
                                                        objectFit: 'cover' 
                                                    }} 
                                                />
                                            </TableCell>
                                            <TableCell sx={{ color: 'white' }}>{game.name}</TableCell>
                                            <TableCell sx={{ color: 'white' }}>${game.price}</TableCell>
                                            <TableCell sx={{ color: 'white' }}>{game.discount}%</TableCell>
                                            <TableCell>
                                                <IconButton 
                                                    onClick={() => handleEdit(game._id || game.id)}
                                                    sx={{ color: 'var(--accent-color)' }}
                                                >
                                                    <EditIcon />
                                                </IconButton>
                                                <IconButton 
                                                    onClick={() => handleDeleteClick(game._id || game.id)}
                                                    sx={{ color: '#ff4444' }}
                                                >
                                                    <DeleteIcon />
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>
                                    ))
                                ) : (
                                    <TableRow>
                                        <TableCell colSpan={5} sx={{ textAlign: 'center', color: 'white' }}>
                                            No hay juegos disponibles
                                        </TableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>

                    {/* Diálogo de confirmación para eliminar */}
                    <Dialog
                        open={deleteDialog.open}
                        onClose={handleDeleteCancel}
                    >
                        <DialogTitle>
                            ¿Estás seguro de que quieres eliminar este juego?
                        </DialogTitle>
                        <DialogContent>
                            <Typography>
                                Esta acción no se puede deshacer.
                            </Typography>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleDeleteCancel}>
                                Cancelar
                            </Button>
                            <Button 
                                onClick={handleDelete} 
                                color="error" 
                                variant="contained"
                            >
                                Eliminar
                            </Button>
                        </DialogActions>
                    </Dialog>
                </Paper>
            </Container>
        </Box>
    );
}

export default EditGames; 