import React, { useState, useEffect } from 'react';
import {
    Box,
    Container,
    Typography,
    Paper,
    Select,
    MenuItem,
    TextField,
    Button,
    Grid,
    IconButton,
    FormControl,
    InputLabel,
    Alert,
    Snackbar
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { gameAccountService } from '../../services/gameAccountService';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import AddIcon from '@mui/icons-material/Add';

function AddGameAccounts() {
    const [games, setGames] = useState([]);
    const [selectedGame, setSelectedGame] = useState('');
    const [accounts, setAccounts] = useState([{ email: '', password: '' }]);
    const [additionalAccounts, setAdditionalAccounts] = useState([{ email: '', password: '' }]);
    const [loading, setLoading] = useState(false);
    const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });
    const navigate = useNavigate();

    useEffect(() => {
        fetchGames();
    }, []);

    const fetchGames = async () => {
        try {
            const token = localStorage.getItem('adminToken');
            const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/games`, {
                headers: { Authorization: `Bearer ${token}` }
            });
            if (response.data.success) {
                setGames(response.data.data);
            }
        } catch (error) {
            if (error.response?.status === 401) {
                localStorage.removeItem('adminToken');
                navigate('/admin/login');
            }
            setSnackbar({
                open: true,
                message: 'Error al cargar los juegos',
                severity: 'error'
            });
        }
    };

    const handleGameSelect = (event) => {
        const gameId = event.target.value;
        const game = games.find(g => g.id === gameId);
        setSelectedGame(gameId);
        
        // Resetear los formularios cuando se cambia de juego
        setAccounts([{ email: '', password: '' }]);
        setAdditionalAccounts([{ email: '', password: '' }]);
    };

    const handleAccountChange = (index, field, value, isAdditional = false) => {
        const updateAccounts = (prevAccounts) => 
            prevAccounts.map((account, i) => 
                i === index ? { ...account, [field]: value } : account
            );

        if (isAdditional) {
            setAdditionalAccounts(updateAccounts);
        } else {
            setAccounts(updateAccounts);
        }
    };

    const handleAddAccount = (isAdditional = false) => {
        if (isAdditional) {
            setAdditionalAccounts([...additionalAccounts, { email: '', password: '' }]);
        } else {
            setAccounts([...accounts, { email: '', password: '' }]);
        }
    };

    const handleRemoveAccount = (index, isAdditional = false) => {
        if (isAdditional) {
            setAdditionalAccounts(prev => prev.filter((_, i) => i !== index));
        } else {
            setAccounts(prev => prev.filter((_, i) => i !== index));
        }
    };

    const handleSubmit = async () => {
        if (!selectedGame) {
            setSnackbar({
                open: true,
                message: 'Por favor selecciona un juego',
                severity: 'warning'
            });
            return;
        }

        setLoading(true);
        try {
            const game = games.find(g => g.id === selectedGame);
            const needsAdditionalAccount = game?.additional_account_type !== 'no';

            // Validar cuentas adicionales si son necesarias
            if (needsAdditionalAccount && additionalAccounts.some(acc => !acc.email || !acc.password)) {
                setSnackbar({
                    open: true,
                    message: `Por favor completa todos los campos de las cuentas ${game.additional_account_type}`,
                    severity: 'warning'
                });
                return;
            }

            const response = await gameAccountService.addBulkAccounts(
                selectedGame,
                accounts,
                needsAdditionalAccount ? additionalAccounts : null
            );

            if (response.success) {
                setSnackbar({
                    open: true,
                    message: 'Cuentas agregadas exitosamente',
                    severity: 'success'
                });
                // Resetear formularios
                setSelectedGame('');
                setAccounts([{ email: '', password: '' }]);
                setAdditionalAccounts([{ email: '', password: '' }]);
            }
        } catch (error) {
            console.error('Error al guardar cuentas:', error);
            setSnackbar({
                open: true,
                message: error.response?.data?.message || 'Error al guardar las cuentas',
                severity: 'error'
            });
        } finally {
            setLoading(false);
        }
    };

    const selectedGameData = games.find(g => g.id === selectedGame);
    const needsAdditionalAccount = selectedGameData?.additional_account_type !== 'no';

    return (
        <Box sx={{ minHeight: '100vh', bgcolor: 'background.default', pt: { xs: 10, sm: 12 }, pb: 4, mt: 8 }}>
            <Container maxWidth="lg">
                <Typography variant="h4" gutterBottom sx={{ color: 'white', mb: 4 }}>
                    Agregar Cuentas de Juegos
                </Typography>

                <Paper sx={{ p: 4, bgcolor: 'rgba(0, 0, 0, 0.5)', backdropFilter: 'blur(10px)' }}>
                    {/* Selector de juego */}
                    <FormControl fullWidth sx={{ mb: 4 }}>
                        <InputLabel>Seleccionar Juego</InputLabel>
                        <Select
                            value={selectedGame}
                            onChange={handleGameSelect}
                            label="Seleccionar Juego"
                        >
                            {games.map((game) => (
                                <MenuItem key={game.id} value={game.id}>
                                    {game.name}
                                    {game.additional_account_type !== 'no' && 
                                        ` (Requiere cuenta ${game.additional_account_type})`}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>

                    {selectedGame && (
                        <>
                            {/* Cuentas Steam */}
                            <Box sx={{ mb: 4 }}>
                                <Typography variant="h6" gutterBottom sx={{ color: 'primary.main' }}>
                                    Cuentas Steam
                                </Typography>
                                {accounts.map((account, index) => (
                                    <Grid container spacing={2} key={index} sx={{ mb: 2 }}>
                                        <Grid item xs={12} sm={5}>
                                            <TextField
                                                fullWidth
                                                label="Email de Steam"
                                                value={account.email}
                                                onChange={(e) => handleAccountChange(index, 'email', e.target.value)}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={5}>
                                            <TextField
                                                fullWidth
                                                label="Contraseña de Steam"
                                                type="password"
                                                value={account.password}
                                                onChange={(e) => handleAccountChange(index, 'password', e.target.value)}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={2}>
                                            <IconButton 
                                                onClick={() => handleRemoveAccount(index)}
                                                disabled={accounts.length === 1}
                                                color="error"
                                            >
                                                <DeleteIcon />
                                            </IconButton>
                                        </Grid>
                                    </Grid>
                                ))}
                                <Button 
                                    variant="outlined" 
                                    onClick={() => handleAddAccount()}
                                    startIcon={<AddIcon />}
                                >
                                    Agregar Cuenta Steam
                                </Button>
                            </Box>

                            {/* Cuentas adicionales si son necesarias */}
                            {needsAdditionalAccount && (
                                <Box sx={{ mb: 4 }}>
                                    <Typography variant="h6" gutterBottom sx={{ color: 'primary.main' }}>
                                        Cuentas {selectedGameData.additional_account_type.toUpperCase()}
                                    </Typography>
                                    {additionalAccounts.map((account, index) => (
                                        <Grid container spacing={2} key={index} sx={{ mb: 2 }}>
                                            <Grid item xs={12} sm={5}>
                                                <TextField
                                                    fullWidth
                                                    label={`Email de ${selectedGameData.additional_account_type}`}
                                                    value={account.email}
                                                    onChange={(e) => handleAccountChange(index, 'email', e.target.value, true)}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={5}>
                                                <TextField
                                                    fullWidth
                                                    label={`Contraseña de ${selectedGameData.additional_account_type}`}
                                                    type="password"
                                                    value={account.password}
                                                    onChange={(e) => handleAccountChange(index, 'password', e.target.value, true)}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={2}>
                                                <IconButton 
                                                    onClick={() => handleRemoveAccount(index, true)}
                                                    disabled={additionalAccounts.length === 1}
                                                    color="error"
                                                >
                                                    <DeleteIcon />
                                                </IconButton>
                                            </Grid>
                                        </Grid>
                                    ))}
                                    <Button 
                                        variant="outlined" 
                                        onClick={() => handleAddAccount(true)}
                                        startIcon={<AddIcon />}
                                    >
                                        Agregar Cuenta {selectedGameData.additional_account_type}
                                    </Button>
                                </Box>
                            )}

                            {/* Botón de guardar */}
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={handleSubmit}
                                disabled={loading}
                                fullWidth
                                sx={{ mt: 2 }}
                            >
                                {loading ? 'Guardando...' : 'Guardar Todas las Cuentas'}
                            </Button>
                        </>
                    )}
                </Paper>

                <Snackbar 
                    open={snackbar.open} 
                    autoHideDuration={6000} 
                    onClose={() => setSnackbar({ ...snackbar, open: false })}
                >
                    <Alert 
                        onClose={() => setSnackbar({ ...snackbar, open: false })} 
                        severity={snackbar.severity}
                        sx={{ width: '100%' }}
                    >
                        {snackbar.message}
                    </Alert>
                </Snackbar>
            </Container>
        </Box>
    );
}

export default AddGameAccounts; 