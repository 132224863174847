import React, { useState, useEffect } from 'react';
import { Box } from '@mui/material';
import SalesSection from '../components/SalesSection';
import axios from 'axios';

function Sales() {
    const [games, setGames] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchGames = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/games`);
                if (response.data.success) {
                    setGames(response.data.data);
                }
            } catch (error) {
                console.error('Error al cargar juegos:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchGames();
    }, []);

    if (loading) return null;

    return (
        <Box sx={{ minHeight: '100vh', pt: { xs: 12, md: 16 }, pb: 8 }}>
            <SalesSection games={games} />
        </Box>
    );
}

export default Sales; 