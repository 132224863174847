import React, { useState, useEffect } from 'react';
import {
    Container,
    Box,
    Typography,
    Button,
    Grid,
    CircularProgress,
    Card,
    CardMedia,
    CardContent,
    IconButton
} from '@mui/material';
import { Add as AddIcon, Edit as EditIcon, Delete as DeleteIcon } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useSnackbar } from 'notistack';

function EditCarousel() {
    const [carousels, setCarousels] = useState([]);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();
    const BACKEND_URL = process.env.REACT_APP_BACKEND_URL || 'http://localhost:5000';

    useEffect(() => {
        fetchCarousels();
    }, []);

    const fetchCarousels = async () => {
        try {
            const response = await axios.get(`${BACKEND_URL}/carousel`);
            
            if (response.data.success) {
                setCarousels(response.data.data);
                console.log('Carruseles cargados:', response.data.data);
            }
        } catch (error) {
            console.error('Error al cargar carruseles:', error);
            enqueueSnackbar('Error al cargar los carruseles', { 
                variant: 'error',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'center'
                }
            });
        } finally {
            setLoading(false);
        }
    };

    const handleDelete = async (id) => {
        if (window.confirm('¿Estás seguro de que quieres eliminar este carrusel?')) {
            try {
                const token = localStorage.getItem('adminToken');
                await axios.delete(`${BACKEND_URL}/api/carousel/${id}`, {
                    headers: { Authorization: `Bearer ${token}` }
                });
                
                enqueueSnackbar('Carrusel eliminado correctamente', { 
                    variant: 'success',
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'center'
                    }
                });
                
                // Actualizar la lista de carruseles
                fetchCarousels();
            } catch (error) {
                console.error('Error al eliminar carrusel:', error);
                enqueueSnackbar('Error al eliminar el carrusel', { 
                    variant: 'error',
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'center'
                    }
                });
            }
        }
    };

    if (loading) {
        return (
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '60vh' }}>
                <CircularProgress />
            </Box>
        );
    }

    return (
        <Box sx={{ 
            pt: { xs: 12, md: 16 }, 
            px: 2,
            minHeight: '100vh',
            bgcolor: 'background.default'
        }}>
            <Container maxWidth="xl">
                <Box sx={{ 
                    display: 'flex', 
                    justifyContent: 'space-between', 
                    alignItems: 'center',
                    mb: 4,
                    position: 'relative',
                    zIndex: 2,
                    mt: { xs: 2, md: 4 }
                }}>
                    <Typography variant="h4" sx={{ 
                        color: 'white',
                        fontWeight: 'bold'
                    }}>
                        Gestionar Carruseles
                    </Typography>
                    <Button
                        variant="contained"
                        onClick={() => navigate('/admin/add-carousel')}
                        startIcon={<AddIcon />}
                        sx={{
                            bgcolor: 'var(--accent-color)',
                            color: 'white',
                            padding: '10px 20px',
                            fontSize: '1rem',
                            fontWeight: 'bold',
                            '&:hover': {
                                bgcolor: 'var(--accent-dark)',
                                transform: 'translateY(-2px)',
                                boxShadow: '0 5px 15px rgba(0,0,0,0.3)'
                            },
                            transition: 'all 0.3s ease'
                        }}
                    >
                        Agregar Carrusel
                    </Button>
                </Box>

                {(!carousels || carousels.length === 0) ? (
                    <Box sx={{ 
                        display: 'flex', 
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        minHeight: '200px',
                        bgcolor: 'rgba(0,0,0,0.5)',
                        borderRadius: 2,
                        p: 4
                    }}>
                        <Typography variant="h6" sx={{ mb: 2, color: 'white' }}>
                            No hay carruseles disponibles
                        </Typography>
                    </Box>
                ) : (
                    <Grid container spacing={3}>
                        {carousels.map((carousel) => (
                            <Grid item xs={12} sm={6} md={4} key={carousel.id}>
                                <Card sx={{ 
                                    height: '100%',
                                    background: 'rgba(0, 0, 0, 0.3)',
                                    border: '1px solid rgba(255, 255, 255, 0.1)',
                                    borderRadius: '8px',
                                    overflow: 'hidden'
                                }}>
                                    <CardMedia
                                        component="img"
                                        height="200"
                                        image={
                                            carousel.carouselGame?.banner_image 
                                                ? `${process.env.REACT_APP_BACKEND_URL}${carousel.carouselGame.banner_image}`
                                                : carousel.image 
                                                    ? (carousel.image.startsWith('http') 
                                                        ? carousel.image 
                                                        : `${process.env.REACT_APP_BACKEND_URL}${carousel.image}`)
                                                    : '/images/default-banner.jpg'
                                        }
                                        alt={carousel.title}
                                        sx={{ 
                                            objectFit: 'cover',
                                            width: '100%'
                                        }}
                                        onError={(e) => {
                                            e.target.src = '/images/default-banner.jpg';
                                        }}
                                    />
                                    <CardContent>
                                        <Typography variant="h6" sx={{ color: 'white', mb: 1 }}>
                                            {carousel.title}
                                        </Typography>
                                        <Typography variant="body2" sx={{ color: 'rgba(255, 255, 255, 0.7)' }}>
                                            {carousel.subtitle}
                                        </Typography>
                                        <Box sx={{ mt: 2, display: 'flex', gap: 1 }}>
                                            <IconButton 
                                                onClick={() => navigate(`/admin/carousel/edit/${carousel.id}`)}
                                                sx={{ color: 'primary.main' }}
                                            >
                                                <EditIcon />
                                            </IconButton>
                                            <IconButton 
                                                onClick={() => handleDelete(carousel.id)}
                                                sx={{ color: 'error.main' }}
                                            >
                                                <DeleteIcon />
                                            </IconButton>
                                        </Box>
                                    </CardContent>
                                </Card>
                            </Grid>
                        ))}
                    </Grid>
                )}
            </Container>
        </Box>
    );
}

export default EditCarousel; 