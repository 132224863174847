import React, { useState, useEffect } from 'react';
import {
    Box,
    Container,
    Typography,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    IconButton,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Chip,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    TextField,
    Alert,
    Snackbar,
    CircularProgress
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { gameAccountService } from '../../services/gameAccountService';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

function ManageGameAccounts() {
    const [games, setGames] = useState([]);
    const [selectedGame, setSelectedGame] = useState('');
    const [accounts, setAccounts] = useState([]);
    const [loading, setLoading] = useState(false);
    const [editDialog, setEditDialog] = useState(false);
    const [selectedAccount, setSelectedAccount] = useState(null);
    const [showPassword, setShowPassword] = useState({});
    const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });
    const navigate = useNavigate();

    useEffect(() => {
        fetchGames();
    }, []);

    useEffect(() => {
        if (selectedGame) {
            fetchAccounts();
        }
    }, [selectedGame]);

    const fetchGames = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/games`, {
                headers: { 
                    Authorization: `Bearer ${localStorage.getItem('adminToken')}` 
                }
            });
            if (response.data.success) {
                setGames(response.data.data);
            }
        } catch (error) {
            console.error('Error al cargar juegos:', error);
            showSnackbar('Error al cargar los juegos', 'error');
        }
    };

    const fetchAccounts = async () => {
        try {
            setLoading(true);
            const response = await axios.get(
                `${process.env.REACT_APP_BACKEND_URL}/game-accounts/${selectedGame}`,
                {
                    headers: { 
                        Authorization: `Bearer ${localStorage.getItem('adminToken')}` 
                    }
                }
            );
            if (response.data.success) {
                setAccounts(response.data.data);
            }
        } catch (error) {
            console.error('Error al cargar cuentas:', error);
            showSnackbar('Error al cargar las cuentas', 'error');
        } finally {
            setLoading(false);
        }
    };

    const handleEditAccount = (account) => {
        setSelectedAccount(account);
        setEditDialog(true);
    };

    const handleSaveEdit = async () => {
        try {
            const response = await gameAccountService.updateAccount(
                selectedAccount.id,
                selectedAccount
            );
            if (response.success) {
                setEditDialog(false);
                fetchAccounts();
                showSnackbar('Cuenta actualizada exitosamente', 'success');
            }
        } catch (error) {
            console.error('Error al actualizar cuenta:', error);
            showSnackbar('Error al actualizar la cuenta', 'error');
        }
    };

    const handleDeleteAccount = async (accountId) => {
        if (window.confirm('¿Estás seguro de que quieres eliminar esta cuenta?')) {
            try {
                const response = await gameAccountService.deleteAccount(accountId);
                if (response.success) {
                    fetchAccounts();
                    showSnackbar('Cuenta eliminada exitosamente', 'success');
                }
            } catch (error) {
                console.error('Error al eliminar cuenta:', error);
                showSnackbar('Error al eliminar la cuenta', 'error');
            }
        }
    };

    const togglePasswordVisibility = (accountId) => {
        setShowPassword(prev => ({
            ...prev,
            [accountId]: !prev[accountId]
        }));
    };

    const showSnackbar = (message, severity = 'success') => {
        setSnackbar({ open: true, message, severity });
    };

    const handleCloseSnackbar = () => {
        setSnackbar({ ...snackbar, open: false });
    };

    const getStatusColor = (status) => {
        switch (status) {
            case 'disponible':
                return 'success';
            case 'asignada':
                return 'primary';
            case 'inactiva':
                return 'error';
            default:
                return 'default';
        }
    };

    return (
        <Box sx={{ minHeight: '100vh', bgcolor: 'background.default', pt: { xs: 10, sm: 12 }, pb: 4, mt: 8 }}>
            <Container maxWidth="lg">
                <Typography variant="h4" gutterBottom sx={{ color: 'white', mb: 4 }}>
                    Gestionar Cuentas de Juegos
                </Typography>

                <Paper sx={{ p: 3, bgcolor: 'rgba(0, 0, 0, 0.5)', backdropFilter: 'blur(10px)' }}>
                    <FormControl fullWidth sx={{ mb: 3 }}>
                        <InputLabel>Seleccionar Juego</InputLabel>
                        <Select
                            value={selectedGame}
                            onChange={(e) => setSelectedGame(e.target.value)}
                            label="Seleccionar Juego"
                        >
                            {games.map((game) => (
                                <MenuItem key={game.id} value={game.id}>
                                    {game.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>

                    {loading ? (
                        <Box sx={{ display: 'flex', justifyContent: 'center', p: 3 }}>
                            <CircularProgress />
                        </Box>
                    ) : selectedGame && accounts.length === 0 ? (
                        <Typography sx={{ p: 2, color: 'text.secondary', textAlign: 'center' }}>
                            No hay cuentas registradas para este juego
                        </Typography>
                    ) : selectedGame && (
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Email</TableCell>
                                    <TableCell>Contraseña</TableCell>
                                    <TableCell>Estado</TableCell>
                                    <TableCell>Asignada a</TableCell>
                                    <TableCell>Acciones</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {accounts.map((account) => (
                                    <TableRow key={account.id}>
                                        <TableCell>{account.account_email}</TableCell>
                                        <TableCell>
                                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                {showPassword[account.id] ? 
                                                    account.account_password : 
                                                    '••••••••'}
                                                <IconButton 
                                                    size="small"
                                                    onClick={() => togglePasswordVisibility(account.id)}
                                                >
                                                    {showPassword[account.id] ? 
                                                        <VisibilityOffIcon /> : 
                                                        <VisibilityIcon />}
                                                </IconButton>
                                            </Box>
                                        </TableCell>
                                        <TableCell>
                                            <Chip 
                                                label={account.status}
                                                color={getStatusColor(account.status)}
                                                size="small"
                                            />
                                        </TableCell>
                                        <TableCell>
                                            {account.assigned_to_order ? 
                                                `Orden #${account.assigned_to_order}` : 
                                                '-'}
                                        </TableCell>
                                        <TableCell>
                                            <IconButton 
                                                onClick={() => handleEditAccount(account)}
                                                disabled={account.status === 'asignada'}
                                                color="primary"
                                                size="small"
                                            >
                                                <EditIcon />
                                            </IconButton>
                                            <IconButton 
                                                onClick={() => handleDeleteAccount(account.id)}
                                                disabled={account.status === 'asignada'}
                                                color="error"
                                                size="small"
                                            >
                                                <DeleteIcon />
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    )}
                </Paper>

                {/* Diálogo de edición */}
                <Dialog 
                    open={editDialog} 
                    onClose={() => setEditDialog(false)}
                    maxWidth="sm"
                    fullWidth
                >
                    <DialogTitle>Editar Cuenta</DialogTitle>
                    <DialogContent>
                        <TextField
                            fullWidth
                            label="Email"
                            value={selectedAccount?.account_email || ''}
                            onChange={(e) => setSelectedAccount({
                                ...selectedAccount,
                                account_email: e.target.value
                            })}
                            sx={{ mt: 2, mb: 2 }}
                        />
                        <TextField
                            fullWidth
                            label="Contraseña"
                            value={selectedAccount?.account_password || ''}
                            onChange={(e) => setSelectedAccount({
                                ...selectedAccount,
                                account_password: e.target.value
                            })}
                            sx={{ mb: 2 }}
                        />
                        <FormControl fullWidth>
                            <InputLabel>Estado</InputLabel>
                            <Select
                                value={selectedAccount?.status || 'disponible'}
                                onChange={(e) => setSelectedAccount({
                                    ...selectedAccount,
                                    status: e.target.value
                                })}
                                label="Estado"
                            >
                                <MenuItem value="disponible">Disponible</MenuItem>
                                <MenuItem value="inactiva">Inactiva</MenuItem>
                            </Select>
                        </FormControl>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => setEditDialog(false)}>Cancelar</Button>
                        <Button onClick={handleSaveEdit} variant="contained">
                            Guardar
                        </Button>
                    </DialogActions>
                </Dialog>

                {/* Snackbar para notificaciones */}
                <Snackbar 
                    open={snackbar.open} 
                    autoHideDuration={6000} 
                    onClose={handleCloseSnackbar}
                >
                    <Alert 
                        onClose={handleCloseSnackbar} 
                        severity={snackbar.severity}
                        sx={{ width: '100%' }}
                    >
                        {snackbar.message}
                    </Alert>
                </Snackbar>
            </Container>
        </Box>
    );
}

export default ManageGameAccounts; 