import React from 'react';
import { Card, CardMedia, CardContent, Typography, Box, Button, Stack } from '@mui/material';
import { Link } from 'react-router-dom';
import { formatCOP } from '../utils/formatPrice';
import { useCart } from '../context/CartContext';
import { useSnackbar } from 'notistack';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';

const defaultImage = 'https://blackvortexgames.com/api/images/default-game.jpg';

function GameCard({ game, showPrice = false, disableHover = false }) {
    const { cartItems = [], addToCart, removeFromCart, isInCart } = useCart() || {};
    const { enqueueSnackbar } = useSnackbar();

    const handleCartAction = () => {
        if (isInCart?.(game.id)) {
            removeFromCart?.(game.id);
            enqueueSnackbar('Producto eliminado del carrito', { 
                variant: 'info',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'center'
                }
            });
        } else {
            addToCart?.(game);
            enqueueSnackbar('Producto agregado al carrito', { 
                variant: 'success',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'center'
                }
            });
        }
    };

    const inCart = isInCart?.(game.id) || false;

    const getOriginalPrice = () => {
        if (game.discount > 0) {
            if (game.originalPrice) {
                return Number(game.originalPrice);
            }
            return Number(game.price) / (1 - (Number(game.discount) / 100));
        }
        return Number(game.price);
    };

    const originalPrice = getOriginalPrice();
    const currentPrice = Number(game.price);

    return (
        <Card sx={{
            width: '100%',
            maxWidth: {
                md: '180px',
                lg: '190px',
                xl: '200px'
            },
            height: 'auto',
            minHeight: {
                xs: '220px',
                sm: '230px',
                md: '210px',
                lg: '220px',
                xl: '230px'
            },
            display: 'flex',
            flexDirection: 'column',
            background: 'rgba(10, 10, 31, 0.8)',
            backdropFilter: 'blur(10px)',
            border: { xs: '2px solid', sm: '3px solid', md: '3px solid' },
            borderColor: 'rgba(138, 43, 226, 0.8)',
            transition: 'all 0.3s cubic-bezier(0.4, 0, 0.2, 1)',
            borderRadius: { xs: '8px', sm: '10px', md: '12px' },
            overflow: 'hidden',
            position: 'relative',
            m: 'auto',
            p: 0
        }}>
            <CardMedia
                component="img"
                image={game.image ? `https://blackvortexgames.com/api/images/games/${game.image.split('/').pop()}` : defaultImage}
                alt={game.name}
                sx={{
                    width: '100%',
                    height: { 
                        xs: '210px',
                        sm: '220px',
                        md: '210px',
                        lg: '220px',
                        xl: '230px'
                    },
                    objectFit: 'cover'
                }}
            />
            {game.discount > 0 && (
                <Box
                    sx={{
                        position: 'absolute',
                        top: 10,
                        right: 10,
                        background: 'linear-gradient(45deg, #FF0000, #FF4081)',
                        color: 'white',
                        padding: { xs: '4px 8px', sm: '6px 12px' },
                        borderRadius: '4px',
                        fontWeight: 'bold',
                        fontSize: { xs: '0.8rem', sm: '0.9rem', md: '1rem' },
                        boxShadow: '0 2px 4px rgba(0,0,0,0.2)',
                        zIndex: 1,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center'
                    }}
                >
                    -{game.discount}%
                </Box>
            )}
            <Box sx={{
                flex: '1',
                display: 'flex',
                flexDirection: 'column',
                p: { xs: 0.3, sm: 0.5, md: 0.8 },
                pt: { xs: 0.3, sm: 0.5, md: 0.8 },
                pb: { xs: 4, sm: 0.8, md: 1 },
                background: 'rgba(10, 10, 31, 0.6)',
                position: 'relative',
                gap: 0,
                minHeight: 'auto'
            }}>
                {showPrice && (
                    <Box sx={{ 
                        textAlign: 'center',
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 0.5,
                        mb: 5,
                        mt: 0,
                        flexShrink: 0,
                        height: 'auto'
                    }}>
                        <Typography
                            variant="h6"
                            sx={{
                                color: '#00FFFF',
                                fontWeight: 700,
                                fontSize: { xs: '1rem', sm: '1.1rem', md: '1.2rem' },
                                lineHeight: 1,
                                mt: 0,
                                mb: 0
                            }}
                        >
                            ${formatCOP(currentPrice)} COP
                        </Typography>
                        {game.discount > 0 ? (
                            <Typography
                                variant="body1"
                                sx={{
                                    color: 'rgba(255,255,255,0.6)',
                                    textDecoration: 'line-through',
                                    fontSize: { xs: '0.8rem', sm: '0.85rem', md: '0.9rem' },
                                    mt: 0,
                                    mb: 0,
                                    lineHeight: 1
                                }}
                            >
                                ${formatCOP(originalPrice)} COP
                            </Typography>
                        ) : (
                            <Box sx={{ height: { xs: '0.8rem', sm: '0.85rem', md: '0.9rem' } }} />
                        )}
                    </Box>
                )}
                <Box sx={{
                    display: 'grid',
                    gridTemplateColumns: { xs: '1fr', sm: '1fr auto' },
                    gap: 0.8,
                    position: 'absolute',
                    bottom: 0,
                    left: 0,
                    right: 0,
                    px: { xs: 1, sm: 1.2, md: 1.3 },
                    pb: { xs: 0.5, sm: 0.8, md: 1 },
                    backgroundColor: 'rgba(10, 10, 31, 0.6)',
                    flexShrink: 0,
                    marginTop: 0,
                    transform: 'translateY(0)'
                }}>
                    <Button
                        component={Link}
                        to={`/game/${game.id}`}
                        variant="contained"
                        sx={{
                            background: 'linear-gradient(45deg, #6A0DAD, #9D00FF)',
                            color: 'white',
                            py: { xs: 0.2, sm: 0.3 },
                            fontSize: { xs: '0.75rem', sm: '0.8rem', md: '0.9rem' },
                            mb: 0,
                            minHeight: '24px',
                            height: { xs: '28px', sm: '30px' }
                        }}
                    >
                        Ver Ahora
                    </Button>
                    <Button
                        onClick={handleCartAction}
                        variant="outlined"
                        sx={{
                            minWidth: { xs: '100%', sm: 'auto' },
                            px: { xs: 0.8, sm: 1 },
                            py: { xs: 0.2, sm: 0.3 },
                            borderColor: '#00FFFF',
                            color: '#00FFFF',
                            mb: 0,
                            minHeight: '24px',
                            height: { xs: '28px', sm: '30px' }
                        }}
                    >
                        <ShoppingCartIcon sx={{ fontSize: { xs: '0.9rem', sm: '1rem' } }} />
                    </Button>
                </Box>
            </Box>
        </Card>
    );
}

export default GameCard; 