import React from 'react';
import { 
    Container, Box, Typography, Grid, Card, CardContent, 
    Button, CardActions 
} from '@mui/material';
import { Link } from 'react-router-dom';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import EditIcon from '@mui/icons-material/Edit';
import ViewCarouselIcon from '@mui/icons-material/ViewCarousel';
import AdminNavbar from '../../components/AdminNavbar';

function AdminDashboard() {
    return (
        <>
            <AdminNavbar />
            <Box sx={{ minHeight: '100vh', pt: { xs: 12, md: 16 }, pb: 8 }}>
                <Container maxWidth="lg">
                    <Typography variant="h4" sx={{ mb: 4, color: 'white', textAlign: 'center' }}>
                        Panel de Administración
                    </Typography>

                    <Grid container spacing={4} justifyContent="center">
                        {/* Tarjeta para Agregar Juegos */}
                        <Grid item xs={12} sm={6} md={4}>
                            <Card sx={{ 
                                height: '100%',
                                background: 'rgba(0, 0, 0, 0.5)',
                                backdropFilter: 'blur(10px)',
                                border: '1px solid rgba(106, 13, 173, 0.3)'
                            }}>
                                <CardContent>
                                    <Box sx={{ textAlign: 'center', mb: 2 }}>
                                        <AddCircleIcon sx={{ fontSize: 60, color: 'var(--accent-color)' }} />
                                    </Box>
                                    <Typography variant="h5" component="div" sx={{ color: 'white', mb: 2, textAlign: 'center' }}>
                                        Agregar Juego
                                    </Typography>
                                    <Typography sx={{ color: 'rgba(255,255,255,0.7)', textAlign: 'center' }}>
                                        Añade nuevos juegos a la tienda con toda su información
                                    </Typography>
                                </CardContent>
                                <CardActions sx={{ justifyContent: 'center', pb: 3 }}>
                                    <Button 
                                        component={Link} 
                                        to="/admin/add-game"
                                        variant="contained"
                                        sx={{
                                            background: 'var(--accent-color)',
                                            '&:hover': {
                                                background: 'var(--accent-dark)'
                                            }
                                        }}
                                    >
                                        Agregar Juego
                                    </Button>
                                </CardActions>
                            </Card>
                        </Grid>

                        {/* Tarjeta para Editar Juegos */}
                        <Grid item xs={12} sm={6} md={4}>
                            <Card sx={{ 
                                height: '100%',
                                background: 'rgba(0, 0, 0, 0.5)',
                                backdropFilter: 'blur(10px)',
                                border: '1px solid rgba(106, 13, 173, 0.3)'
                            }}>
                                <CardContent>
                                    <Box sx={{ textAlign: 'center', mb: 2 }}>
                                        <EditIcon sx={{ fontSize: 60, color: 'var(--accent-color)' }} />
                                    </Box>
                                    <Typography variant="h5" component="div" sx={{ color: 'white', mb: 2, textAlign: 'center' }}>
                                        Editar Juegos
                                    </Typography>
                                    <Typography sx={{ color: 'rgba(255,255,255,0.7)', textAlign: 'center' }}>
                                        Modifica o elimina juegos existentes en la tienda
                                    </Typography>
                                </CardContent>
                                <CardActions sx={{ justifyContent: 'center', pb: 3 }}>
                                    <Button 
                                        component={Link} 
                                        to="/admin/edit-games"
                                        variant="contained"
                                        sx={{
                                            background: 'var(--accent-color)',
                                            '&:hover': {
                                                background: 'var(--accent-dark)'
                                            }
                                        }}
                                    >
                                        Editar Juegos
                                    </Button>
                                </CardActions>
                            </Card>
                        </Grid>

                        {/* Tarjeta para Editar Carrusel */}
                        <Grid item xs={12} sm={6} md={4}>
                            <Card sx={{ 
                                height: '100%',
                                background: 'rgba(0, 0, 0, 0.5)',
                                backdropFilter: 'blur(10px)',
                                border: '1px solid rgba(106, 13, 173, 0.3)'
                            }}>
                                <CardContent>
                                    <Box sx={{ textAlign: 'center', mb: 2 }}>
                                        <ViewCarouselIcon sx={{ fontSize: 60, color: 'var(--accent-color)' }} />
                                    </Box>
                                    <Typography variant="h5" component="div" sx={{ color: 'white', mb: 2, textAlign: 'center' }}>
                                        Editar Carrusel
                                    </Typography>
                                    <Typography sx={{ color: 'rgba(255,255,255,0.7)', textAlign: 'center' }}>
                                        Modifica o elimina secciones del carrusel
                                    </Typography>
                                </CardContent>
                                <CardActions sx={{ justifyContent: 'center', pb: 3 }}>
                                    <Button 
                                        component={Link} 
                                        to="/admin/edit-carousel"
                                        variant="contained"
                                        sx={{
                                            background: 'var(--accent-color)',
                                            '&:hover': {
                                                background: 'var(--accent-dark)'
                                            }
                                        }}
                                    >
                                        Editar Carrusel
                                    </Button>
                                </CardActions>
                            </Card>
                        </Grid>
                    </Grid>
                </Container>
            </Box>
        </>
    );
}

export default AdminDashboard; 