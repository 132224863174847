import React, { useEffect } from 'react';
import { Container, Grid, Paper, Typography, Button, Box } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import EditIcon from '@mui/icons-material/Edit';
import ViewCarouselIcon from '@mui/icons-material/ViewCarousel';
import ShoppingCartCheckoutIcon from '@mui/icons-material/ShoppingCartCheckout';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import AddIcon from '@mui/icons-material/Add';
import MenuBookIcon from '@mui/icons-material/MenuBook';

function AdminPanel() {
    const navigate = useNavigate();

    useEffect(() => {
        const token = localStorage.getItem('adminToken');
        if (!token) {
            navigate('/admin/login');
        }
    }, [navigate]);

    const adminOptions = [
        {
            title: "AGREGAR JUEGO",
            description: "Añade nuevos juegos a la tienda",
            icon: <AddCircleIcon sx={{ fontSize: 50, color: '#00FFFF' }} />,
            path: "/admin/add-game",
            buttonText: "Agregar Juego"
        },
        {
            title: "EDITAR JUEGOS",
            description: "Modifica o elimina juegos existentes",
            icon: <EditIcon sx={{ fontSize: 50, color: '#00FFFF' }} />,
            path: "/admin/edit-games",
            buttonText: "Editar Juegos"
        },
        {
            title: "EDITAR CARRUSEL",
            description: "Gestiona las secciones del carrusel principal",
            icon: <ViewCarouselIcon sx={{ fontSize: 50, color: '#00FFFF' }} />,
            path: "/admin/carousel",
            buttonText: "Editar Carrusel"
        },
        {
            title: "AUTORIZAR COMPRAS",
            description: "Gestiona y autoriza las compras pendientes",
            icon: <ShoppingCartCheckoutIcon sx={{ fontSize: 50, color: '#00FFFF' }} />,
            path: "/admin/authorize-orders",
            buttonText: "Autorizar Compras"
        },
        {
            title: "GESTIONAR CUENTAS",
            description: "Gestiona las cuentas asignadas a los juegos",
            icon: <ManageAccountsIcon sx={{ fontSize: 50, color: '#00FFFF' }} />,
            path: "/admin/manage-accounts",
            buttonText: "Gestionar Cuentas"
        },
        {
            title: "AGREGAR CUENTAS",
            description: "Añade nuevas cuentas a los juegos",
            icon: <AddIcon sx={{ fontSize: 50, color: '#00FFFF' }} />,
            path: "/admin/add-accounts",
            buttonText: "Agregar Cuentas"
        },
        {
            title: "AGREGAR GUÍAS",
            description: "Añade guías y tutoriales para los juegos",
            icon: <MenuBookIcon sx={{ fontSize: 50, color: '#00FFFF' }} />,
            path: "/admin/guides",
            buttonText: "Agregar Guías"
        }
    ];

    return (
        <Box sx={{ 
            minHeight: '100vh', 
            bgcolor: 'background.default',
            pt: { xs: 10, sm: 12 },
            pb: 4,
            mt: 8
        }}>
            <Container maxWidth="lg">
                <Typography 
                    variant="h4" 
                    gutterBottom 
                    sx={{ 
                        color: 'white', 
                        mb: 4,
                        textAlign: 'center'
                    }}
                >
                    Panel de Administración
                </Typography>

                <Grid container spacing={4}>
                    {adminOptions.map((option, index) => (
                        <Grid item xs={12} sm={6} md={4} key={index}>
                            <Paper
                                elevation={3}
                                sx={{
                                    p: 3,
                                    height: '100%',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    textAlign: 'center',
                                    bgcolor: 'rgba(10, 10, 31, 0.8)',
                                    border: '1px solid rgba(106, 13, 173, 0.3)',
                                    transition: 'all 0.3s ease',
                                    '&:hover': {
                                        transform: 'translateY(-5px)',
                                        boxShadow: '0 0 20px rgba(138, 43, 226, 0.3)'
                                    }
                                }}
                            >
                                {option.icon}
                                <Typography variant="h6" sx={{ mt: 2, mb: 1, color: 'white' }}>
                                    {option.title}
                                </Typography>
                                <Typography variant="body2" sx={{ mb: 3, color: 'text.secondary' }}>
                                    {option.description}
                                </Typography>
                                <Button
                                    component={Link}
                                    to={option.path}
                                    variant="contained"
                                    sx={{
                                        mt: 'auto',
                                        bgcolor: 'rgba(106, 13, 173, 0.8)',
                                        '&:hover': {
                                            bgcolor: 'rgba(106, 13, 173, 1)'
                                        }
                                    }}
                                >
                                    {option.buttonText}
                                </Button>
                            </Paper>
                        </Grid>
                    ))}
                </Grid>
            </Container>
        </Box>
    );
}

export default AdminPanel; 