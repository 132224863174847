import React, { useState, useEffect } from 'react';
import { 
    Container, Box, Typography, TextField, Button, Grid, 
    Paper, Snackbar, Alert, InputAdornment 
} from '@mui/material';
import PriceInput from '../../components/PriceInput';
import { useParams, useNavigate } from 'react-router-dom';
import adminApi from '../../utils/adminApi';
import { formatCOP } from '../../utils/formatPrice';
import axios from 'axios';

function EditGame() {
    const { id } = useParams();
    const navigate = useNavigate();
    const [gameData, setGameData] = useState({
        name: '',
        price: '',
        discount: 0,
        image: '',
        description: '',
        longDescription: '',
        requirements: {
            minimum: {
                os: '',
                processor: '',
                memory: '',
                graphics: '',
                storage: '',
                directx: ''
            },
            recommended: {
                os: '',
                processor: '',
                memory: '',
                graphics: '',
                storage: '',
                directx: ''
            }
        },
        features: {
            audio: [],
            subtitles: [],
            genre: [],
            releaseDate: '',
            platform: 'Steam',
            developer: '',
            publisher: ''
        },
        originalPrice: ''
    });

    const [alert, setAlert] = useState({
        open: false,
        message: '',
        severity: 'success'
    });

    const [imagePreview, setImagePreview] = useState('');
    const [discountedPrice, setDiscountedPrice] = useState(null);
    const [discountAmount, setDiscountAmount] = useState('');

    useEffect(() => {
        const fetchGame = async () => {
            try {
                const response = await adminApi.get(`/games/${id}`);

                if (response.data) {
                    const game = response.data;
                    
                    // Asegurarse de que los arrays y objetos estén inicializados correctamente
                    const features = {
                        audio: Array.isArray(game.features?.audio) ? game.features.audio : game.features?.audio?.split(',').filter(Boolean) || [],
                        subtitles: Array.isArray(game.features?.subtitles) ? game.features.subtitles : game.features?.subtitles?.split(',').filter(Boolean) || [],
                        genre: Array.isArray(game.features?.genre) ? game.features.genre : game.features?.genre?.split(',').filter(Boolean) || [],
                        releaseDate: game.features?.releaseDate || '',
                        platform: game.features?.platform || 'Steam',
                        developer: game.features?.developer || '',
                        publisher: game.features?.publisher || ''
                    };

                    const requirements = {
                        minimum: {
                            os: game.requirements?.minimum?.os || '',
                            processor: game.requirements?.minimum?.processor || '',
                            memory: game.requirements?.minimum?.memory || '',
                            graphics: game.requirements?.minimum?.graphics || '',
                            storage: game.requirements?.minimum?.storage || '',
                            directx: game.requirements?.minimum?.directx || ''
                        },
                        recommended: {
                            os: game.requirements?.recommended?.os || '',
                            processor: game.requirements?.recommended?.processor || '',
                            memory: game.requirements?.recommended?.memory || '',
                            graphics: game.requirements?.recommended?.graphics || '',
                            storage: game.requirements?.recommended?.storage || '',
                            directx: game.requirements?.recommended?.directx || ''
                        }
                    };

                    setGameData({
                        ...game,
                        features,
                        requirements,
                        originalPrice: Number(game.price || 0),
                        price: Number(game.price || 0),
                        description: game.description || '',
                        longDescription: game.longDescription || ''
                    });
                }
            } catch (error) {
                console.error('Error al obtener el juego:', error);
                if (error.response?.status === 401) {
                    localStorage.removeItem('adminToken');
                    navigate('/admin/login');
                }
            }
        };

        if (id) {
            fetchGame();
        }
    }, [id, navigate]);

    const handleChange = (e, section, subsection) => {
        const { name, value } = e.target;
        
        if (name === 'originalPrice') {
            setGameData(prev => ({
                ...prev,
                originalPrice: value,
                price: value,
                discount: 0
            }));
            setDiscountAmount('');
        } else if (section && subsection) {
            setGameData(prev => ({
                ...prev,
                [section]: {
                    ...prev[section],
                    [subsection]: {
                        ...prev[section][subsection],
                        [name]: value
                    }
                }
            }));
        } else if (section) {
            setGameData(prev => ({
                ...prev,
                [section]: {
                    ...prev[section],
                    [name]: value
                }
            }));
        } else {
            setGameData(prev => ({
                ...prev,
                [name]: value
            }));
        }
    };

    const handleImageChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            const previewUrl = URL.createObjectURL(file);
            setGameData(prev => ({
                ...prev,
                imageFile: file,
                oldImage: prev.image,
                image: previewUrl
            }));
            setImagePreview(previewUrl);
        }
    };

    const handleDiscountAmountChange = (e) => {
        const amountToDiscount = e.target.value === '' ? 0 : Number(e.target.value);
        const originalPrice = Number(gameData.originalPrice);
        
        if (amountToDiscount > originalPrice) {
            return;
        }

        const finalPrice = originalPrice - amountToDiscount;
        const calculatedDiscount = Math.round((amountToDiscount / originalPrice) * 100);

        setDiscountAmount(e.target.value);
        setGameData(prev => ({
            ...prev,
            originalPrice: originalPrice,
            price: finalPrice,
            discount: calculatedDiscount
        }));
    };

    const handleImageUpload = async (file) => {
        const formData = new FormData();
        formData.append('image', file);
        const response = await axios.post(
            `${process.env.REACT_APP_BACKEND_URL}/upload`,
            formData,
            {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }
        );
        // ...
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            // Asegurarse de que los objetos estén completos antes de enviar
            const formData = new FormData();

            // Convertir objetos a strings para el FormData
            formData.append('requirements', JSON.stringify({
                minimum: gameData.requirements?.minimum || {
                    os: '',
                    processor: '',
                    memory: '',
                    graphics: '',
                    storage: '',
                    directx: ''
                },
                recommended: gameData.requirements?.recommended || {
                    os: '',
                    processor: '',
                    memory: '',
                    graphics: '',
                    storage: '',
                    directx: ''
                }
            }));

            formData.append('features', JSON.stringify({
                audio: gameData.features?.audio || [],
                subtitles: gameData.features?.subtitles || [],
                genre: gameData.features?.genre || [],
                releaseDate: gameData.features?.releaseDate || '',
                platform: gameData.features?.platform || 'Steam',
                developer: gameData.features?.developer || '',
                publisher: gameData.features?.publisher || ''
            }));

            // Agregar el resto de los campos
            formData.append('name', gameData.name || '');
            formData.append('price', gameData.price || 0);
            formData.append('originalPrice', gameData.originalPrice || 0);
            formData.append('discount', gameData.discount || 0);
            formData.append('description', gameData.description || '');
            formData.append('longDescription', gameData.longDescription || '');

            if (gameData.imageFile) {
                formData.append('coverImage', gameData.imageFile);
            } else if (gameData.image) {
                formData.append('image', gameData.image);
            }

            const response = await adminApi.put(`/games/${id}`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });

            if (response.data.success) {
                setAlert({
                    open: true,
                    message: 'Juego actualizado correctamente',
                    severity: 'success'
                });
                setTimeout(() => {
                    navigate('/admin/games');
                }, 2000);
            }
        } catch (error) {
            console.error('Error al actualizar el juego:', error);
            if (error.response?.status === 401) {
                localStorage.removeItem('adminToken');
                navigate('/admin/login');
            }
            setAlert({
                open: true,
                message: error.message || error.response?.data?.message || 'Error al actualizar el juego',
                severity: 'error'
            });
        }
    };

    return (
        <Box sx={{ minHeight: '100vh', pt: { xs: 12, md: 16 }, pb: 8 }}>
            <Container maxWidth="lg">
                <Paper 
                    elevation={3} 
                    sx={{ 
                        p: 4, 
                        background: 'rgba(0, 0, 0, 0.5)',
                        backdropFilter: 'blur(10px)',
                        border: '1px solid rgba(255, 255, 255, 0.1)'
                    }}
                >
                    <Typography variant="h4" sx={{ mb: 4, color: 'white' }}>
                        Editar Juego
                    </Typography>

                    <form onSubmit={handleSubmit}>
                        <Grid container spacing={3}>
                            <Grid item xs={12} md={4}>
                                <TextField
                                    fullWidth
                                    label="Nombre del Juego"
                                    name="name"
                                    value={gameData.name}
                                    onChange={handleChange}
                                    required
                                    sx={{ 
                                        input: { color: 'white' },
                                        '& label': { color: 'rgba(255, 255, 255, 0.7)' }
                                    }}
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <Typography variant="subtitle1" sx={{ mt: 2, mb: 2, color: 'white' }}>
                                    Información de Precios
                                </Typography>
                            </Grid>

                            <Grid item xs={12} md={4}>
                                <TextField
                                    fullWidth
                                    label="Precio Original"
                                    name="originalPrice"
                                    type="number"
                                    value={gameData.originalPrice}
                                    onChange={handleChange}
                                    required
                                    InputProps={{
                                        startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                    }}
                                    inputProps={{
                                        style: { 
                                            color: 'white',
                                            height: '1.4375em',
                                            padding: '16.5px 14px'
                                        }
                                    }}
                                    sx={{ 
                                        '& .MuiInputBase-root': {
                                            height: '56px'
                                        },
                                        '& .MuiOutlinedInput-root': {
                                            '& fieldset': {
                                                borderColor: 'rgba(255, 255, 255, 0.23)',
                                            },
                                            '&:hover fieldset': {
                                                borderColor: 'rgba(255, 255, 255, 0.5)',
                                            },
                                            '&.Mui-focused fieldset': {
                                                borderColor: 'rgba(138, 43, 226, 0.8)',
                                            }
                                        },
                                        '& .MuiInputLabel-root': {
                                            transform: 'translate(14px, -6px) scale(0.75)',
                                            '&.Mui-focused': {
                                                color: 'rgba(138, 43, 226, 0.8)'
                                            }
                                        }
                                    }}
                                />
                            </Grid>

                            <Grid item xs={12} md={4}>
                                <TextField
                                    fullWidth
                                    label="Monto a descontar"
                                    name="discountAmount"
                                    type="number"
                                    value={discountAmount}
                                    onChange={handleDiscountAmountChange}
                                    InputProps={{
                                        startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                    }}
                                    inputProps={{ 
                                        min: 0, 
                                        max: gameData.originalPrice,
                                        step: "1000",
                                        style: { 
                                            color: 'white',
                                            height: '1.4375em',
                                            padding: '16.5px 14px'
                                        }
                                    }}
                                    sx={{ 
                                        '& .MuiInputBase-root': {
                                            height: '56px',
                                        },
                                        '& .MuiOutlinedInput-root': {
                                            '& fieldset': {
                                                borderColor: 'rgba(255, 255, 255, 0.23)',
                                            },
                                            '&:hover fieldset': {
                                                borderColor: 'rgba(255, 255, 255, 0.5)',
                                            },
                                            '&.Mui-focused fieldset': {
                                                borderColor: 'rgba(138, 43, 226, 0.8)',
                                            }
                                        },
                                        '& label': { 
                                            color: 'rgba(255, 255, 255, 0.7)',
                                            transform: 'translate(14px, 16px) scale(1)'
                                        },
                                        '& label.Mui-focused': {
                                            color: 'rgba(138, 43, 226, 0.8)'
                                        },
                                        '& label.MuiInputLabel-shrink': {
                                            transform: 'translate(14px, -6px) scale(0.75)'
                                        }
                                    }}
                                />
                            </Grid>

                            <Grid item xs={12} md={4}>
                                <TextField
                                    fullWidth
                                    label="Precio Final"
                                    value={formatCOP(gameData.price)}
                                    disabled
                                    InputProps={{
                                        startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                        readOnly: true
                                    }}
                                    inputProps={{
                                        style: { 
                                            color: '#00FFFF',
                                            height: '1.4375em',
                                            padding: '16.5px 14px'
                                        }
                                    }}
                                    sx={{ 
                                        '& .MuiInputBase-root': {
                                            height: '56px',
                                            background: 'rgba(0, 255, 255, 0.05)'
                                        },
                                        '& .MuiOutlinedInput-root': {
                                            '& fieldset': {
                                                borderColor: 'rgba(0, 255, 255, 0.2)',
                                            },
                                            '&:hover fieldset': {
                                                borderColor: 'rgba(0, 255, 255, 0.3)',
                                            },
                                            '&.Mui-disabled': {
                                                '& fieldset': {
                                                    borderColor: 'rgba(0, 255, 255, 0.2)',
                                                }
                                            }
                                        },
                                        '& .MuiInputLabel-root': {
                                            transform: 'translate(14px, -6px) scale(0.75)',
                                            '&.Mui-disabled': {
                                                color: 'rgba(0, 255, 255, 0.7)'
                                            }
                                        },
                                        '& .Mui-disabled': {
                                            '-webkit-text-fill-color': '#00FFFF',
                                            cursor: 'not-allowed'
                                        }
                                    }}
                                />
                            </Grid>

                            {discountAmount > 0 && (
                                <Grid item xs={12}>
                                    <Box sx={{ 
                                        p: 2, 
                                        mt: 1,
                                        borderRadius: 1,
                                        background: 'rgba(0, 255, 255, 0.1)',
                                        border: '1px solid rgba(0, 255, 255, 0.2)'
                                    }}>
                                        <Typography sx={{ color: '#00FFFF', fontSize: '0.9rem' }}>
                                            Precio original (tachado): ${formatCOP(gameData.originalPrice)} COP
                                        </Typography>
                                        <Typography sx={{ color: '#00FFFF', fontSize: '0.9rem' }}>
                                            Descuento aplicado: {gameData.discount}%
                                        </Typography>
                                        <Typography sx={{ color: '#00FFFF', fontSize: '0.9rem' }}>
                                            Precio final (mostrado): ${formatCOP(gameData.price)} COP
                                        </Typography>
                                    </Box>
                                </Grid>
                            )}

                            <Grid item xs={12} md={6}>
                                <Typography variant="subtitle1" sx={{ color: 'white', mb: 2 }}>
                                    Imagen del Juego
                                </Typography>

                                {gameData.image && (
                                    <Box sx={{ mb: 2 }}>
                                        <Typography variant="subtitle2" sx={{ color: 'white', mb: 1 }}>
                                            Imagen actual:
                                        </Typography>
                                        <img 
                                            src={imagePreview || gameData.image}
                                            alt="Current game cover"
                                            style={{ 
                                                maxWidth: '100%',
                                                height: 'auto',
                                                borderRadius: '8px',
                                                border: '2px solid rgba(106, 13, 173, 0.3)'
                                            }}
                                        />
                                    </Box>
                                )}

                                <input
                                    accept="image/*"
                                    style={{ display: 'none' }}
                                    id="cover-image-upload"
                                    type="file"
                                    onChange={handleImageChange}
                                />
                                <label htmlFor="cover-image-upload">
                                    <Button
                                        variant="contained"
                                        component="span"
                                        fullWidth
                                        sx={{
                                            mb: 2,
                                            background: 'var(--accent-color)',
                                            '&:hover': {
                                                background: 'var(--accent-dark)'
                                            }
                                        }}
                                    >
                                        Cambiar Imagen
                                    </Button>
                                </label>

                                <Typography variant="subtitle2" sx={{ color: 'white', mb: 1, mt: 2 }}>
                                    O ingresa una URL
                                </Typography>
                                <TextField
                                    fullWidth
                                    placeholder="https://ejemplo.com/imagen.jpg"
                                    name="image"
                                    value={gameData.image}
                                    onChange={(e) => handleChange(e)}
                                    sx={{ 
                                        input: { color: 'white' },
                                        '& label': { color: 'rgba(255, 255, 255, 0.7)' }
                                    }}
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <Button 
                                    type="submit"
                                    variant="contained"
                                    size="large"
                                    sx={{
                                        mt: 3,
                                        background: 'var(--accent-color)',
                                        '&:hover': {
                                            background: 'var(--accent-dark)'
                                        }
                                    }}
                                >
                                    Guardar Cambios
                                </Button>
                            </Grid>
                        </Grid>
                    </form>
                </Paper>

                <Snackbar 
                    open={alert.open} 
                    autoHideDuration={6000} 
                    onClose={() => setAlert({ ...alert, open: false })}
                >
                    <Alert 
                        onClose={() => setAlert({ ...alert, open: false })} 
                        severity={alert.severity}
                        sx={{ width: '100%' }}
                    >
                        {alert.message}
                    </Alert>
                </Snackbar>
            </Container>
        </Box>
    );
}

export default EditGame; 