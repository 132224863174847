import React, { useState, useEffect } from 'react';
import {
    Container,
    Box,
    Typography,
    Paper,
    Grid,
    Card,
    CardMedia,
    CardContent,
    IconButton,
    CircularProgress,
    Button
} from '@mui/material';
import { Add as AddIcon, Edit as EditIcon, Delete as DeleteIcon } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useSnackbar } from 'notistack';

function CarouselList() {
    const [carousels, setCarousels] = useState([]);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {
        fetchCarousels();
    }, []);

    const fetchCarousels = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/carousel`);
            if (response.data.success) {
                setCarousels(response.data.data || []);
            }
        } catch (error) {
            console.error('Error al cargar carruseles:', error);
        } finally {
            setLoading(false);
        }
    };

    const handleDelete = async (id) => {
        if (window.confirm('¿Estás seguro de que quieres eliminar este carrusel?')) {
            try {
                const token = localStorage.getItem('adminToken');
                await axios.delete(`${process.env.REACT_APP_BACKEND_URL}/carousel/${id}`, {
                    headers: { Authorization: `Bearer ${token}` }
                });
                
                enqueueSnackbar('Carrusel eliminado correctamente', { 
                    variant: 'success',
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'center'
                    }
                });
                
                // Actualizar la lista de carruseles
                fetchCarousels();
            } catch (error) {
                console.error('Error al eliminar carrusel:', error);
                enqueueSnackbar('Error al eliminar el carrusel', { 
                    variant: 'error',
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'center'
                    }
                });
            }
        }
    };

    if (loading) {
        return <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '60vh' }}>
            <CircularProgress />
        </Box>;
    }

    return (
        <Box sx={{ pt: 8, px: 2 }}>
            <Container maxWidth="xl">
                <Box sx={{ 
                    display: 'flex', 
                    justifyContent: 'space-between', 
                    alignItems: 'center',
                    mb: 4 
                }}>
                    <Typography variant="h4">Gestionar Carruseles</Typography>
                    <Button
                        variant="contained"
                        onClick={() => navigate('/admin/carousel/add')}
                        startIcon={<AddIcon />}
                        sx={{
                            bgcolor: 'var(--accent-color)',
                            '&:hover': {
                                bgcolor: 'var(--accent-dark)'
                            }
                        }}
                    >
                        Agregar Carrusel
                    </Button>
                </Box>

                {carousels.length === 0 ? (
                    <Box sx={{ 
                        display: 'flex', 
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        minHeight: '200px',
                        bgcolor: 'rgba(0,0,0,0.5)',
                        borderRadius: 2,
                        p: 4
                    }}>
                        <Typography variant="h6" sx={{ mb: 2 }}>
                            No hay carruseles disponibles
                        </Typography>
                        <Button
                            variant="contained"
                            onClick={() => navigate('/admin/carousel/add')}
                            startIcon={<AddIcon />}
                            sx={{
                                bgcolor: 'var(--accent-color)',
                                '&:hover': {
                                    bgcolor: 'var(--accent-dark)'
                                }
                            }}
                        >
                            Agregar Primer Carrusel
                        </Button>
                    </Box>
                ) : (
                    <Grid container spacing={3}>
                        {carousels.map((carousel) => (
                            <Grid item xs={12} sm={6} md={4} key={carousel.id}>
                                <Card sx={{ 
                                    height: '100%',
                                    background: 'rgba(0, 0, 0, 0.3)',
                                    border: '1px solid rgba(255, 255, 255, 0.1)',
                                    borderRadius: '8px',
                                    overflow: 'hidden'
                                }}>
                                    <CardMedia
                                        component="img"
                                        height="200"
                                        image={`${process.env.REACT_APP_BACKEND_URL}${carousel.image}`}
                                        alt={carousel.title}
                                        sx={{ objectFit: 'cover' }}
                                    />
                                    <CardContent>
                                        <Typography variant="h6" sx={{ color: 'white', mb: 1 }}>
                                            {carousel.title}
                                        </Typography>
                                        <Typography variant="body2" sx={{ color: 'rgba(255, 255, 255, 0.7)' }}>
                                            {carousel.subtitle}
                                        </Typography>
                                        <Box sx={{ mt: 2, display: 'flex', gap: 1 }}>
                                            <IconButton 
                                                onClick={() => navigate(`/admin/carousel/edit/${carousel.id}`)}
                                                sx={{ color: 'primary.main' }}
                                            >
                                                <EditIcon />
                                            </IconButton>
                                            <IconButton 
                                                onClick={() => handleDelete(carousel.id)}
                                                sx={{ color: 'error.main' }}
                                            >
                                                <DeleteIcon />
                                            </IconButton>
                                        </Box>
                                    </CardContent>
                                </Card>
                            </Grid>
                        ))}
                    </Grid>
                )}
            </Container>
        </Box>
    );
}

export default CarouselList; 