import React from 'react';
import { Container, Typography, Grid, Card, Box, Button, Chip } from '@mui/material';
import { motion } from 'framer-motion';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import PersonIcon from '@mui/icons-material/Person';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { images } from '../config/images';
import StarBackground from '../components/StarBackground';

function Blog() {
    const blogPosts = [
        {
            id: 1,
            title: "Guía Completa de Netflix 2024",
            excerpt: "Descubre todas las novedades y funciones premium que Netflix tiene para ti este año.",
            image: images.blog.netflix,
            category: "Guías",
            author: "Admin",
            date: "Marzo 15, 2024",
            readTime: "5 min"
        },
        {
            id: 2,
            title: "Comparativa: Spotify vs Apple Music",
            excerpt: "¿Cuál servicio de streaming musical es mejor para ti? Analizamos todas las diferencias.",
            image: images.blog.spotify,
            category: "Comparativas",
            author: "Admin",
            date: "Marzo 10, 2024",
            readTime: "8 min"
        },
        {
            id: 3,
            title: "Novedades Disney+ 2024",
            excerpt: "Todo el contenido nuevo que llegará a Disney+ este año: Marvel, Star Wars y más.",
            image: images.blog.disney,
            category: "Noticias",
            author: "Admin",
            date: "Marzo 5, 2024",
            readTime: "6 min"
        }
    ];

    return (
        <Box sx={{ position: 'relative', minHeight: '100vh' }}>
            <StarBackground />
            <Box 
                sx={{ 
                    position: 'relative',
                    zIndex: 1,
                    minHeight: '100vh',
                    pt: { xs: 12, md: 16 },
                    pb: 8
                }}
            >
                <Container maxWidth="xl">
                    <motion.div
                        initial={{ opacity: 0, y: 20 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.5 }}
                    >
                        <Typography 
                            variant="h2" 
                            component="h1" 
                            color="white"
                            sx={{ 
                                mb: 2,
                                fontWeight: 700,
                                textAlign: 'center'
                            }}
                        >
                            Blog
                        </Typography>
                        <Typography 
                            variant="h5" 
                            color="white"
                            sx={{ 
                                mb: 6,
                                opacity: 0.8,
                                textAlign: 'center'
                            }}
                        >
                            Noticias, guías y actualizaciones sobre tus plataformas favoritas
                        </Typography>

                        <Grid container spacing={4}>
                            {blogPosts.map((post, index) => (
                                <Grid item xs={12} md={4} key={post.id}>
                                    <motion.div
                                        initial={{ opacity: 0, y: 20 }}
                                        animate={{ opacity: 1, y: 0 }}
                                        transition={{ delay: index * 0.1 }}
                                    >
                                        <Card 
                                            className="glass-effect card-hover"
                                            sx={{ 
                                                height: '100%',
                                                background: 'rgba(255, 255, 255, 0.1)',
                                                backdropFilter: 'blur(10px)',
                                                borderRadius: 'var(--border-radius-lg)',
                                                color: 'white',
                                                overflow: 'hidden'
                                            }}
                                        >
                                            <Box
                                                component="img"
                                                src={post.image}
                                                alt={post.title}
                                                sx={{
                                                    width: '100%',
                                                    height: 240,
                                                    objectFit: 'cover',
                                                    transition: 'transform 0.3s ease',
                                                    '&:hover': {
                                                        transform: 'scale(1.05)'
                                                    }
                                                }}
                                            />
                                            <Box sx={{ p: 3 }}>
                                                <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
                                                    <Chip 
                                                        label={post.category}
                                                        size="small"
                                                        sx={{ 
                                                            bgcolor: 'var(--accent-color)',
                                                            color: 'white'
                                                        }}
                                                    />
                                                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
                                                        <AccessTimeIcon sx={{ fontSize: '1rem', opacity: 0.7 }} />
                                                        <Typography variant="caption">
                                                            {post.readTime}
                                                        </Typography>
                                                    </Box>
                                                </Box>

                                                <Typography 
                                                    variant="h5" 
                                                    sx={{ 
                                                        fontWeight: 600,
                                                        mb: 2,
                                                        lineHeight: 1.3
                                                    }}
                                                >
                                                    {post.title}
                                                </Typography>

                                                <Typography 
                                                    sx={{ 
                                                        opacity: 0.8,
                                                        mb: 3
                                                    }}
                                                >
                                                    {post.excerpt}
                                                </Typography>

                                                <Box sx={{ 
                                                    display: 'flex', 
                                                    justifyContent: 'space-between',
                                                    alignItems: 'center',
                                                    mt: 'auto'
                                                }}>
                                                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                                        <PersonIcon sx={{ opacity: 0.7 }} />
                                                        <Typography variant="body2">
                                                            {post.author}
                                                        </Typography>
                                                    </Box>
                                                    <Button
                                                        endIcon={<ArrowForwardIcon />}
                                                        sx={{
                                                            color: 'var(--accent-color)',
                                                            '&:hover': {
                                                                bgcolor: 'rgba(255,255,255,0.1)'
                                                            }
                                                        }}
                                                    >
                                                        Leer más
                                                    </Button>
                                                </Box>
                                            </Box>
                                        </Card>
                                    </motion.div>
                                </Grid>
                            ))}
                        </Grid>
                    </motion.div>
                </Container>
            </Box>
        </Box>
    );
}

export default Blog; 