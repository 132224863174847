import React from 'react';
import { Box, Container, Typography, Grid } from '@mui/material';
import GameCard from './GameCard';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';

function SalesSection({ games }) {
    const discountedGames = games.filter(game => game.discount > 0);

    if (!discountedGames.length) return null;

    return (
        <Box sx={{ py: 6, background: 'linear-gradient(180deg, rgba(106, 13, 173, 0.3), rgba(0, 0, 0, 0))' }}>
            <Container>
                <Box sx={{ 
                    textAlign: 'center', 
                    mb: 6,
                    background: 'rgba(0, 0, 0, 0.5)',
                    p: 4,
                    borderRadius: '16px',
                    border: '1px solid rgba(255, 255, 255, 0.1)'
                }}>
                    <LocalOfferIcon sx={{ 
                        fontSize: '4rem', 
                        color: '#00FFFF',
                        mb: 2
                    }} />
                    <Typography variant="h3" sx={{ 
                        color: 'white',
                        mb: 2,
                        textTransform: 'uppercase',
                        fontWeight: 'bold'
                    }}>
                        ¡Aprovecha las Ofertas!
                    </Typography>
                    <Typography variant="h6" sx={{ 
                        color: 'rgba(255, 255, 255, 0.8)',
                        maxWidth: '800px',
                        mx: 'auto'
                    }}>
                        No te pierdas estas increíbles ofertas en juegos de Steam. 
                        Obtén acceso instantáneo a los mejores títulos con descuentos 
                        exclusivos y disfruta de una experiencia gaming premium por menos.
                    </Typography>
                </Box>

                <Grid container spacing={2}>
                    {discountedGames.map((game) => (
                        <Grid item key={game.id} xs={6} sm={4} md={3}>
                            <GameCard game={game} showPrice={true} />
                        </Grid>
                    ))}
                </Grid>
            </Container>
        </Box>
    );
}

export default SalesSection;