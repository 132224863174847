import React, { useState, useEffect } from 'react';
import { 
    Box, 
    Typography, 
    TextField, 
    Slider, 
    Button,
    FormControl,
    FormControlLabel,
    Checkbox,
    Radio,
    RadioGroup,
    Divider
} from '@mui/material';

function FilterMenu({ onFilter, initialPriceRange, initialCategory, initialSortOrder, initialSearchTerm }) {
    const [priceRange, setPriceRange] = useState(initialPriceRange || [0, 1000000]);
    const [searchTerm, setSearchTerm] = useState(initialSearchTerm || '');
    const [selectedCategory, setSelectedCategory] = useState(initialCategory || '');
    const [sortBy, setSortBy] = useState(initialSortOrder || '');

    const handlePriceChange = (event, newValue) => {
        setPriceRange(newValue);
    };

    const handleFilter = () => {
        console.log('Enviando filtros:', { searchTerm });
        onFilter({
            priceRange,
            searchTerm,
            category: selectedCategory,
            sortBy
        });
    };

    const handleClear = () => {
        setPriceRange([0, 1000000]);
        setSearchTerm('');
        setSelectedCategory('');
        setSortBy('');
        onFilter({
            priceRange: [0, 1000000],
            searchTerm: '',
            category: '',
            sortBy: ''
        });
    };

    const handleSearchChange = (e) => {
        const newSearchTerm = e.target.value;
        setSearchTerm(newSearchTerm);
        setTimeout(() => {
            onFilter({
                priceRange,
                searchTerm: newSearchTerm,
                category: selectedCategory,
                sortBy
            });
        }, 300);
    };

    useEffect(() => {
        handleFilter();
    }, [priceRange, selectedCategory, sortBy]);

    return (
        <>
            <Typography variant="h6" sx={{ mb: 3, color: 'white' }}>
                FILTRAR
            </Typography>

            <Box sx={{ mb: 4 }}>
                <Typography sx={{ mb: 1, color: 'white' }}>PRODUCTO</Typography>
                <TextField
                    fullWidth
                    placeholder="Ingrese el producto.."
                    value={searchTerm}
                    onChange={handleSearchChange}
                    sx={{
                        '& .MuiOutlinedInput-root': {
                            color: 'white',
                            '& fieldset': {
                                borderColor: 'rgba(255, 255, 255, 0.23)',
                            },
                            '&:hover fieldset': {
                                borderColor: 'rgba(255, 255, 255, 0.5)',
                            },
                        },
                    }}
                />
            </Box>

            <Box sx={{ mb: 4 }}>
                <Typography sx={{ mb: 1, color: 'white' }}>PRECIO</Typography>
                <Slider
                    value={priceRange}
                    onChange={handlePriceChange}
                    valueLabelDisplay="auto"
                    min={0}
                    max={1000000}
                    sx={{
                        color: '#6A0DAD',
                        '& .MuiSlider-thumb': {
                            backgroundColor: '#9D00FF',
                        },
                        '& .MuiSlider-track': {
                            backgroundColor: '#9D00FF',
                        },
                    }}
                />
                <Box sx={{ display: 'flex', justifyContent: 'space-between', color: 'white' }}>
                    <Typography>Precio: {priceRange[0].toLocaleString()}</Typography>
                    <Typography>- {priceRange[1].toLocaleString()}</Typography>
                </Box>
            </Box>

            <Box sx={{ mb: 4 }}>
                <Typography sx={{ mb: 1, color: 'white' }}>CATEGORÍA</Typography>
                <FormControl component="fieldset">
                    <RadioGroup
                        value={selectedCategory}
                        onChange={(e) => setSelectedCategory(e.target.value)}
                    >
                        <FormControlLabel 
                            value="Principal" 
                            control={<Radio sx={{ color: 'white' }} />} 
                            label="Principal" 
                            sx={{ color: 'white' }}
                        />
                        <FormControlLabel 
                            value="Secundaria" 
                            control={<Radio sx={{ color: 'white' }} />} 
                            label="Secundaria" 
                            sx={{ color: 'white' }}
                        />
                    </RadioGroup>
                </FormControl>
            </Box>

            <Box sx={{ mb: 4 }}>
                <Typography sx={{ mb: 1, color: 'white' }}>ORDENAR POR</Typography>
                <FormControl component="fieldset">
                    <RadioGroup
                        value={sortBy}
                        onChange={(e) => setSortBy(e.target.value)}
                    >
                        <FormControlLabel 
                            value="date" 
                            control={<Radio sx={{ color: 'white' }} />} 
                            label="Fecha de lanzamiento" 
                            sx={{ color: 'white' }}
                        />
                        <FormControlLabel 
                            value="price_asc" 
                            control={<Radio sx={{ color: 'white' }} />} 
                            label="Precio: Menor a Mayor" 
                            sx={{ color: 'white' }}
                        />
                        <FormControlLabel 
                            value="price_desc" 
                            control={<Radio sx={{ color: 'white' }} />} 
                            label="Precio: Mayor a Menor" 
                            sx={{ color: 'white' }}
                        />
                        <FormControlLabel 
                            value="name" 
                            control={<Radio sx={{ color: 'white' }} />} 
                            label="Nombre" 
                            sx={{ color: 'white' }}
                        />
                    </RadioGroup>
                </FormControl>
            </Box>

            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                <Button
                    variant="outlined"
                    onClick={handleClear}
                    fullWidth
                    sx={{
                        borderColor: '#00FFFF',
                        color: '#00FFFF',
                        '&:hover': {
                            borderColor: '#00FFFF',
                            background: 'rgba(0,255,255,0.1)',
                        }
                    }}
                >
                    Limpiar
                </Button>
            </Box>
        </>
    );
}

export default FilterMenu; 