import React, { lazy, Suspense } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Navbar from './components/Navbar';
import Home from './pages/Home';
import Products from './pages/Products';
import Blog from './pages/Blog';
import Contact from './pages/Contact';
import Cart from './pages/Cart';
import Login from './pages/Login';
import Register from './pages/Register';
import Profile from './pages/user/Profile';
import Settings from './pages/user/Settings';
import Orders from './pages/user/Orders';
import { CartProvider } from './context/CartContext';
import GameDetails from './pages/GameDetails';
import AdminLogin from './pages/admin/AdminLogin';
import AddGame from './pages/admin/AddGame';
import EditGames from './pages/admin/EditGames';
import EditGame from './pages/admin/EditGame';
import AdminDashboard from './pages/admin/AdminDashboard';
import Background from './components/Background';
import { AuthProvider } from './context/AuthContext';
import SocialBar from './components/SocialBar';
import Footer from './components/Footer';
import { Box } from '@mui/material';
import ScrollToTop from './components/ScrollToTop';
import { motion, AnimatePresence } from 'framer-motion';
import EditCarousel from './pages/admin/EditCarousel';
import AdminPanel from './pages/admin/AdminPanel';
import { SnackbarProvider } from 'notistack';
import CarouselList from './pages/admin/CarouselList';
import AddCarousel from './pages/admin/AddCarousel';
import AuthorizeOrders from './pages/admin/AuthorizeOrders';
import ProtectedRoute from './components/ProtectedRoute';
import Checkout from './pages/Checkout';
import Payment from './pages/Payment';
import './App.css';
import AddGameAccounts from './pages/admin/AddGameAccounts';
import ManageGameAccounts from './pages/admin/ManageGameAccounts';
import AddGuides from './pages/admin/AddGuides';
import Sales from './pages/Sales';

const theme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: '#00FFFF',
      dark: '#00CCCC',
      light: '#40FFFF',
      contrastText: '#ffffff'
    },
    secondary: {
      main: '#FF00FF',
      dark: '#CC00CC',
      light: '#FF40FF',
      contrastText: '#ffffff'
    },
    accent: {
      neon: '#00FF00',
      purple: '#9D00FF',
      blue: '#00F5FF',
      pink: '#FF00FF',
      gold: '#FFD700'
    },
    background: {
      default: 'transparent',
      paper: 'rgba(42, 0, 77, 0.6)',
      gradient: 'linear-gradient(135deg, rgba(26, 0, 51, 0.6) 0%, rgba(77, 0, 153, 0.6) 100%)'
    },
    text: {
      primary: '#FFFFFF',
      secondary: 'rgba(255, 255, 255, 0.8)',
      accent: '#00FFFF'
    }
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          borderRadius: '8px'
        }
      }
    },
    MuiCard: {
      styleOverrides: {
        root: {
          borderRadius: '16px',
          backgroundColor: 'rgba(10, 10, 10, 0.8)'
        }
      }
    }
  },
  customGradients: {
    primary: 'linear-gradient(45deg, #8A2BE2, #B24BF3)',
    secondary: 'linear-gradient(45deg, #00FFFF, #00FF9D)',
    dark: 'linear-gradient(45deg, #0A0A1F, #1A1A3A)',
    glow: 'linear-gradient(45deg, rgba(138, 43, 226, 0.4), rgba(0, 255, 255, 0.4))',
    neon: 'linear-gradient(45deg, #FF00FF, #00FFFF)'
  },
  customShadows: {
    neon: '0 0 20px rgba(138, 43, 226, 0.5), 0 0 40px rgba(0, 255, 255, 0.3)',
    purple: '0 0 25px rgba(138, 43, 226, 0.6)',
    blue: '0 0 25px rgba(0, 255, 255, 0.6)',
    intense: '0 0 30px rgba(255, 0, 255, 0.4), 0 0 60px rgba(0, 255, 255, 0.2)'
  }
});

function App() {
  return (
    <AuthProvider>
      <CartProvider>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <SnackbarProvider maxSnack={3}>
            <Box 
              sx={{ 
                minHeight: '100vh',
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                overflow: 'hidden'
              }}
            >
              <Background />
              <Router basename="/">
                <ScrollToTop />
                <motion.div
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ duration: 0.5 }}
                  style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}
                >
                  <Navbar />
                  <SocialBar />
                  <Box sx={{ flex: 1 }}>
                    <Suspense fallback={<div>Cargando...</div>}>
                      <AnimatePresence mode="wait">
                        <Routes>
                          <Route path="/" element={
                            <motion.div
                              initial={{ opacity: 0, y: 20 }}
                              animate={{ opacity: 1, y: 0 }}
                              exit={{ opacity: 0, y: -20 }}
                              transition={{ duration: 0.5 }}
                            >
                              <Home />
                            </motion.div>
                          } />
                          <Route path="/products" element={
                            <motion.div
                              initial={{ opacity: 0, y: 20 }}
                              animate={{ opacity: 1, y: 0 }}
                              exit={{ opacity: 0, y: -20 }}
                              transition={{ duration: 0.5 }}
                            >
                              <Products />
                            </motion.div>
                          } />
                          <Route path="/blog" element={<Blog />} />
                          <Route path="/contact" element={<Contact />} />
                          <Route path="/cart" element={<Cart />} />
                          <Route path="/login" element={<Login />} />
                          <Route path="/register" element={<Register />} />
                          <Route path="/profile" element={<Profile />} />
                          <Route path="/orders" element={<Orders />} />
                          <Route path="/settings" element={<Settings />} />
                          <Route path="/game/:id" element={<GameDetails />} />
                          <Route path="/admin/login" element={<AdminLogin />} />
                          <Route path="/admin" element={
                            <ProtectedRoute>
                              <AdminPanel />
                            </ProtectedRoute>
                          } />
                          <Route 
                            path="/admin/add-game" 
                            element={
                              <ProtectedRoute>
                                <AddGame />
                              </ProtectedRoute>
                            } 
                          />
                          <Route 
                            path="/admin/edit-games" 
                            element={
                              <ProtectedRoute adminOnly>
                                <EditGames />
                              </ProtectedRoute>
                            } 
                          />
                          <Route 
                            path="/admin/edit-game/:id" 
                            element={
                              <ProtectedRoute adminOnly>
                                <EditGame />
                              </ProtectedRoute>
                            } 
                          />
                          <Route 
                            path="/admin/edit-carousel" 
                            element={
                              <ProtectedRoute adminOnly>
                                <EditCarousel />
                              </ProtectedRoute>
                            } 
                          />
                          <Route 
                            path="/admin/carousel" 
                            element={
                              <ProtectedRoute adminOnly>
                                <CarouselList />
                              </ProtectedRoute>
                            } 
                          />
                          <Route 
                            path="/admin/carousel/add" 
                            element={
                              <ProtectedRoute adminOnly>
                                <AddCarousel />
                              </ProtectedRoute>
                            } 
                          />
                          <Route 
                            path="/admin/authorize-orders" 
                            element={
                              <ProtectedRoute adminOnly>
                                <AuthorizeOrders />
                              </ProtectedRoute>
                            } 
                          />
                          <Route path="/checkout" element={<Checkout />} />
                          <Route path="/payment/:orderId" element={<Payment />} />
                          <Route 
                            path="/admin/add-accounts" 
                            element={
                              <ProtectedRoute adminOnly>
                                <AddGameAccounts />
                              </ProtectedRoute>
                            } 
                          />
                          <Route 
                            path="/admin/manage-accounts" 
                            element={
                              <ProtectedRoute adminOnly>
                                <ManageGameAccounts />
                              </ProtectedRoute>
                            } 
                          />
                          <Route 
                            path="/admin/guides" 
                            element={
                                <ProtectedRoute adminOnly>
                                    <AddGuides />
                                </ProtectedRoute>
                            } 
                          />
                          <Route path="/sales" element={<Sales />} />
                        </Routes>
                      </AnimatePresence>
                    </Suspense>
                  </Box>
                  <Footer />
                </motion.div>
              </Router>
            </Box>
          </SnackbarProvider>
        </ThemeProvider>
      </CartProvider>
    </AuthProvider>
  );
}

export default App;
