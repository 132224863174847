import React, { useState } from 'react';
import { 
    Container, Typography, Grid, Card, Box, Button, TextField,
    Alert, Snackbar, IconButton
} from '@mui/material';
import { motion } from 'framer-motion';
import EmailIcon from '@mui/icons-material/Email';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import SendIcon from '@mui/icons-material/Send';
import StarBackground from '../components/StarBackground';

function Contact() {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        subject: '',
        message: ''
    });
    const [showAlert, setShowAlert] = useState(false);

    const handleSubmit = (e) => {
        e.preventDefault();
        // Aquí iría la lógica para enviar el formulario
        setShowAlert(true);
        setFormData({ name: '', email: '', subject: '', message: '' });
    };

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };

    const contactInfo = [
        {
            icon: <EmailIcon sx={{ fontSize: 40 }} />,
            title: "Email",
            info: "soporte@tudominio.com",
            action: "Envíanos un correo"
        },
        {
            icon: <WhatsAppIcon sx={{ fontSize: 40 }} />,
            title: "WhatsApp",
            info: "+1234567890",
            action: "Chatea con nosotros"
        },
        {
            icon: <LocationOnIcon sx={{ fontSize: 40 }} />,
            title: "Horario",
            info: "24/7",
            action: "Siempre disponibles"
        }
    ];

    return (
        <Box sx={{ position: 'relative', minHeight: '100vh' }}>
            <StarBackground />
            <Box 
                sx={{ 
                    position: 'relative',
                    zIndex: 1,
                    minHeight: '100vh',
                    pt: { xs: 12, md: 16 },
                    pb: 8
                }}
            >
                <Container maxWidth="xl">
                    <motion.div
                        initial={{ opacity: 0, y: 20 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.5 }}
                    >
                        <Typography 
                            variant="h2" 
                            component="h1" 
                            color="white"
                            sx={{ 
                                mb: 2,
                                fontWeight: 700,
                                textAlign: 'center'
                            }}
                        >
                            Contáctanos
                        </Typography>
                        <Typography 
                            variant="h5" 
                            color="white"
                            sx={{ 
                                mb: 6,
                                opacity: 0.8,
                                textAlign: 'center'
                            }}
                        >
                            Estamos aquí para ayudarte con cualquier duda
                        </Typography>

                        {/* Info Cards */}
                        <Grid container spacing={4} sx={{ mb: 8 }}>
                            {contactInfo.map((item, index) => (
                                <Grid item xs={12} md={4} key={index}>
                                    <motion.div
                                        initial={{ opacity: 0, y: 20 }}
                                        animate={{ opacity: 1, y: 0 }}
                                        transition={{ delay: index * 0.1 }}
                                    >
                                        <Card 
                                            className="glass-effect card-hover"
                                            sx={{ 
                                                height: '100%',
                                                background: 'rgba(255, 255, 255, 0.1)',
                                                backdropFilter: 'blur(10px)',
                                                borderRadius: 'var(--border-radius-lg)',
                                                color: 'white',
                                                p: 4,
                                                textAlign: 'center'
                                            }}
                                        >
                                            <Box sx={{ 
                                                color: 'var(--accent-color)',
                                                mb: 2
                                            }}>
                                                {item.icon}
                                            </Box>
                                            <Typography variant="h6" sx={{ mb: 1 }}>
                                                {item.title}
                                            </Typography>
                                            <Typography sx={{ mb: 2, opacity: 0.8 }}>
                                                {item.info}
                                            </Typography>
                                            <Typography 
                                                variant="body2"
                                                sx={{ 
                                                    color: 'var(--accent-color)',
                                                    cursor: 'pointer',
                                                    '&:hover': { textDecoration: 'underline' }
                                                }}
                                            >
                                                {item.action}
                                            </Typography>
                                        </Card>
                                    </motion.div>
                                </Grid>
                            ))}
                        </Grid>

                        {/* Contact Form */}
                        <Grid container justifyContent="center">
                            <Grid item xs={12} md={8}>
                                <Card 
                                    component="form"
                                    onSubmit={handleSubmit}
                                    className="glass-effect"
                                    sx={{ 
                                        background: 'rgba(255, 255, 255, 0.1)',
                                        backdropFilter: 'blur(10px)',
                                        borderRadius: 'var(--border-radius-lg)',
                                        color: 'white',
                                        p: 4
                                    }}
                                >
                                    <Grid container spacing={3}>
                                        <Grid item xs={12} md={6}>
                                            <TextField
                                                fullWidth
                                                label="Nombre"
                                                name="name"
                                                value={formData.name}
                                                onChange={handleChange}
                                                required
                                                sx={{
                                                    '& .MuiOutlinedInput-root': {
                                                        color: 'white',
                                                        '& fieldset': {
                                                            borderColor: 'rgba(255,255,255,0.3)'
                                                        },
                                                        '&:hover fieldset': {
                                                            borderColor: 'rgba(255,255,255,0.5)'
                                                        }
                                                    },
                                                    '& .MuiInputLabel-root': {
                                                        color: 'rgba(255,255,255,0.7)'
                                                    }
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <TextField
                                                fullWidth
                                                label="Email"
                                                name="email"
                                                type="email"
                                                value={formData.email}
                                                onChange={handleChange}
                                                required
                                                sx={{
                                                    '& .MuiOutlinedInput-root': {
                                                        color: 'white',
                                                        '& fieldset': {
                                                            borderColor: 'rgba(255,255,255,0.3)'
                                                        },
                                                        '&:hover fieldset': {
                                                            borderColor: 'rgba(255,255,255,0.5)'
                                                        }
                                                    },
                                                    '& .MuiInputLabel-root': {
                                                        color: 'rgba(255,255,255,0.7)'
                                                    }
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField
                                                fullWidth
                                                label="Asunto"
                                                name="subject"
                                                value={formData.subject}
                                                onChange={handleChange}
                                                required
                                                sx={{
                                                    '& .MuiOutlinedInput-root': {
                                                        color: 'white',
                                                        '& fieldset': {
                                                            borderColor: 'rgba(255,255,255,0.3)'
                                                        },
                                                        '&:hover fieldset': {
                                                            borderColor: 'rgba(255,255,255,0.5)'
                                                        }
                                                    },
                                                    '& .MuiInputLabel-root': {
                                                        color: 'rgba(255,255,255,0.7)'
                                                    }
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField
                                                fullWidth
                                                multiline
                                                rows={4}
                                                label="Mensaje"
                                                name="message"
                                                value={formData.message}
                                                onChange={handleChange}
                                                required
                                                sx={{
                                                    '& .MuiOutlinedInput-root': {
                                                        color: 'white',
                                                        '& fieldset': {
                                                            borderColor: 'rgba(255,255,255,0.3)'
                                                        },
                                                        '&:hover fieldset': {
                                                            borderColor: 'rgba(255,255,255,0.5)'
                                                        }
                                                    },
                                                    '& .MuiInputLabel-root': {
                                                        color: 'rgba(255,255,255,0.7)'
                                                    }
                                                }}
                                            />
                                        </Grid>
                                    </Grid>

                                    <Button
                                        type="submit"
                                        variant="contained"
                                        size="large"
                                        startIcon={<SendIcon />}
                                        className="button-hover"
                                        sx={{
                                            mt: 4,
                                            background: 'var(--accent-color)',
                                            '&:hover': {
                                                background: 'var(--accent-dark)'
                                            }
                                        }}
                                    >
                                        Enviar Mensaje
                                    </Button>
                                </Card>
                            </Grid>
                        </Grid>
                    </motion.div>
                </Container>

                <Snackbar
                    open={showAlert}
                    autoHideDuration={6000}
                    onClose={() => setShowAlert(false)}
                >
                    <Alert 
                        onClose={() => setShowAlert(false)} 
                        severity="success"
                        sx={{ 
                            width: '100%',
                            bgcolor: 'var(--accent-color)',
                            color: 'white'
                        }}
                    >
                        ¡Mensaje enviado con éxito! Te responderemos pronto.
                    </Alert>
                </Snackbar>
            </Box>
        </Box>
    );
}

export default Contact; 