import React, { useState, useEffect , useRef } from 'react';
import { 
    Container, Box, Typography, Button, Grid,
    useTheme, IconButton
} from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import GameCard from '../components/GameCard';
import axios from 'axios';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import WhatshotIcon from '@mui/icons-material/Whatshot';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import ImageWithFallback from '../components/ImageWithFallback';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { formatCOP } from '../utils/formatPrice';
import Carousel from '../components/Carousel';

// Componente SliderArrow
const SliderArrow = ({ direction, onClick }) => {
    return (
        <Box
            onClick={onClick}
            sx={{
                position: 'absolute',
                top: '50%',
                transform: 'translateY(-50%)',
                [direction === 'next' ? 'right' : 'left']: { xs: '40px', sm: '60px', md: '80px' },
                zIndex: 2,
                width: { xs: '35px', md: '50px' },
                height: { xs: '35px', md: '50px' },
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                cursor: 'pointer',
                background: 'rgba(0,0,0,0.5)',
                borderRadius: '50%',
                transition: 'all 0.3s ease',
                '&:hover': {
                    background: 'rgba(255,255,255,0.2)',
                    transform: 'translateY(-50%) scale(1.1)',
                },
                '& svg': {
                    fontSize: { xs: '20px', md: '30px' },
                    color: 'white'
                }
            }}
        >
            {direction === 'left' ? (
                <KeyboardArrowLeftIcon />
            ) : (
                <KeyboardArrowRightIcon />
            )}
        </Box>
    );
};

// Componente CarouselSlide


// Aquí se definen las opciones de configuración del carrusel






const CustomCarousel = ({ items }) => {
    const [currentIndex, setCurrentIndex] = useState(0); // Comenzamos en 0 para la animación inicial
    const [isLoaded, setIsLoaded] = useState(false);
    const containerRef = useRef(null);
    const isTransitioning = useRef(false);

    const totalSlides = items.length;
    const duplicatedItems = [items[items.length - 1], ...items, items[0]];

    // Efecto para la animación inicial
    useEffect(() => {
        if (!isLoaded) {
            // Pequeño delay para asegurar que los elementos están renderizados
            setTimeout(() => {
                setCurrentIndex(1); // Movemos al primer slide real
                setIsLoaded(true);
            }, 100);
        }
    }, [isLoaded]);

    const handleTransitionEnd = () => {
        const container = containerRef.current;
        if (!container) return;

        if (currentIndex === 0) {
            container.style.transition = 'none';
            setCurrentIndex(totalSlides);
            requestAnimationFrame(() => {
                container.style.transition = 'transform 500ms ease-in-out';
            });
        } else if (currentIndex === duplicatedItems.length - 1) {
            container.style.transition = 'none';
            setCurrentIndex(1);
            requestAnimationFrame(() => {
                container.style.transition = 'transform 500ms ease-in-out';
            });
        }
        isTransitioning.current = false;
    };

    const nextSlide = () => {
        if (isTransitioning.current) return; // Evitar múltiples transiciones
        isTransitioning.current = true;
        setCurrentIndex(prev => prev + 1); // Avanzar al siguiente slide
    };

    const prevSlide = () => {
        if (isTransitioning.current) return;
        isTransitioning.current = true;
        setCurrentIndex(prev => prev - 1); // Retroceder al slide anterior
    };

    return (
        <div className="carousel-container">
            <div
                className={`carousel-slides ${isLoaded ? 'loaded' : ''}`}
                ref={containerRef}
                onTransitionEnd={handleTransitionEnd}
                style={{
                    transform: `translateX(-${currentIndex * 100}%)`,
                    transition: isLoaded ? 'transform 500ms ease-in-out' : 'none'
                }}
            >
                {duplicatedItems.map((item, index) => (
                    <div 
                        key={index} 
                        className={`carousel-slide ${index === currentIndex ? 'active' : ''}`}
                    >
                        <img
                            src={item.image}
                            alt={item.title}
                            style={{
                                width: '100%',
                                height: '100%',
                                objectFit: 'cover'
                            }}
                        />
                    </div>
                ))}
            </div>
            <SliderArrow direction="left" onClick={prevSlide} />
            <SliderArrow direction="next" onClick={nextSlide} />
        </div>
    );
};











// Componente principal Home
function Home() {
    const navigate = useNavigate();
    const [latestGames, setLatestGames] = useState([]);
    const [discountedGames, setDiscountedGames] = useState([]);
    const [loading, setLoading] = useState(true);
    const [carouselItems, setCarouselItems] = useState([]);
    const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

    // Mover la configuración del slider dentro del componente
    const sliderSettings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 5000,
        arrows: true,
        nextArrow: <SliderArrow direction="next" />,
        prevArrow: <SliderArrow direction="left" />,
        centerMode: true,
        centerPadding: '300px',
        cssEase: 'linear',
        useCSS: true,
        useTransform: true,
        swipeToSlide: true
    };

    // Configuración del slider para las GameCards en móvil
    const gameCardsSliderSettings = {
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 2,
        slidesToScroll: 2,
        arrows: false,
        responsive: [
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    dots: true
                }
            }
        ]
    };

    // Estilo común para ambas secciones (Ofertas y Últimos Lanzamientos)
    const commonGridStyle = {
        mx: 0,
        width: '100%',
        display: 'grid',
        gap: { 
            xs: '15px',
            sm: '30px',
            md: '35px'
        },
        gridTemplateColumns: {
            xs: 'repeat(2, 1fr)',          // 2 columnas en móvil
            sm: 'repeat(2, 1fr)',          // 2 columnas en tablets
            md: 'repeat(5, 1fr)',          // 5 columnas en desktop pequeño
            lg: 'repeat(6, 1fr)',          // 6 columnas en 1920x1200
            xl: 'repeat(7, 1fr)'           // 7 columnas en pantallas más grandes
        },
        '& > .MuiGrid-item': {
            p: 0,
            m: 0,
            width: {
                xs: '150px',    // Ajustado para 2 cards en móvil
                sm: '100%',
                md: '180px',
                lg: '190px',
                xl: '200px'
            },
            height: {
                xs: '180px',    // Ajustado para móvil
                sm: '230px',
                md: '210px',
                lg: '220px',
                xl: '230px'
            },
            '& .MuiCard-root': {
                height: 'auto',
                maxWidth: {
                    xs: '150px',  // Ajustado para 2 cards en móvil
                    md: '180px',
                    lg: '190px',
                    xl: '200px'
                },
                margin: '0 auto'
            }
        }
    };

    // Para móvil, mostrar solo 2 cards
    const getVisibleGames = (games) => {
        const isMobile = window.innerWidth <= 600;
        return isMobile ? games.slice(0, 2) : games.slice(0, 6);
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true);
                
                // Cargar datos del carrusel
                const carouselResponse = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/carousel`);
                if (carouselResponse.data.success) {
                    console.log('Datos de carrusel recibidos:', carouselResponse.data.data);
                    const formattedCarousels = carouselResponse.data.data.map(carousel => ({
                        id: carousel.id,
                        title: carousel.title,
                        subtitle: carousel.subtitle,
                        description: carousel.description,
                        buttonText: carousel.buttonText || "¡COMPRAR AHORA!",
                        image: carousel.carouselGame?.banner_image 
                            ? `${process.env.REACT_APP_BACKEND_URL}${carousel.carouselGame.banner_image}`
                            : carousel.image,
                        gameId: carousel.gameId,
                        game: carousel.carouselGame
                    }));
                    setCarouselItems(formattedCarousels);
                }

                // Cargar últimos lanzamientos
                const latestResponse = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/games/latest`);
                if (latestResponse.data && latestResponse.data.success) {
                    setLatestGames(latestResponse.data.data);
                }

                // Cargar todos los juegos para filtrar las ofertas
                const gamesResponse = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/games`);
                if (gamesResponse.data && gamesResponse.data.success) {
                    const allGames = gamesResponse.data.data;
                    
                    // Filtrar juegos con descuento y ordenarlos por mayor descuento
                    const discounted = allGames
                        .filter(game => game.discount > 0)  // Solo juegos con descuento mayor a 0
                        .sort((a, b) => b.discount - a.discount)  // Ordenar por descuento de mayor a menor
                        .slice(0, 6);  // Tomar los primeros 6
                    
                    console.log('Juegos con descuento:', discounted);  // Para debug
                    setDiscountedGames(discounted);
                }

            } catch (error) {
                console.error('Error al cargar datos:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [BACKEND_URL]);

    const handleCarouselButtonClick = (carousel) => {
        if (carousel.gameId && carousel.game) {
            navigate(`/game/${carousel.game.id}`);
        } else {
            const searchTitle = carousel.title
                .replace(/['"]/g, '')
                .trim();
            navigate(`/products?search=${encodeURIComponent(searchTitle)}`);
        }
    };

    return (
        <Box sx={{ 
            minHeight: '100vh',
            width: '100vw',
            position: 'relative',
            left: '50%',
            right: '50%',
            marginLeft: '-50vw',
            marginRight: '-50vw',
            pt: { 
                xs: 12,
                sm: 14,
                md: 16
            },
            px: 0,
            background: 'none'
        }}>
            <Container maxWidth="xl" sx={{ 
                px: { 
                    xs: 0,
                    sm: 0,
                    md: 0
                },
                maxWidth: '100% !important',
                background: 'none'
            }}>
                <Box sx={{ 
                    pl: 0, 
                    pr: 0,
                    width: 'auto',
                    height: 'auto',
                    overflow: 'visible'
                }}>
                    {/* Banner Principal con Slider */}
                    <Box sx={{ 
                        height: { 
                            xs: '250px',
                            sm: '300px',
                            md: '450px'
                        },
                        overflow: 'visible',
                        position: 'relative',
                        mt: { 
                            xs: 0,
                            sm: 2,
                            md: -4
                        },
                        mx: 'auto'
                    }}>
                        {loading ? (
                            <Box sx={{ 
                                height: '100%', 
                                display: 'flex', 
                                alignItems: 'center', 
                                justifyContent: 'center',
                                bgcolor: 'rgba(0,0,0,0.5)'
                            }}>
                                <Typography>Cargando...</Typography>
                            </Box>
                        ) : carouselItems.length > 0 ? (
                            <Box sx={{ 
                                width: '100%',
                                position: 'relative',
                                overflow: 'hidden',
                                background: 'none',
                                '& *': {
                                    outline: 'none !important',
                                    border: 'none !important'
                                },
                                '& .slick-slider': {
                                    width: '100%',
                                    position: 'relative',
                                    background: 'none'
                                },
                                '& .slick-list': {
                                    overflow: 'hidden',
                                    background: 'none'
                                },
                                '& .slick-track': {
                                    display: 'flex',
                                    alignItems: 'center',
                                    willChange: 'transform',
                                    background: 'none'
                                },
                                '& .slick-slide': {
                                    opacity: 0.3,
                                    transform: 'scale(0.75)',
                                    transition: 'all 500ms ease-out',
                                    padding: '0 15px',
                                    background: 'none'
                                },
                                '& .slick-current': {
                                    opacity: 1,
                                    transform: 'scale(1)',
                                    zIndex: 1,
                                    background: 'none',
                                    '& .carousel-card': {
                                        background: 'none'
                                    }
                                }
                            }}>
                                <Carousel slides={carouselItems} />
                            </Box>
                        ) : (
                            <Box sx={{ 
                                height: '100%', 
                                display: 'flex', 
                                alignItems: 'center', 
                                justifyContent: 'center',
                                bgcolor: 'rgba(0,0,0,0.5)'
                            }}>
                                <Typography>No hay elementos en el carrusel</Typography>
                            </Box>
                        )}
                    </Box>

                    {/* Sección de Ofertas Especiales */}
                    <Box sx={{ 
                        width: '100%', 
                        mb: { 
                            xs: 8,
                            sm: 14,
                            md: 16
                        },
                        mt: { 
                            xs: 6,
                            sm: 8,
                            md: 0
                        },
                        px: {
                            xs: 2,
                            sm: 4,
                            md: 6,
                            lg: 8
                        }
                    }}>
                        <Box sx={{ 
                            display: 'flex', 
                            alignItems: 'center',
                            mb: { 
                                xs: 4,
                                sm: 3,
                                md: 4
                            }
                        }}>
                            <LocalOfferIcon sx={{ 
                                color: '#FF4081', 
                                mr: 1,
                                fontSize: { xs: '1.5rem', sm: '1.8rem', md: '2rem' }
                            }} />
                            <Typography variant="h5" sx={{ 
                                color: '#fff',
                                fontSize: { xs: '1.2rem', sm: '1.5rem', md: '1.8rem' }
                            }}>
                                OFERTAS ESPECIALES
                            </Typography>
                        </Box>
                        
                        {/* Grid para desktop, Slider para móvil */}
                        <Box sx={{ 
                            display: { xs: 'block', md: 'none' },  // Solo visible en móvil
                            mx: -2  // Compensar el padding del contenedor
                        }}>
                            <Slider {...gameCardsSliderSettings}>
                                {discountedGames.map((game) => (
                                    <Box key={game.id} sx={{ px: 1 }}>
                                        <GameCard 
                                            game={game} 
                                            showPrice={true}
                                        />
                                    </Box>
                                ))}
                            </Slider>
                        </Box>

                        {/* Grid original para desktop */}
                        <Box sx={{ display: { xs: 'none', md: 'block' } }}>
                            <Grid container spacing={3} sx={commonGridStyle}>
                                {discountedGames.map((game) => (
                                    <Grid item key={game.id}>
                                        <GameCard 
                                            game={game} 
                                            showPrice={true}
                                        />
                                    </Grid>
                                ))}
                            </Grid>
                        </Box>
                    </Box>

                    {/* Sección de Últimos Lanzamientos */}
                    <Box sx={{ 
                        width: '100%', 
                        mb: { 
                            xs: 6,
                            sm: 5,
                            md: 6
                        },
                        mt: { 
                            xs: 0,
                            sm: 8,
                            md: 10
                        },
                        px: {
                            xs: 2,
                            sm: 4,
                            md: 6,
                            lg: 8
                        }
                    }}>
                        <Box sx={{ 
                            display: 'flex', 
                            alignItems: 'center',
                            mb: { 
                                xs: 4,
                                sm: 3,
                                md: 4
                            }
                        }}>
                            <WhatshotIcon sx={{ 
                                color: '#FF4081', 
                                mr: 1,
                                fontSize: { xs: '1.5rem', sm: '1.8rem', md: '2rem' }
                            }} />
                            <Typography variant="h5" sx={{ 
                                color: '#fff',
                                fontSize: { xs: '1.2rem', sm: '1.5rem', md: '1.8rem' }
                            }}>
                                ÚLTIMOS LANZAMIENTOS
                            </Typography>
                        </Box>
                        
                        {/* Slider para móvil */}
                        <Box sx={{ 
                            display: { xs: 'block', md: 'none' },
                            mx: -2
                        }}>
                            <Slider {...gameCardsSliderSettings}>
                                {latestGames.map((game) => (
                                    <Box key={game.id} sx={{ px: 1 }}>
                                        <GameCard 
                                            game={game} 
                                            showPrice={true}
                                        />
                                    </Box>
                                ))}
                            </Slider>
                        </Box>

                        {/* Grid para desktop */}
                        <Box sx={{ display: { xs: 'none', md: 'block' } }}>
                            <Grid container spacing={0} sx={commonGridStyle}>
                                {getVisibleGames(latestGames).map((game) => (
                                    <Grid item key={game.id}>
                                        <GameCard 
                                            game={game} 
                                            showPrice={true}
                                        />
                                    </Grid>
                                ))}
                            </Grid>
                        </Box>
                    </Box>

                    {/* Sección de Newsletter */}
                    <Box sx={{
                        mt: { 
                            xs: 20,
                            sm: 14,
                            md: 16
                        },
                        py: 6,
                        px: {
                            xs: 4,
                            sm: 6,
                            md: 8,
                            lg: 10
                        },
                        mx: {
                            xs: 2,
                            sm: 4,
                            md: 6,
                            lg: 8
                        },
                        borderRadius: 2,
                        background: 'linear-gradient(45deg, rgba(106, 13, 173, 0.3), rgba(0, 0, 0, 0.5))',
                        backdropFilter: 'blur(10px)',
                        border: '1px solid rgba(255, 255, 255, 0.1)'
                    }}>
                        <Typography variant="h4" color="white" align="center" gutterBottom>
                            ¡No te pierdas ninguna oferta!
                        </Typography>
                        <Typography color="white" align="center" sx={{ mb: 4, opacity: 0.8 }}>
                            Suscríbete a nuestro newsletter para recibir las mejores ofertas
                        </Typography>
                        {/* Aquí puedes agregar un formulario de suscripción */}
                    </Box>

                </Box>
            </Container>
        </Box>
    );
}

export default Home;
