import React from 'react';
import { AppBar, Toolbar, Button, Box, Container, Typography } from '@mui/material';
import { Link, useLocation } from 'react-router-dom';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import EditIcon from '@mui/icons-material/Edit';
import LogoutIcon from '@mui/icons-material/Logout';
import DashboardIcon from '@mui/icons-material/Dashboard';
import ShoppingCartCheckoutIcon from '@mui/icons-material/ShoppingCartCheckout';

function AdminNavbar() {
    const location = useLocation();

    const handleLogout = () => {
        localStorage.removeItem('adminToken');
        window.location.href = '/admin/login';
    };

    return (
        <AppBar 
            position="fixed" 
            sx={{ 
                background: 'rgba(0, 0, 0, 0.95)',
                backdropFilter: 'blur(10px)',
                borderBottom: '1px solid rgba(106, 13, 173, 0.3)'
            }}
        >
            <Container maxWidth="xl">
                <Toolbar sx={{ justifyContent: 'space-between' }}>
                    {/* Logo y Título */}
                    <Box
                        component={Link}
                        to="/admin/dashboard"
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            textDecoration: 'none',
                            gap: 1
                        }}
                    >
                        <DashboardIcon sx={{ color: 'var(--accent-color)' }} />
                        <Typography 
                            variant="h6" 
                            sx={{ 
                                color: 'white',
                                fontWeight: 'bold',
                                display: { xs: 'none', sm: 'block' }
                            }}
                        >
                            Panel de Administración
                        </Typography>
                    </Box>

                    {/* Botones de Navegación */}
                    <Box sx={{ display: 'flex', gap: 2 }}>
                        <Button
                            component={Link}
                            to="/admin/add-game"
                            startIcon={<AddCircleIcon />}
                            sx={{
                                color: 'white',
                                borderRadius: '8px',
                                background: location.pathname === '/admin/add-game' 
                                    ? 'rgba(106, 13, 173, 0.5)'
                                    : 'rgba(106, 13, 173, 0.3)',
                                '&:hover': {
                                    background: 'rgba(106, 13, 173, 0.5)'
                                }
                            }}
                        >
                            Agregar Juego
                        </Button>

                        <Button
                            component={Link}
                            to="/admin/edit-games"
                            startIcon={<EditIcon />}
                            sx={{
                                color: 'white',
                                borderRadius: '8px',
                                background: location.pathname === '/admin/edit-games' 
                                    ? 'rgba(106, 13, 173, 0.5)'
                                    : 'rgba(106, 13, 173, 0.3)',
                                '&:hover': {
                                    background: 'rgba(106, 13, 173, 0.5)'
                                }
                            }}
                        >
                            Editar Juegos
                        </Button>

                        <Button
                            component={Link}
                            to="/admin/authorize-orders"
                            startIcon={<ShoppingCartCheckoutIcon />}
                            sx={{
                                color: 'white',
                                borderRadius: '8px',
                                background: location.pathname === '/admin/authorize-orders' 
                                    ? 'rgba(106, 13, 173, 0.5)'
                                    : 'rgba(106, 13, 173, 0.3)',
                                '&:hover': {
                                    background: 'rgba(106, 13, 173, 0.5)'
                                }
                            }}
                        >
                            Autorizar Compras
                        </Button>

                        <Button
                            onClick={handleLogout}
                            startIcon={<LogoutIcon />}
                            sx={{
                                color: 'white',
                                borderRadius: '8px',
                                background: 'rgba(255, 59, 59, 0.3)',
                                '&:hover': {
                                    background: 'rgba(255, 59, 59, 0.5)'
                                }
                            }}
                        >
                            Cerrar Sesión
                        </Button>
                    </Box>
                </Toolbar>
            </Container>
        </AppBar>
    );
}

export default AdminNavbar; 