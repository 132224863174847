import React, { createContext, useContext, useState, useEffect } from 'react';
import axios from 'axios';

const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {
    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        checkAuth();
    }, []);

    const checkAuth = async () => {
        try {
            const token = localStorage.getItem('token');
            const adminUser = localStorage.getItem('adminUser');

            if (token && adminUser) {
                // Si existe un adminUser en localStorage, usarlo directamente
                const parsedAdminUser = JSON.parse(adminUser);
                setUser({
                    ...parsedAdminUser,
                    role: 'admin',
                    isAdmin: true
                });
            } else if (token) {
                // Si solo hay token, verificar con el backend
                const response = await axios.get('http://localhost:5000/api/auth/me', {
                    headers: { Authorization: `Bearer ${token}` }
                });
                setUser(response.data);
            }
        } catch (error) {
            localStorage.removeItem('token');
            localStorage.removeItem('adminUser');
        } finally {
            setLoading(false);
        }
    };

    const login = async (email, password) => {
        try {
            const isAdminLogin = email === process.env.REACT_APP_ADMIN_EMAIL;
            const loginUrl = isAdminLogin 
                ? 'http://localhost:5000/api/auth/admin/login'
                : 'http://localhost:5000/api/auth/login';

            const response = await axios.post(loginUrl, {
                email,
                password
            });

            if (response.data.success) {
                const token = response.data.token;
                localStorage.setItem('token', token);
                setUser(response.data.user);
                return { success: true };
            } else {
                return { 
                    success: false, 
                    message: response.data.message 
                };
            }
        } catch (error) {
            console.error('Error en login:', error);
            return {
                success: false,
                message: error.response?.data?.message || 'Error al iniciar sesión'
            };
        }
    };

    const register = async (userData) => {
        try {
            const response = await axios.post('http://localhost:5000/api/auth/register', userData);
            if (response.data.success) {
                const { token, user } = response.data;
                localStorage.setItem('token', token);
                setUser(user);
                return { success: true };
            }
            return { success: false, message: response.data.message };
        } catch (error) {
            return {
                success: false,
                message: error.response?.data?.message || 'Error al registrarse'
            };
        }
    };

    const logout = () => {
        localStorage.removeItem('token');
        setUser(null);
    };

    return (
        <AuthContext.Provider value={{ user, loading, login, register, logout }}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => useContext(AuthContext); 